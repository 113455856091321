<template>
  <section class="lav-replenish mb-10">
    <div class="lav-card ">
       <div class="lav-card-circle"></div>
       <div class="lav-card_logs">
        <img class="lav-card_logs-item lav-desktop" src="../assets/img/tinkoff-desktop.svg" alt="логотип Тинькофф Банк">
        <img class="lav-card_logs-item lav-desktop" src="../assets/img/sberbank-desktop.svg" alt="логотип СберБанк">
        <img class="lav-card_logs-item lav-desktop" src="../assets/img/alfabank-desktop.svg" alt="логотип Альфа-Банк">
        <img class="lav-card_logs-item lav-desktop" src="../assets/img/raiffeisen-desktop.svg" alt="логотип Райффайзенбанк">
        <img class="lav-card_logs-item lav-desktop" src="../assets/img/ozon-desktop.svg" alt="логотип Озон Банк">

        <img class="lav-card_logs-item lav-mobile resize"  src="../assets/img/tinkoff.svg" alt="логотип Тинькофф Банк">
        <img class="lav-card_logs-item lav-mobile resize" src="../assets/img/sberbank.svg" alt="логотип СберБанк">
        <img class="lav-card_logs-item lav-mobile resize" src="../assets/img/alfabank.svg" alt="логотип Альфа-Банк">
        <img class="lav-card_logs-item lav-mobile resize" src="../assets/img/raiffeisen.svg" alt="логотип Райффайзенбанк">
        <img class="lav-card_logs-item lav-mobile resize" src="../assets/img/ozon.svg" alt="логотип Озон Банк">
    </div>
       <h2 class="lav-replenish-title">Пополнить<br>картой</h2>
       
       
      
       <p class="lav-replenish-text">Пополнить депозит с комиссией от 0.9 до 1.8 %, <b>зачисление до 5 минут</b></p>
       <div  v-if="user.green_sign" class="mb-0 mt-0 alex-replenishment-available-text ms-1 mb-3" style="color: #fff; opacity: 0.9;">
        <div >
        <svg class="mb-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.3499 8.00002C15.3499 3.94073 12.0592 0.650024 7.9999 0.650024C3.94061 0.650024 0.649902 3.94073 0.649902 8.00002C0.649902 12.0593 3.94061 15.35 7.9999 15.35C12.0592 15.35 15.3499 12.0593 15.3499 8.00002Z" stroke="#fff" stroke-width="1.2" stroke-miterlimit="10"/>
          <path d="M10.7032 6.23223L7.16767 9.76777L5.3999 8" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          У вас зеленая галочка, комиссия за ввод и вывод (кроме TRC) – 0%
        </div>
      </div> 
       <div class="lav-card-btn" @click="toggleTopUpOwn">Пополнить</div>
      </div>

      <div class="lav-crypto mb-10">
        <div class="lav-crypto-circle"></div>
        <h2 class="lav-replenish-title">Пополнить<br>криптовалютой</h2>
        <p class="lav-replenish-text">Моментальное пополнение криптовалютой USDT, ETH, BNB, TRX без комиссий в любой
            сети.
        </p>
        <div class="lav-crypto-btn mb-10" @click="toggleTopUpCrypto">Пополнить</div>
    </div>
 </section>
    <!-- <div class="py-2 ">
      <div class="row">
        <div class="col-xl-6 col-sm-6 mb-xl-0 mb-2" >
                <div class="top-up-card">
                  <div class="secured-label ">
                    <svg width="22" height="22"  class="me-1" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.167 10.193C19.167 14.6754 15.9129 18.8738 11.467 20.1021C11.1645 20.1846 10.8345 20.1846 10.532 20.1021C6.08619 18.8738 2.83203 14.6754 2.83203 10.193V6.16877C2.83203 5.4171 3.40038 4.56461 4.10621 4.28044L9.21202 2.19047C10.3579 1.72297 11.6504 1.72297 12.7962 2.19047L17.902 4.28044C18.5987 4.56461 19.1762 5.4171 19.1762 6.16877L19.167 10.193Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.0003 11.4587C12.0128 11.4587 12.8337 10.6378 12.8337 9.62533C12.8337 8.6128 12.0128 7.79199 11.0003 7.79199C9.9878 7.79199 9.16699 8.6128 9.16699 9.62533C9.16699 10.6378 9.9878 11.4587 11.0003 11.4587Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 11.459V14.209" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      {{$t('topup.safe_p2p') }}
                      
                  </div>
                  <h5 class="text-header-m mt-4" >
                    {{$t('topup.topup_card') }}
                  </h5>
                  <div class="text-body-m" >
                    {{$t('topup.topup_dep') }}

                  </div>
                  <div class="row d-flex justify-content-end">
                    <div
                      class="choosing-btn choosing-btn-white"
                      @click="toggleTopUpOwn"
                    >
                      <div class="m-1">{{$t('topup.choose') }}</div>
                    </div>
                  </div>
                  
                </div>
       </div>
  
       <div class="col-xl-6 col-sm-6 mb-xl-0 mb-2">

          <div class="top-up-card-blue ">
            <h5 class="text-white text-header-m mt-2 mb-2">
              {{$t('topup.topup_crypto') }} 
            </h5>
          
            <div class="text-white mb-4 text-body-m">
              {{$t('topup.topup_moment') }}
            </div>
            <div class="row d-flex justify-content-end">
                <div
                class="choosing-btn "
                @click="toggleTopUpCrypto"
              >
                <div class="m-1">{{$t('topup.choose') }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      
    </div> -->
 
  </template>
  
  <style scoped>
  /* 400 */



/* @media (prefers-reduced-motion: no-preference) {
  html {
     scroll-behavior: smooth;
  }
} */
.resize{
  transform: scale(0.7);
}

.lav-desktop {
  display: flex !important;
}

.lav-mobile {
  display: none !important;
}
 

.lav-container {
  max-width: 1512px;
  margin: 0 auto;
}

/* lav-referral */

.lav-referral {
  margin: 0 auto;
  padding: 175px 133px 306px;
}

.lav-referral__level {
  display: flex;
  gap: 78px;
  justify-content: center;
}

.lav-referral__level_item {
  width: 168px;
  height: 168px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 15px;
  border-radius: 50%;
  background-color: #191919;
  align-items: center;
  justify-content: center;
}

.lav-open.lav-referral__level_item {
  background-color: #1852BA;
  padding-top: 49px;
  justify-content: flex-start;
}

.lav-referral__level_item-number,
.lav-referral__level_item-name {
  display: none;
}

.lav-referral__level_item-lock {
  width: 78px;
  height: 78px;
}

.lav-open .lav-referral__level_item-number,
.lav-open .lav-referral__level_item-name {
  display: block;
  font-size: 36px;
  line-height: 35px;
  font-family: "Inter", sans-serif;
}

.lav-open .lav-referral__level_item-lock {
  display: none;
}

.lav-referral-title {
  margin-top: 142px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 47px;
  line-height: 65px;
  text-align: center;
  color: #7D7D7D;
}

.lav-referral-volume {
  margin-top: 30px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 100px;
  line-height: 130px;
  text-align: center;
  color: #FFFFFF;
}

.lav-referral__composition {
  margin: 0 auto;
  margin-top: 47px;
  width: 468px;
  height: 175px;
  display: flex;
  justify-content: space-between;
  background: #1F1F1F;
  border-radius: 26px;
  padding: 26px 37px 43px;
}

.lav-referral__composition_item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

}

.lav-referral__composition_item-logo {
  width: 65px;
  height: 65px;

}

.lav-referral__composition_item-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 21px;
  color: #FFFFFF;
}

.lav-referral__row {
  margin-top: 125px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.lav-referral-link {
  width: 460px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  line-height: 35px;
  border-radius: 30px;
  padding: 45px 63px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #fff;
  background: radial-gradient(50% 134.49% at 50% 50%, #557AD3 0%, #05379B 100%);
  gap: 42px;
}

.lav-referral_enrollment {
  position: relative;
  width: 762px;
  height: 120px;
  padding: 0 40px 0 44px;
  border-radius: 30px;
  background-color: #0C0C0C;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 29px;
  line-height: 35px;
  color: #FFFFFF;
}

.lav-referral_enrollment::before {
  content: '';
  position: fixed;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.9023984593837535) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.9023984593837535) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.9023984593837535) 0%, rgba(0, 0, 0, 1) 100%);
  border-radius: 30px;
  z-index: -1;
}

.lav-referral-statistics {
  margin-top: 24px;
  width: 460px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  line-height: 35px;
  border-radius: 30px;
  padding: 45px 63px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #fff;
  background: transparent;
  gap: 72px;
}

/* lav-referral END*/

/* lav-replenish */
.lav-replenish {
  padding: 78px 20px 70px;
  overflow: hidden;
}

.lav-card,
.lav-crypto {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lav-card-circle {
  position: fixed;
  width: 1528px;
  height: 1528px;
  top: -850px;
  background: radial-gradient(50% 50% at 50% 50%, #035DE5 0%, #05165E 100%);
  filter: blur(49.45px);
  z-index: -1;
  border-radius: 50%;
}

.lav-card_logs {
  display: flex;
  gap: 84px;
}

.lav-replenish-title {
  margin-top: 78px;
  font-family: "Inter", sans-serif;
  font-size: 70px;
  line-height: 91px;
  text-align: center;
  color: #FFFFFF;
}

.lav-crypto .lav-replenish-title {
  margin-top: 230px;
}

.lav-replenish-text {
  width: 568px;
  font-family: "Inter", sans-serif;
  margin-top: 5px;
  font-size: 32px;
  line-height: 41.6px;
  text-align: center;
  color: #E4E4E4;
}

.lav-card-btn,
.lav-crypto-btn {
  margin-top: 70px;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 412px;
  height: 100px;
  background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #1A93CD 100%);
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 41.6px;
  color: #080808;
}

.lav-crypto {
  justify-content: flex-end;
}

.lav-crypto-circle {
  position: fixed;
  width: 1528px;
  height: 1528px;
  bottom: -850px;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%);
  filter: blur(49.45px);
  border-radius: 50%;
}

/* lav-replenish END */

@media screen and (width > 768px) and (width < 1520px) {
  /* lav-referral */

  .lav-referral {
     padding: 11.45vw 8.7vw 20.01vw;
  }

  .lav-referral__level {
     gap: 5.1vw;
  }

  .lav-referral__level_item {
     width: 10.99vw;
     height: 10.99vw;
     gap: .98vw;
  }

  .lav-open.lav-referral__level_item {
     padding-top: 3.2vw;
  }

  .lav-referral__level_item-lock {
     width: 5.1vw;
     height: 5.1vw;
  }

  .lav-open .lav-referral__level_item-number,
  .lav-open .lav-referral__level_item-name {
     font-size: 2.35vw;
     line-height: 2.29vw;
  }


  .lav-referral-title {
     margin-top: 9.29vw;
     font-size: 3.07vw;
     line-height: 4.25vw;
  }

  .lav-referral-volume {
     margin-top: 1.96vw;
     font-size: 6.54vw;
     line-height: 8.5vw;
  }

  .lav-referral__composition {
     margin-top: 3.07vw;
     width: 30.61vw;
     height: 11.45vw;
     border-radius: 1.7vw;
     padding: 1.7vw 2.42vw 2.81vw;
  }

  .lav-referral__composition_item {
     gap: 1.31vw;
  }

  .lav-referral__composition_item-logo {
     width: 4.25vw;
     height: 4.25vw;

  }

  .lav-referral__composition_item-text {
     font-size: 1.9vw;
     line-height: 1.37vw;
  }

  .lav-referral__row {
     margin-top: 8.18vw;
     gap: 1.31vw;
  }

  .lav-referral-link {
     width: 30.09vw;
     font-size: 2.09vw;
     line-height: 2.29vw;
     border-radius: 1.31vw;
     padding: 2.94vw 4.12vw;
     gap: 2.75vw;
  }

  .lav-referral_enrollment {
     width: 49vw;
     height: 9vw;
     padding: 0 2.62vw 0 2.88vw;
     border-radius: 1.31vw;
     font-size: 1.9vw;
     line-height: 2.29vw;
  }

  .lav-referral_enrollment::before {
     top: -.13vw;
     left: -.13vw;
     width: calc(100% + .26vw);
     height: calc(100% + .26vw);
     border-radius: 1.31vw;
  }

  .lav-referral-statistics {
     margin-top: 1.57vw;
     width: 30.09vw;
     font-size: 2.09vw;
     line-height: 2.29vw;
     border-radius: 1.31vw;
     padding: 2.94vw 4.12vw;
     gap: 4.71vw;
  }

  /* lav-referral END*/

  /* lav-replenish */
  .lav-replenish {
     padding: 10px 1.31vw 4.58vw;
     overflow: hidden;
  }

  .lav-card,
  .lav-crypto {
     height: 50%;
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
  }

  .lav-card-circle {
     position: fixed;
     width: 99.93vw;
     height: 99.93vw;
     top: -55.59vw;
     background: radial-gradient(50% 50% at 50% 50%, #035DE5 0%, #05165E 100%);
     filter: blur(3.23vw);
     z-index: -1;
     border-radius: 50%;
  }

  .lav-card_logs {
     display: flex;
     gap: 5.49vw;
  }

  .lav-replenish-title {
     margin-top: 5.1vw;
     font-family: "Inter", sans-serif;
     font-size: 4.58vw;
     line-height: 5.95vw;
     text-align: center;
     color: #FFFFFF;
  }

  .lav-crypto .lav-replenish-title {
     margin-top: 50px;
  }

  .lav-replenish-text {
     width: 37.15vw;
     font-family: "Inter", sans-serif;
     margin-top: 10px;
     font-size: 2.09vw;
     line-height: 2.72vw;
     text-align: center;
     color: #E4E4E4;
  }

  .lav-card_logs-item {
     width: 30px;
  }

  .lav-card-btn,
  .lav-crypto-btn {
     margin-top: 4.58vw;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 26.95vw;
     height: 6.54vw;
     background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #1A93CD 100%);
     border-radius: 1.05vw;
     font-family: "Inter", sans-serif;
     font-size: 2.09vw;
     line-height: 2.72vw;
     color: #080808;
  }

  .lav-crypto {
     justify-content: flex-end;
  }

  .lav-crypto-circle {
     position: fixed;
     width: 99.93vw;
     height: 99.93vw;
     top: 50vw;
     z-index: -1;
     background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%);
     filter: blur(3.23vw);
     border-radius: 50%;
  }

  /* lav-replenish END */

}

@media screen and (width < 768px) {
  body {
     background: #000;
  }

  .lav-desktop {
     display: none !important;
  }

  .lav-mobile {
     display: flex !important;
  }

  .lav-referral {
     padding: 0 19px;
  }

  .lav-referral-id {
     display: flex;
     justify-content: center;
     background: #131313;
     padding: 28px 0;
  }

  .lav-referral__level {
     gap: 19px;
  }

  .lav-referral__level_item {
     width: 66px;
     height: 66px;
     margin-top: 36px;
     gap: 0;
  }

  .lav-open.lav-referral__level_item {
     padding-top: 0;
     justify-content: center;
  }

  .lav-open .lav-referral__level_item-number,
  .lav-open .lav-referral__level_item-name {
     font-size: 22px;
     line-height: 1;
  }

  .lav-referral__level_item-lock {
     width: 30px;
     height: 30px;
  }

  .lav-referral-title {
     margin-top: 78px;
     font-size: 16px;
     line-height: 21px;
  }

  .lav-referral-volume {
     margin-top: 10px;
     font-size: 36px;
     line-height: 1;
  }

  .lav-referral__composition {
     padding: 11px 17px;
     height: 77px;
     margin: 0 auto;
     margin-top: 70px;
     border-radius: 15px;
     width: fit-content;
     gap: 43px;
  }

  .lav-referral__composition_item {
     gap: 5px;
  }

  .lav-referral__composition_item-logo {
     width: 30px;
     height: 30px;
  }

  .lav-referral__composition_item-text {
     font-size: 16px;
  }

  .lav-referral__row {
     margin-top: 35px;
     gap: 34px;
  }

  .lav-referral-link {
     width: 100%;
     font-size: 22px;
     padding: 21px 22px 21px 25px;
     justify-content: space-between;
     border-radius: 15px;
  }

  .lav-referral-link svg,
  .lav-referral-statistics svg {
     width: 41px;
  }

  .lav-referral-statistics,
  .lav-referral_enrollment {
     text-transform: uppercase;
     width: 100%;
     height: auto;
     font-size: 14px;
     padding: 0px 22px 0px 25px;
     justify-content: space-between;
     border-radius: 15px;
     margin-top: 0;
  }

  .lav-referral_enrollment {
     order: 2;
     text-transform: uppercase;
  }

  .lav-referral_enrollment::before {
     display: none;
  }

  /* lav-replenish */
  .lav-replenish {
     /* height: 100dvh; */
     padding: 10px 20px 50px;
     overflow: hidden;
  }

  .lav-card,
  .lav-crypto {
     height: 50%;
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
  }

  .lav-card-circle {
     position: fixed;
     width: 100vh;
     height: 100vh;
     top: -64vh;
     background: radial-gradient(50% 50% at 50% 50%, #035DE5 0%, #05165E 100%);
     filter: blur(49.45px);
     z-index: -1;
     border-radius: 50%;
  }

  .lav-card_logs {
     display: flex;
     gap: 23px;
  }

  .lav-replenish-title {
     margin-top: 10px;
     font-family: "Inter", sans-serif;
     font-size: 34px;
     line-height: 35px;
     text-align: center;
     color: #FFFFFF;
  }

  .lav-crypto .lav-replenish-title {
     margin-top: 51px;
  }

  .lav-replenish-text {
     width: auto;
     font-family: "Inter", sans-serif;
     margin-top: 10px;
     font-size: 16px;
     line-height: 21px;
     text-align: center;
     color: #E4E4E4;
  }

  .lav-card-btn,
  .lav-crypto-btn {
     margin-top: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 270px;
     height: 65px;
     background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #1A93CD 100%);
     border-radius: 16px;
     font-family: "Inter", sans-serif;
     font-size: 20px;
     line-height: 26px;
     color: #080808;
  }

  .lav-crypto {
     justify-content: flex-end;
  }

  .lav-crypto-circle {
     position: fixed;
     width: 100vh;
     height: 100vh;
     top: 50vh;
     z-index: -1;
     background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%);
     filter: blur(49.45px);
     border-radius: 50%;
  }

  /* lav-replenish END */

}

/* lav-referrals */
.lav-referrals {
  display: flex;
  flex-direction: column;
  padding: 60px 12px;
}

.lav-referrals-title {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
}

.lav-referrals-wrapper {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.lav-referrals__item {
  position: relative;
  width: 100%;
  padding: 12px 0;
  background: #101010;
  box-shadow: 0px 4px 15.5px rgba(0, 0, 0, 0.13);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.lav-referrals__item.lav-open {
  background: radial-gradient(50% 58.88% at 50% 50%, #FFD438 0%, #ED8F03 100%);
}

.lav-referrals__item_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Inter", sans-serif;
}

.lav-referrals__item_main-user {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.lav-referrals__item_main-user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  flex: 1;
}

.lav-row {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

.lav-referrals__item_main-data {
  display: flex;
  gap: 15px;
  font-size: 14px;
  line-height: 17px;
}

.lav-referrals__item_main-title {
  font-weight: 400;
  color: #4B4B4B;
}

.lav-referrals__item_main-user svg {
  transition: .3s;
}

.lav-open svg {
  rotate: 180deg;
}

.lav-open .lav-referrals__item_main-title {
  color: #FFFFFF;
}

.lav-referrals__item_main-sum {
  font-weight: 500;
  color: #FFFFFF;
}

.lav-referrals__item_additionally {
  display: flex;
  max-height: 0;
  overflow: hidden;
  flex-direction: column;
  gap: 13px;
  position: relative;
  transition: .3s;
  padding-left: 12px;
  padding-right: 12px;
}

.lav-open .lav-referrals__item_additionally {
  padding-top: 18px;
  max-height: 500px;
}

.lav-open .lav-referrals__item_additionally::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 1px;
  background-color: #CACACA;
  ;
}

.lav-referrals__item_additionally-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #4F75CA;
  padding-bottom: 10px;

}

.lav-referrals__item_additionally-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.lav-referrals__item_additionally-item-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #D2D2D2;
}

.lav-click-fon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

/* lav-referrals END */

/* lav-statistics */
.lav-statistics {
  padding: 60px 22px 104px;
  display: flex;
  flex-direction: column;
}

.lav-statistics-title {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  text-align: center;
}

.lav-statistics_wrapper {
  margin-top: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.lav-statistics_item {
  width: 100%;
  height: 172px;
  display: flex;
  flex-direction: column;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
}

.lav-statistics_item-title {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #E4E4E4;
  border-bottom: 1px solid #fff;
}

.lav-statistics_item-data {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  line-height: 22px;
  color: #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

/* lav-statistics END*/
  </style>
  
  <script>
  // import Telegram from "./components/Telegram.vue";
  import setTooltip from "@/assets/js/tooltip.js";
//   import RankingList from "@/examples/Cards/RankingList.vue";
//   import RankingListCard from "@/examples/Cards/RankingListCard.vue";
  
//   import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
  // import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
//   import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
  // import TimelineList from "./components/TimelineList.vue";
  // import TimelineItem from "./components/TimelineItem.vue";
  // import ProjectsCard from "./components/ProjectsCard.vue";
  import US from "../assets/img/icons/flags/US.png";
  import DE from "../assets/img/icons/flags/DE.png";
  import GB from "../assets/img/icons/flags/GB.png";
  import BR from "../assets/img/icons/flags/BR.png";
  import { mapMutations,mapGetters,mapActions  } from "vuex";
  
  import {
    faHandPointer,
    faUsers,
  
    faCreditCard,
    faScrewdriverWrench,
  } from "@fortawesome/free-solid-svg-icons";
  export default {
    name: "dashboard-default",
    data() {
      return {
        change: {
          in:{
            icon: 'fa-arrow-down',
            color: 'primary',
            sub: 'Депозит'
          },
          out:{
            icon: 'fa-arrow-up',
            color: 'danger',
            sub: 'Успешный вывод'
          },
          reinvest:{
            icon: '',
            color: 'dark',
            sub: 'Транзакция'
          },
          reinvest_in:{
            icon: 'fa-arrow-left',
            color: 'info',
            sub: 'Выведено (реинвест)'
          },
          reinvest_out:{
            icon: 'fa-arrow-right',
            color: 'info',
            sub: 'Депозит (реинвест)'
          }
        },
        req: {
          in:{
            icon: 'fa-arrow-down',
            color: 'info',
            sub: 'Депозит'
          },
          out:{
            icon: 'fa-arrow-up',
            color: 'info',
            sub: 'Заявка на вывод'
          },
          reinvest:{
            icon: 'fa-arrow-up',
            color: 'dark',
            sub: 'Реинвестирование'
          }
        },
        iconBackground: "bg-gradient-info",
        faCreditCard,
        faScrewdriverWrench,
        faUsers,
        faHandPointer,
        sales: {
          us: {
            country: "United States",
            sales: 2500,
            value: "$230,900",
            bounce: "29.9%",
            flag: US,
          },
          germany: {
            country: "Germany",
            sales: "3.900",
            value: "$440,000",
            bounce: "40.22%",
            flag: DE,
          },
          britain: {
            country: "Great Britain",
            sales: "1.400",
            value: "$190,700",
            bounce: "23.44%",
            flag: GB,
          },
          brasil: {
            country: "Brasil",
            sales: "562",
            value: "$143,960",
            bounce: "32.14%",
            flag: BR,
          },
        },
      };
    },
    methods: {
      ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
      ...mapActions(["getUserData","getTransactions","getBotsUser"]),
      withdraw(id){
        this.toggleWithdraw(id)
      },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      callSupport(){
        if(this.isMobile){
          this.$toast.info("Сейчас начнется вызов", { });
          location.href='tel:+78007074730';
        }
        else{
          this.$toast.info("Вы с компьютера или с планшета, наберите номер на мобильном", { });
        }
  
      },
    },
    computed:{
      ...mapGetters(["user","userPlot","userTransactions","userBots","userRequests"]),
      cards(){
        return {
              title: 'Транзации',
              item:this.transactions
            }
       },
       requests(){
        var tt = []
        this.userRequests.forEach(element => {
            var t_amount = '-'
            if (element.value){
              t_amount = element.value.toLocaleString('en')
            }
  
            tt.push({
              title: element.portfolio.name,
              date: `${this.req[element.in_out].sub} от ${element.date}`,
              amount: `${t_amount} $`,
              icon:  this.req[element.in_out].icon,
              color: this.req[element.in_out].color,
          })
        });
        return tt
  
       },
  
       transactions(){
        var tt = []
        this.userTransactions.forEach(element => {
            var t_amount = '-'
            if (element.value){
              t_amount = element.value.toLocaleString('en')
            }
            if (element.source === 'reinvest') element.in_out =  `reinvest_${element.in_out}`
           
           var sub =  'Транзакция'
           var icon =  ''
           var color = 'dark'
  
            if (this.change[element.in_out]) {
              sub = this.change[element.in_out].sub 
              icon = this.change[element.in_out].icon
              color = this.change[element.in_out].color
            }
  
            tt.push({
              title: element.portfolio.name,
              date: `${sub} от ${element.date}`,
              amount: `${t_amount} $`,
              icon:  icon,
              color: color,
          })
        });
        return tt
  
       }
    },
    components: {
    //   MiniStatisticsCard,
      // ReportsBarChart,
      // Telegram,
    //   GradientLineChart,
    //   RankingList,
    //   RankingListCard,
      // ProjectsCard,
      // TimelineList,
      // TimelineItem,
    },
    unmounted() {
      this.root.style.setProperty("--bs-body-bg", "#fff");
    },
     mounted() {
      
      setTooltip();
      this.getTransactions()
      this.getBotsUser()
      this.root = document.documentElement;
      this.root.style.setProperty("--bs-body-bg", "#0C0C0C");
    },
  };
  </script>
  