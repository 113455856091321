/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import Toaster from "@meforma/vue-toaster";
import VueClipboard from 'vue3-clipboard'
import './registerServiceWorker'
import { createI18n } from 'vue-i18n';
import ru from '/translate/ru.js'
import en from '/translate/en.js'
import {vueTelegramLogin} from 'vue-telegram-login'
const messages = {
  en:en,
  ru:ru
}

// Create VueI18n instance with options


const appInstance = createApp(App);

const i18n = new createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

appInstance.use(store);
appInstance.use(router);
appInstance.use(i18n);
appInstance.use(VueClipboard);
appInstance.use(vueTelegramLogin);

appInstance.use(SoftUIDashboard);
appInstance.use(Toaster,{
    // One of the options
    position: "top",
    timeout: 10000,
  });

// Dismiss all opened toast immediately
appInstance.mount("#app");
