<template>
  <aside
     style="padding-top:20px; overflow-x: hidden !important; "
      class="overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs "
      id="sidenav-main"
  >
    <!-- <div class="fadeshow1 justify-content-end"  style="position: absolute; right:15px; top:43px;">
        <button type="button" class="btn-close" @click='toggleSidebar' aria-label="Close"></button>
  </div> -->
    <div class="sidenav-header" >
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      
      <div class="m-0 navbar-brand justify-content-end " >
        <img :src="logo" class="navbar-brand-img mb-2 " style="height:300px !important" alt="main_logo" />
        <span class="ms-2 text-light" style="font-size: 22px;">Telos <strong>Market</strong></span>
      </div>
      
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="customClass" />
  </aside>
</template>

<style>
@media only screen and (min-width: 700px) {
  .fadeshow1 {
      display: none;
  }
}

</style>
<script>
import SidenavList from "./SidenavList.vue";
import { mapMutations } from "vuex";
import logo from "@/assets/img/logologologo.png";
// import Ancash from "../../components/Icon/Close.vue";
import { mapState } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    toggleSidebar() {
      this.navbarMinimize();
    },
    mobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
   
  },

  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
    
  },
};
</script>
