<template>
  <div class="h-100">
      <ul class="list-group">
        <li class="text-sm border-0 list-group-item ps-0">
          <div class="input-group mb-2 " >
            <input 
              id="email"
              type="text"
              :value="info.email"
              placeholder="example, info@telosmarket.com"
              disabled
              name="email"
              class="form-control text-upgraded border-polina"
              style="opacity:0.5" 
              aria-label="Ваш email">
          </div>
        </li>
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          
          <div class="input-group mb-0" >
              <span class="input-group-text text-upgraded"><strong class="text-dark">{{$t('profile.fio') }} </strong> &nbsp;</span>
              <input 
                id="first_name"
                type="text"
                :value="info.fullName"
                @change="user_data.first_name = $event.target.value"
                :placeholder="$t('profile.full_fio')"
                name="first_name"
                class="form-control text-upgraded border-polina" 
                aria-label="Ваше имя и фамилия">
          </div>

        </li>

        <li class="text-sm border-0 list-group-item ps-0">
          <div class="input-group mb-0 " >
            <span class="input-group-text text-upgraded"><strong class="text-dark">{{$t('profile.phone') }} </strong> &nbsp;</span>
            <input 
              id="phone"
              type="text"
              :value="info.mobile"
              @change="user_data.phone = $event.target.value"
              :placeholder="$t('profile.number_phone')"
              name="phone"
              class="form-control text-upgraded border-polina" 
              aria-label="Ваш номер телефона для связи">
          </div>
        </li>

        <li class="text-sm border-0 list-group-item ps-0">
          <div v-if="updatingUser" class="polita-btn d-flex w-100" style="opacity: 0.5;" > {{$t('profile.compl_save') }}</div>
          <div v-if="!updatingUser"  class="polita-btn flex d-flex w-100"  @click="save" > {{$t('profile.save') }} </div >
        </li>
        <hr>
  
        
      </ul>
  </div>
</template>

<style scoped>
.input-group-text{
  /* Имя и Фамилия */
  font-weight: 600;
}
.border-polina{
  background: #FFFFFF;
  border: 1px solid #BABDC5;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px !important;
}
.border-polina:focus{
  box-shadow:none !important;
  border:1px solid #919191 !important;
}
.text-upgraded{
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1A1A1A;

}
.polita-btn-blue{
  background: rgb(103, 145, 247);
  border-radius: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #FFFFFF;
  height: 62px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  vertical-align:baseline;
}
.polita-btn{
  border: 3px solid #676767;
  border-radius: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
  height: 62px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  vertical-align:baseline;
}
</style>
<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {mapActions,mapGetters } from "vuex";

export default {
  name: "ProfileInfoCard",
  data() {
    return {
      user_data:{},
      loading:false
    }
  },
  components: {
    // FontAwesomeIcon,
  },
  computed:{
    ...mapGetters(["updatingUser"])
  },
  methods: {
    ...mapActions(["updateUser"]),
    save(){
      this.loading = true;
      this.updateUser({vm:this,data:this.user_data})
      .then(response => {
        this.loading = false;
        console.log(response)

      })
        
    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      location: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
};
</script>
