<template>
  <div class="card mt-2" 
    :class="detailed ?  'bg-detailed' : 'bg-dark'"
    >
    <div 
    class="card-body text-light related ps-3 pt-1 pb-1 "
    :style="item.closingdeps>0 ?  'border-radius:15px; background:rgba(255,0,0,0.13)' : ''"
    >
      <div class="pt-2" style="height:50px">
        <span class="pb-2" >
          <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="2" width="30" height="30" rx="15" fill="#fff"/>
            <path d="M22 9.5C20.035 9.5 18.4375 11.0975 18.4375 13.0625C18.4375 14.99 19.945 16.55 21.91 16.6175C21.97 16.61 22.03 16.61 22.075 16.6175C22.09 16.6175 22.0975 16.6175 22.1125 16.6175C22.12 16.6175 22.12 16.6175 22.1275 16.6175C24.0475 16.55 25.555 14.99 25.5625 13.0625C25.5625 11.0975 23.965 9.5 22 9.5Z" fill="#000"/>
            <path d="M25.8097 18.6124C23.7172 17.2174 20.3047 17.2174 18.1972 18.6124C17.2447 19.2499 16.7197 20.1124 16.7197 21.0349C16.7197 21.9574 17.2447 22.8124 18.1897 23.4424C19.2397 24.1474 20.6197 24.4999 21.9997 24.4999C23.3797 24.4999 24.7597 24.1474 25.8097 23.4424C26.7547 22.8049 27.2797 21.9499 27.2797 21.0199C27.2722 20.0974 26.7547 19.2424 25.8097 18.6124Z" fill="#000"/>
            <defs>
            <radialGradient id="paint0_radial_403_422" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22 17) scale(27 72.6261)">
            <stop stop-color="#557AD3"/>
            <stop offset="1" stop-color="#05379B"/>
            </radialGradient>
            </defs>
            </svg>
            
          </span>
            <span style="font-weight: 400; font-size:20px; " class=" ps-2">{{item.first_name}}   (#{{item.secret_code}})</span>
      </div>
     
      <div class="mt-2" v-if="detailed && subStructure.private_data">
        <span style="font-size: 20px; font-weight:700" class="">
          {{$t('statref.deposit') }} {{subStructure.private_data.deposits.toLocaleString('en')}} {{$t('statref.valuta') }} 
        </span>
        <div style="border-top:1px solid #fff" class="mt-2 mb-4"></div>

        <!-- <template v-if="item.depositlose>0">
        <br>
        {{$t('statref.vivel') }} <span class="" style="color:red"> -{{ subStructure.private_data.depositlose.toLocaleString('en') }}  {{$t('statref.valuta') }} </span>
        </template> -->
        <!-- <br>{{$t('statref.plan_vivod') }}{{ subStructure.private_data.closingdeps }} {{$t('statref.valuta') }} -->
        {{$t('statref.reinvest') }} {{subStructure.private_data.reinvest}}  {{$t('statref.valuta') }}
      </div>
      <div class="mt-2 mb-2" v-else>
        <span style="font-size: 20px; font-weight:700" class="">
        {{$t('statref.deposit') }} {{item.deposits.toLocaleString('en')}} {{$t('statref.valuta') }} 
        </span>


        <!-- <template v-if="item.depositlose>0">
        <br>
        {{$t('statref.vivel') }} <span class="" style="color:red"> -{{ item.depositlose.toLocaleString('en') }}  {{$t('statref.valuta') }} </span>
        </template> -->
        <!-- <br>{{$t('statref.plan_vivod') }}{{ item.closingdeps }} {{$t('statref.valuta') }} -->
        <!-- {{$t('statref.reinvest') }} {{item.reinvest}}  {{$t('statref.valuta') }} -->
      </div>
       
      <template v-if="detailed"> 
        <div class="small mb-1">
          <hr class="m-1">
          {{$t('statref.date') }} {{item.joined_at}} 
          <hr class="m-1">
          {{$t('statref.proglasil') }} #{{ item.promo }} 
          <template v-if="subStructure.name_ref">({{subStructure.name_ref }})</template>

          <template v-if="subStructure===-1">
            <hr class="m-1">
            <div class="spinner-grow spinner-grow-sm me-2" role="status">
              <span class="visually-hidden">{{$t('statref.loading') }}</span>
            </div>
            {{$t('statref.zagruzka') }}
          </template>
          
          <template v-else>
            <hr class="m-1">
            {{$t('statref.obiem') }} {{ subStructure?.total?.toLocaleString('en') }} {{$t('statref.valuta') }} 
            <hr class="m-1">

            {{$t('statref.razmer') }} {{ subStructure.people }} {{$t('statref.chelovek') }}
            <hr class="m-1">

            <div v-if="level==1" class="mb-2 mt-0" id="addon-wrapping"   @click="copyContact(subStructure.contact)" style="cursor:pointer">
              <i
                class="fas fa-copy me-2"
              ></i>  
              <strong>{{subStructure.contact}}</strong>
            </div>
            <strong v-else>{{subStructure.contact}}</strong> 
            <hr class="m-1">
            
            <i class="" style="font-size: 11px !important; opacity:0.4">
              {{$t('statref.actual_data') }}
               </i>
          </template>
        </div> 
      </template>
      <div v-if="detailed" @click="detailed=false" class="cursor-pointer" style="position:absolute; top:10px; right:20px;  transform:rotate(180deg)">
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.72456 6.3094C3.94645 6.09861 4.29367 6.07945 4.53835 6.25191L4.60845 6.3094L9.99984 11.431L15.3912 6.3094C15.6131 6.09861 15.9603 6.07945 16.205 6.25191L16.2751 6.3094C16.497 6.5202 16.5172 6.85006 16.3356 7.0825L16.2751 7.14909L10.4418 12.6908C10.2199 12.9016 9.87267 12.9207 9.62799 12.7482L9.5579 12.6908L3.72456 7.14909C3.48048 6.91722 3.48048 6.54128 3.72456 6.3094Z" fill="white"/>
          </svg>
        
      </div>
      <div v-else  @click=" showDetail()"  class="cursor-pointer"   style="position:absolute; top:10px; right:20px;">
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.72456 6.3094C3.94645 6.09861 4.29367 6.07945 4.53835 6.25191L4.60845 6.3094L9.99984 11.431L15.3912 6.3094C15.6131 6.09861 15.9603 6.07945 16.205 6.25191L16.2751 6.3094C16.497 6.5202 16.5172 6.85006 16.3356 7.0825L16.2751 7.14909L10.4418 12.6908C10.2199 12.9016 9.87267 12.9207 9.62799 12.7482L9.5579 12.6908L3.72456 7.14909C3.48048 6.91722 3.48048 6.54128 3.72456 6.3094Z" fill="white"/>
          </svg>
          


      </div>
        
    </div>
  </div>
</template>
<style scoped>
.bg-detailed {
  background: #2f2f2f
}
</style>

<script>
import {  mapActions } from "vuex";
export default {
  name: "SoftPagination",
  props: {
    item:[],
    level:Number,
  },
  data() {
    return {
      detailed:false,
      subStructure:-1,
    };
  },

  methods: {
  ...mapActions([ "getUserSubStructure",]),
    showDetail () {
      this.detailed = true;
      this.getUserSubStructure({'id':this.item.secret_code})
      .then(response => {
        console.log(response.data.data)
        this.subStructure = response.data.data
      })
    },
    async copyContact(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(this.$t('statref.copy_buffer'), { });
      } catch($e) {
        this.$toast.error(this.$t('statref.no_copy'), { });
      }
    }
  },
};
</script>
