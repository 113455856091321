<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >

  <div class="d-flex justify-content-center">
    <div  @click="toggleSidebar" class="pb-1 pt-1 ps-3 pe-3 align-items-center d-flex text-center justify-content-center" style=" border-radius: 10px; height:30px; width: fit-content; background-color: #efefef;">
      <svg style="opacity: 0.3;" v-if="sidebarPinned"   width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.9697 15V9C21.9697 4 19.9697 2 14.9697 2H8.96973C3.96973 2 1.96973 4 1.96973 9V15C1.96973 20 3.96973 22 8.96973 22H14.9697C19.9697 22 21.9697 20 21.9697 15Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.96973 2V22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9702 9.44L12.4102 12L14.9702 14.56" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <svg style="opacity: 0.4;"  v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.9697 15V9C21.9697 4 19.9697 2 14.9697 2H8.96973C3.96973 2 1.96973 4 1.96973 9V15C1.96973 20 3.96973 22 8.96973 22H14.9697C19.9697 22 21.9697 20 21.9697 15Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9697 2V22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.96973 9.44L10.5297 12L7.96973 14.56" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class=" ms-3 pb-n1 pt-1 ps-3 pe-3 align-items-center d-flex text-center justify-content-center" style="opacity: 0.7; border-radius: 10px;height:30px;width: fit-content; background-color: #FAB23C;">
        <svg class="mb-1" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.811 7.87655C14.811 11.3403 12.2964 14.5845 8.86099 15.5336C8.62724 15.5974 8.37222 15.5974 8.13847 15.5336C4.70306 14.5845 2.18848 11.3403 2.18848 7.87655V4.76695C2.18848 4.18612 2.62765 3.52737 3.17307 3.30779L7.11847 1.69281C8.00389 1.33156 9.00264 1.33156 9.88806 1.69281L13.8335 3.30779C14.3718 3.52737 14.8181 4.18612 14.8181 4.76695L14.811 7.87655Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.49967 8.85409C9.28208 8.85409 9.91634 8.21982 9.91634 7.43742C9.91634 6.65502 9.28208 6.02075 8.49967 6.02075C7.71727 6.02075 7.08301 6.65502 7.08301 7.43742C7.08301 8.21982 7.71727 8.85409 8.49967 8.85409Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 8.854V10.979" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        <span class="ms-1 mb-1">#{{user.secret_code}}</span>
      </div>
  </div>


  <div @click="navbarMinimize" class="d-md-none " >
      <svg style="opacity: 0.8;" v-if="sidebarPinned"   width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.9697 15V9C21.9697 4 19.9697 2 14.9697 2H8.96973C3.96973 2 1.96973 4 1.96973 9V15C1.96973 20 3.96973 22 8.96973 22H14.9697C19.9697 22 21.9697 20 21.9697 15Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.96973 2V22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9702 9.44L12.4102 12L14.9702 14.56" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  </div>

    <template>
      
    </template>
    <div class="px-1 py-1 container-fluid">
      <breadcrumbs style="display: none;" :currentPage="currentRouteName" :textWhite="textWhite" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          

        </div>
        <!-- <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center mennu">
            <div class="bg-white " style="border-radius: 50%; width:50px;  height:50px; align-content:center; border:1px solid black; ">
            <a
              href="#"
              @click="toggleSidebar"
              style=""
              class="nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </div>
          </li>
        </ul> -->
      </div>
    </div>
  </nav>
</template>
<style>

</style>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      RU: {
        Dashboard: 'Главная',
        Invest: 'Программы',
        TopUp: 'Пополнение',
        Withdraw: 'Вывод',
        Reports: 'Отчеты',
        Profile: 'Профиль',
        Exchange: 'Добавит биржу',
        },
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "navbarMinimizeLg","toggleConfigurator","toggleTopUpOwn"]),
    ...mapActions(["toggleSidebarColor","logout"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-dark");
      this.navbarMinimize();
    },
    download_app(){
      window.location = 'https://l1st.online'
    },
   
    topUp(){
      this.$router.push('/inapp/top-up')
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapGetters(["user",'sidebarPinned']),
    currentRouteName() {
      if (this.$route.name in this.RU)
        return this.RU[this.$route.name]
      else
        return this.$route.name
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
