<template>
  <div class="fixed-plugin " >
    <div class="card " style="background-color: #1B1B1B; overflow-y: scroll; overflow-x: hidden !important; border-left:1px solid rgb(228, 228, 228);">
      <div @click="close_window" style="position:absolute; right:20px;top:20px;cursor:pointer;" >
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.6107 2.91675H11.3899C6.08158 2.91675 2.91699 6.08133 2.91699 11.3897V23.5959C2.91699 28.9188 6.08158 32.0834 11.3899 32.0834H23.5962C28.9045 32.0834 32.0691 28.9188 32.0691 23.6105V11.3897C32.0837 6.08133 28.9191 2.91675 23.6107 2.91675ZM22.4003 20.8542C22.8232 21.2772 22.8232 21.9772 22.4003 22.4001C22.1816 22.6188 21.9045 22.7209 21.6274 22.7209C21.3503 22.7209 21.0732 22.6188 20.8545 22.4001L17.5003 19.0459L14.1462 22.4001C13.9274 22.6188 13.6503 22.7209 13.3732 22.7209C13.0962 22.7209 12.8191 22.6188 12.6003 22.4001C12.1774 21.9772 12.1774 21.2772 12.6003 20.8542L15.9545 17.5001L12.6003 14.1459C12.1774 13.723 12.1774 13.023 12.6003 12.6001C13.0232 12.1772 13.7232 12.1772 14.1462 12.6001L17.5003 15.9542L20.8545 12.6001C21.2774 12.1772 21.9774 12.1772 22.4003 12.6001C22.8232 13.023 22.8232 13.723 22.4003 14.1459L19.0462 17.5001L22.4003 20.8542Z" fill="white"/>
          </svg>
      </div>
     <section class="lav-replenishment " style="margin-bottom:100px;">
      <h1 class="lav-replenishment-tile">Пополнение
        
      </h1>
      <div class="w-100 mt-2">
        <p class="lav-replenishment-subtitle ">Выберите метод пополнения</p>
      </div>

      <div class="lav-replenishment__period mt-0"> 
       
        <div  class="lav-replenishment__period_item lav-replenishment-select w-45 ">
            <div class="lav-replenishment__period_item-name " style="border: none;">Через Банк</div>
        </div>
        <div  class="lav-replenishment__period_item  w-45" @click="opencrypto()">
          <div class="lav-replenishment__period_item-name"  style="border: none;">Криптовалюта</div>
      </div>

      </div>
      <div class="w-100 mt-4">
        <p class="lav-replenishment-subtitle ">Выберите счет пополнения</p>
      </div>
      
      <!-- <div   class=" mt-2 w-100 d-flex lav-replenishment__period_item-union" style="opacity:0.3">
          <div class="lav-replenishment__period_item-name-union text-light ">Единый торговый аккаунт <br> <span class="" style="font-size: 10px;">переводы, реинвестирование, ниже комиссии</span></div>
          <div class="lav-replenishment__period_item-sum-union text-light ">0 руб</div>
          <div class="lav-replenishment__period_item-soon ">скоро</div>
      </div> -->

      <div class="lav-replenishment__period"> 
       <template v-for="(item,index) in userBots"  :key="item.slug">
        <div 
            @click="robot = item;check_index=index" 
            :class="[index === check_index ? 'lav-replenishment-select': null]"
            class="lav-replenishment__period_item "  v-if="item.slug != 'union'">
            <div class="lav-replenishment__period_item-name">{{item.name}}</div>
            <div class="lav-replenishment__period_item-sum">на счету <br> {{item.deposits.toLocaleString('en')}} {{$t('top_up.valuta') }}</div>
        </div>
      </template>

      </div>
      <div class="w-100 mt-3">
        <text class="mt-2" style="color:rgba(157, 157, 157,0.4)" >{{$t('top_up.minimum') }} {{minLimit.toLocaleString('en')}} {{$t('top_up.valuta') }} </text>
      </div>

      <div class="lav-sum-replenishment">
        <h2 class="lav-sum-replenishment-title">Сумма пополнения</h2>
        <input id="price"
          type="number"
          v-model='price'
          name="price"
          @change="price=$event.target.value"
          class="lav-sum-replenishment-input" 
          :aria-label="$t('top_up.summa')"
          placeholder="сумма">

        <div class="lav-sum-replenishment_choice">
            <div  @click="price=1000" class="lav-sum-replenishment_choice-item">1,000</div>
            <div  @click="price=5000"  class="lav-sum-replenishment_choice-item">5,000</div>
            <div  @click="price=15000"  class="lav-sum-replenishment_choice-item">15,000</div>
        </div>
      </div>

      <div v-if="price" class="lav-replenishment-promocode-btn" href="#">Вы пополняете счет "{{robot.name}}" на сумму  {{ price.toLocaleString('en') }} $, с комиссией 1%, на баланс пуступит  {{(price*(1-0.01)).toLocaleString('en') }} $ </div>
      <div v-else class="lav-replenishment-promocode-btn" href="#">Выберите счет и укажите сумму пополнения </div>

      <!-- <div class="lav-replenishment-btn">Пополнить</div> -->

      <div @click="topUpAnCSh" class="lav-replenishment-btn cursor-pointer">
        <div class="spinner-grow spinner-grow-sm me-1"  v-if="loading" style="color:  #000!important" role="status">
          <span class="visually-hidden">{{$t('top_up.loading') }}</span>
        </div>
        <template v-if="loading">
          {{$t('top_up.zagruzka') }}
        </template>
        <template v-else>
          {{$t('top_up.popolnit') }}
        </template>
      </div>

    </section>
  </div>
</div>
  <!-- <div class="fixed-plugin " >
  
    <div class="shadow-lg card my-bg pt-4 " style="overflow-y: scroll; overflow-x: hidden !important; border-left:1px solid rgb(228, 228, 228);">
      <div >
        <h2 class="float-start alex__replenishment-title">{{$t('top_up.popolnenie') }}</h2>
        <div class="me-2 pe-3 pt-1 float-end "  >
              <close @click="close_window"/>
        </div>
      </div>
      <div class="alex__replenishment-description">{{$t('top_up.schet_popolneniya') }}</div>
  

     <div class="row alex__replenishment-description">
      <div 
          class='col-6'
          v-for="(item,index) in userBots"
          :key="item.slug"
        > 
            <div  @click="robot = item;check_index=index" class="mt-2 alex-replenishment__choice-item " 
              :class="[index === check_index ? 'alex-choice-item-select': null]">{{item.name}} <br>
               <small style="opacity: 0.5;">{{item.deposits.toLocaleString('en')}} {{$t('top_up.valuta') }} </small>
              </div>
            </div> 
      </div>

     <div class="alex-choice__money_wrapper mt-3">
        <div class="alex-input__box">
          <input id="price"
          type="number"
          v-model='price'
          name="price"
          :disabled="robot.slug==='gamma'"
          @change="price=$event.target.value"
          class="form-control" 
          :aria-label="$t('top_up.summa')"
          :placeholder="$t('top_up.summa')">
        </div>
        
        <div @click="price=8000" class="alex-choice__money_item">8,000</div>
        <div @click="price=10000" class="alex-choice__money_item">10,000</div>
        <div @click="price=25000"  class="alex-choice__money_item">25,000</div>
        
    </div>
    <div class="row">
      <text class="mt-2 alex-checkbox__label" >{{$t('top_up.minimum') }} {{minLimit.toLocaleString('en')}} {{$t('top_up.valuta') }} </text>
    </div>

    <a ata-bs-toggle="collapse" style=" cursor:pointer" class="mt-2" @click="toggle_promocode()">
      <span class="badge badge-sm bg-gradient-dark" style="border-radius: 10px; width:auto; height:auto; font-size: 10px;">+ промокод</span>
    </a>
    <div class="mt-2" v-if="showPromoCode">
      <div class="alex-input__box">
          <input 
          style="height:15px; padding: 5px 10px 5px 10px; font-size: 14px; width: 100%;"
          type="number"
          v-model="promocode"
          class="form-control" 
          placeholder="Промокод">
        </div>

    </div>

    <div @click="topUpAnCSh" class="alex-btn-top-up withdraw mt-5 cursor-pointer">
      <div class="spinner-grow spinner-grow-sm me-1"  v-if="loading" style="color:  #000!important" role="status">
        <span class="visually-hidden">{{$t('top_up.loading') }}</span>
      </div>
      <template v-if="loading">
        {{$t('top_up.zagruzka') }}
      </template>
      <template v-else>
        {{$t('top_up.popolnit') }}
      </template>
    </div>
    
   

   
    </div>
  </div> -->
</template>

<style scoped>
.lav-replenishment__period_item-soon{
  position: absolute;
  right: -10px;
  top:-10px;
  background-color: red;
  padding: 2px 5px;
  border-radius: 5px;
  color: #fff;
}
.lav-replenishment__period_item-union{
  position: relative;
  align-items: center;
  background: #2E2E2E;
  height: 70px;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 17%);
  cursor: not-allowed;
  text-align: center;
}

.lav-replenishment__period_item-name-union{
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 15px;
  width: 70%;
  text-align: center;
  color: #FFFFFF;
}

.lav-replenishment__period_item-sum-union {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
}

</style>
<style scoped>






/* @media (prefers-reduced-motion: no-preference) {
  html {
     scroll-behavior: smooth;
  }
} */

.lav-desktop {
  display: flex !important;
}

.lav-mobile {
  display: none !important;
}

body {
  background: #0C0C0C;
  font-family: "Inter", sans-serif;
  color: #fff;
  overflow-x: hidden;
  height: auto;
}

/* lav-replenishment */
.lav-replenishment {
  max-width: 390px;
  width: 100%;
  padding: 80px 0px 0px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lav-replenishment-tile {
  font-family: "Inter", sans-serif;
  font-size: 34px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
}

.lav-replenishment-subtitle {
  font-family: "Inter", sans-serif;
  margin-top: 16px;
  font-size: 16px;
  line-height: 21px;
  color: #E4E4E4;
}

.lav-replenishment__period {
  width: 100%;
  margin-top: 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 11px 0;
}

.lav-replenishment__period_item {
  width: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2E2E2E;
  border-radius: 12px;
  border: 1px solid rgb(255 255 255 / 17%);
  cursor: pointer;
}

.lav-replenishment-select {
  border: 1px solid transparent;
  background: radial-gradient(50% 50% at 50% 50%, #FAB23C 0%, #E1A050 100%);;;
}


.lav-replenishment__period_item-name {
  font-family: "Inter", sans-serif;
  padding: 23px 0 18px;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  border-bottom: 1px solid rgb(255 255 255 / 17%);
}

.lav-replenishment__period_item-sum {
  padding: 48px 0 30px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
}

.lav-sum-replenishment {
  margin-top: 41px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lav-sum-replenishment-title {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  line-height: 35px;
  color: #FFFFFF;
}

.lav-sum-replenishment-input {
  margin-top: 22px;
  background: #2e2e2e;
  border-radius: 16px;
  padding: 18px 22px;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  line-height: 34px;
  color: #b3b3b3;
  border:0px;
}

.lav-sum-replenishment-input::-webkit-outer-spin-button,
.lav-sum-replenishment-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.lav-sum-replenishment-input,
.lav-sum-replenishment-input:hover,
.lav-sum-replenishment-input:focus {
  appearance: none;
  -moz-appearance: textfield;
  border-color:#000000 !important;
  box-shadow:0 0 0 0px #ffffff !important;
}

.lav-sum-replenishment_choice {
  margin-top: 19px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.lav-sum-replenishment_choice-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 9px;
  background: #1B1B1B;
  border-radius: 90px;
  cursor: pointer;
  border: 1px solid rgb(255 255 255 / 17%);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #F2F2F2;
}

.lav-replenishment-promocode-btn {
  margin: 80px 0;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  position: relative;
}

.lav-replenishment-promocode-btn::before {
  content: '';
  position: absolute;
  width: 149px;
  height: 149px;

  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate3d(-50%, -50%, 0);


  background: radial-gradient(50% 50% at 50% 50%, #FFB800 0%, #483400 100%);
  filter: blur(26.35px);
  border-radius: 50%;
}

.lav-replenishment-btn {
  width: 100%;
  padding: 20px 0 19px;
  background: radial-gradient(50% 50% at 50% 50%, #FAB23C 0%, #E1A050 100%);;
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
}

.lav-crypt .lav-sum-replenishment_choice {
  gap: 10px 5px;
}
.lav-crypt .lav-sum-replenishment_choice-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 19px 15px 15px;
  background: #000000;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
}

/* lav-replenishment END */


</style>

<script>

// import SoftInput from "@/components/SoftInput.vue";
import { mapMutations, mapActions,mapGetters } from "vuex";
export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      fixedKey: "",
      showPromoCode: false,
      promocode: '',
      check_index: 0,
      price: null,
      loading:false,
      min: 90,
      robot:{
         "id": 1, "name": "День", "slug": "alpha", "minimum": 100, "maximum": 83333, "revenue_from": 0.99, "revenue_to": 1.05, "reinvest": true, "days": 60, "description": "Это искусственный интеллект, который заранее определяет куда может пойти тот или иной актив, путем анализа поведения “толпы” в соц.сетях. \r\n\r\nТорговля осуществляется от фондового рынка, сырьевого рынка, криптовалюты и NFT- проектов.", "summa_text": "от 700 $", "vyvod_text": "Ежедневно", "vyvod_totla_text": "2 месяца", "risk": 1, "revenue_text_total": "От 59 до 63%", "revenue_text": "0,99-1,05% / день", "deposits": 0, "total_revenue": 59, "withdrow_period": 1, "image": "https://backend.l1st.online/media/portfolio/bons.png", "date": "09/10/2022, 19:17", "available": 0, "transactions": [] 
        },
    };
  },
  components: {
    // SoftRadio,
    // SoftAlert,
    // Close,
    // SoftInput
  },

  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor","getUserData", "AnCashPayment"]),
    myalert(){
      window.alert('уточняйте у менеджеров')
    },
    close_window(){
      console.log('close window')
      this.$store.state.topUpOwn = false;
    },
    opencrypto(){
      this.$store.state.topUpCrypto = true;
      this.$store.state.topUpOwn = false;
    },
    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },
    toggle_promocode(){
      this.showPromoCode = !this.showPromoCode;
    },
    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
    topUpAnCSh(){
      if (this.price < this.minLimit){
        this.$toast.error("Сумма должна превышать минимальный порог", { });
        return 1
      }
      if (this.loading) {
        this.$toast.info("loading...", { });
        return 1
      }

      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 5000);
      // if(this.price < 1000) alert('Введите корректную сумму пополнения')
      var data = {
        robot: this.robot.slug,
        value: this.price,
        email: this.user.email,
        phone: this.user.phone,
        promocode: this.promocode, 
      }
      this.AnCashPayment({vm:this,data:data})
      
    },

  },
  watch:{
    robot(val){
      console.log(val)
      // if(val.slug === 'gamma'){
      //   this.price = 50000
      // }
      // else{
      //   this.price = 1000
      // }
    }
  },

  mounted() {

  },
  computed: {
     ...mapGetters(["user","userBots"]),
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    deposited(){
      if (this.user?.deposits)
        return this.user?.deposits[this.robot.slug] 
      else return 0
    },
    minLimit() {
      var deposited = this.deposited
      if (deposited === null) {deposited = 0}
      var limit = this.robot.minimum   - deposited
      if (limit < 0) return 100
      return limit
    },

  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-dark";
    // Deactivate sidenav type buttons on resize and small screens
    this.sidebarColor('warning')
  },
};
</script>

