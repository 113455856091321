<template>
<div class="py-4 container-fluid">
  Вывод
  </div>
</template>

<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
