<template>
  
    
    <InvestmentsText v-if="text==='investments' && user.own === true" />
    <InvestText v-if="text==='invest' && user.own === true" />
    <RealInvest v-if="text==='investments' && user.own === false" />
    <RealInvest v-if="text==='invest' && user.own === false" />


    <FinanceText v-if="text==='finance' && user.own === true" />
    <RealFinance v-if="text==='finance' && user.own === false" />

    <InstructionText v-if="text==='instruction'  && user.own === true" />
    <RealInstruction v-if="text==='instruction'  && user.own === false" />
    
    <TasksText v-if="text==='tasks' && user.own === true" />

    <StatRef v-if="text==='statref'" />
    
    <TasksReal v-if="text==='tasks' && user.own === false" />
    
    <ProfileText v-if="text==='profile' && user.own === true" />
    <ProfileReal v-if="text==='profile' && user.own === false" />

    <TopupText v-if="text==='top-up' && user.own === true" />
    <TopupReal v-if="text==='top-up' && user.own === false" />


    <WithdrawText v-if="text==='withdraw' && user.own === true" />
    <WithdrawReal v-if="text==='withdraw' && user.own === false" />

    <HowitworksText v-if="text==='how-it-works'" />
    <HongKong v-if="text==='hong-kong'" />

    

    <OrderInProcess v-if="text==='order-in-process'" />
    
    <TechnicalsupportText v-if="text==='technical-support'" />

    <TasksReal v-if="text==='gotochatfromtask'" />
    
</template>

<style scoped>
.height-40{
  height:40px !important;
}
input {
  background-color: rgba(0, 0, 0, 0.05);
  color: #fff;
}
input:focus {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
</style>
<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import Telegram from "./components/Telegram.vue";

// import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import referal from "@/assets/img/reg.jpeg";


import StatRef from "./StatRef.vue";
import RealInvest from "./Invest.vue";
import RealFinance from "./Dashboard.vue";
import RealInstruction from "./Instruction.vue";
import TasksReal from "./Tasks.vue";
import ProfileReal from "./Profile.vue";
import TopupReal from "./TopUp.vue";
import WithdrawReal from "./Withdraw.vue";
import OrderInProcess from "./OrderInProcess.vue";


import InvestText from "./components/test/invest.vue";
import InvestmentsText from "./components/test/investments.vue"
import WithdrawText from "./components/test/withdraw.vue"
import FinanceText from "./components/test/finance.vue"
import InstructionText from "./components/test/instruction.vue"
import HongKong from "./components/test/hongkong.vue"
import TasksText from "./components/test/tasks.vue"
import ProfileText from "./components/test/profile.vue"
import HowitworksText from "./components/test/howitworks.vue"
import TechnicalsupportText from "./components/test/technicalsupport.vue"
import TopupText from "./components/test/topup.vue"

import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapActions } from "vuex";
import profile_photo from "@/assets/img/profile.webp";

export default {
  name: "ProfileOverview",
  components: {
    // ProfileInfoCard,
    InvestText,
    InvestmentsText,
    WithdrawText,
    FinanceText,
    InstructionText,
    StatRef,
    TasksText,
    ProfileText,
    HowitworksText,
    TechnicalsupportText,
    TopupText,
    RealInvest,
    RealFinance,
    RealInstruction,
    TasksReal,
    ProfileReal,
    HongKong,
    TopupReal,
    WithdrawReal,
    OrderInProcess
    // Telegram
    // DefaultProjectCard,
    // PlaceHolderCard,
  },

  props: {
    text: {
      type: String,
      default: "unkwonw page",
    },
  },

  data() {
    return {
      showMenu: false,
      loading: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      photo: profile_photo,
      referal: referal,
      old_password: "",
      // ref_link:'1234',
      new_password_1: "",
      new_password_2: "",
    };
  },
  computed:{
    ...mapGetters(["user"]),
    ref_link(){
      return `https://tmarket.me/sign-up?ref=${this.user.uid}`
    },
    info (){
      return {
            fullName: this.user.first_name,
            mobile: this.user.phone,
            email: this.user.email,
          }
    }
  },
  methods: {
    ...mapActions(["getUserData","updateUser","logout","restore", "change_password"]),
    send_new_password(){
      var data = {'email':this.user.email, 'no_redirect': true}
      this.restore({vm:this,data:data})
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');
    },
    openInstruction(){
      alert('Инструкция находится на модерации, дата официальной публикации 15 ноября 2022')
    },  
    change_password_callback(){
      this.loading = true
      var data = {
        email:this.user.email, 
        old_password: this.old_password,
        new_password_1: this.new_password_1,
        new_password_2: this.new_password_2,
      }
      this.change_password({vm:this,data:data})
    },
    change(type,event){
      console.log('change',event.target.checked,event.target.name)

      if (type==='checkbox') {
        var data = {}
        data[event.target.name] = event.target.checked
        console.log(data)
         this.updateUser({vm:this,data:data})
      }
     
      // else if (type==='profile') this.updateUser({
      //   first_name: this.user.first_name,
      //   phone: this.user.phone,
      // })
      // else if (type==='photo') this.updateUser({
      //   photo: this.photo
      // })

    }
  },
  watch:{
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
