<template>
  
  
  <div class="py-4 container-fluid">
    
    <div class="" style="">
      <div 
      style="width:90vw;"
        v-for="(item,index) in Filters"
        :key="item.slug"
      > 
          <input class="w-100 btn-check " type="radio" name="filter"  v-on:change="currFilter = item;check_index = index" :id="item.id" :value="item.slug" :disabled="false" :checked='index==0'>
          <label class="w-100 btn btn-round"  style="margin-bottom: 5px;" :class="{'btn-dark-green':index === check_index,'btn-outline-dark':index != check_index}" :for="item.id">{{item.name}} </label>
      </div> 
    </div>

    <div class="mt-3 row " style="justify-content:center"  v-if="filteredOrders.length===0">
      {{$t('ordered.no_tovar') }}
    </div>

    <div class="mt-3 row"  v-bind:key="order.id"   v-for="order in filteredOrders"  >
        <div class="card h-100 " >
          <div class="p-3 pb-0 card-header ">
            <h6 class="mb-0">{{$t('ordered.check') }}Чек #{{ order.id }}</h6>
            <!-- <a  class="mt-3"  style="color:red" @click="delete_good(order.id)">(X) удалить</a> -->
          </div>
          <div class="p-3 card-body m-1"  >
            <div class="row m-2" >
                <div class="col-3"> <img :src="order.img" height="80" style="border-radius:5px" alt=""></div>
                <div class="col-9">
                    <div class="row">
                       <text> {{order.description}} {{$t('ordered.na') }} </text>
                       <text>{{ order.price.toLocaleString('en') }}{{$t('ordered.valuta') }}</text>
                    </div>
                    <div class="row">
                          
                            <strong>{{$t('ordered.cashback') }} {{ order.cashback.toLocaleString('en') }}{{$t('ordered.valutas') }} </strong>
                            <br>
                          <small>  {{order.date}}</small>
                          <br>
                          <!-- <button  class="mt-3 btn btn-outline-dark"  @click="gotochat(order.id)">связаться с менеджером</button> -->
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
.btn-dark-green{
  background-color: #0C442D !important;
  background: #0C442D !important;
  color:#fff;
}
.btn-dark-green:hover{
  color:#fff;
}
.height-40{
  height:40px !important;
}
input {
  background-color:#0C442D !important;
  color: #fff;
}
input:focus {
  background-color: #0C442D !important;
  color: #fff;
}
</style>
<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import Telegram from "./components/Telegram.vue";

// import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import referal from "@/assets/img/reg.jpeg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapActions } from "vuex";
import profile_photo from "@/assets/img/profile.webp";

export default {
  name: "ProfileOverview",
  components: {
    // SoftSwitch,
    // ProfileInfoCard,
    // Telegram
    // DefaultProjectCard,
    // PlaceHolderCard,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      photo: profile_photo,
      referal: referal,
      orders: [
        {
            id: 1,
            name:'Название товара',
            description:'Описание товара',
            date:'01/01:22',
            img:'https://backend.l1st.online/media/image/temp_pG9udL5.jpg'
        },
      ],
      Filters:[
        {
        'id':'1',
        'slug':'all',
        'name':'🧾 Все'
        },{
        'id':'2',
        'slug':'payd',
        'name':'💰 Выплаченные на счет'
        },{
        'id':'4',
        'slug':'approved',
        'name':'♻️ Принято без выплаты'
        },{
        'id':'3',
        'slug':'rejected',
        'name':'❌ Отклоненные '
        }
      ],
      currFilter:{
        'id':'1',
        'slug':'all',
        'name':'Все'
        },
      old_password: "",
      // ref_link:'1234',
      new_password_1: "",
      new_password_2: "",
    };
  },
  computed:{
    ...mapGetters(["user","products"]),
    ref_link(){
      return `https://tmarket.me/sign-up?ref=${this.user.uid}`
    },
    info (){
      return {
            fullName: this.user.first_name,
            mobile: this.user.phone,
            email: this.user.email,
          }
    },
    filteredOrders(){
      return this.products.filter((item)=>{
        if(this.currFilter.slug  === 'all'){
          return true
        }
        if(this.currFilter.slug  === item.filter_cat){
          return true
        }
        else{
          return false
        }
       
      })
    
    }
  },
  methods: {
    ...mapActions(["getUserData","updateUser","logout","restore", "change_password","getProducts","deleteProduct"]),
    
    send_new_password(){
      var data = {'email':this.user.email, 'no_redirect': true}
      this.restore({vm:this,data:data})
    },
    gotochat(id){
      this.$router.push({query: {gotochat: id}})
      setTimeout(() => {
        this.$router.push({query: {}})
      }, 1000);
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');
    },
    openInstruction(){
      alert('Инструкция находится на модерации, дата официальной публикации 15 ноября 2022')
    },  
    change_password_callback(){
      this.loading = true
      var data = {
        email:this.user.email, 
        old_password: this.old_password,
        new_password_1: this.new_password_1,
        new_password_2: this.new_password_2,
      }
      this.change_password({vm:this,data:data})
    },
    delete_good(id){
      var result = confirm("Действительно хотите удалить товар?", 'Удалить', 'Отмена');
      if (result) {
        this.deleteProduct({vm:this,id:id})
        setTimeout(() => {
          this.getProducts()
        }, 1000);
      }

    },
    change(type,event){
      console.log('change',event.target.checked,event.target.name)

      if (type==='checkbox') {
        var data = {}
        data[event.target.name] = event.target.checked
        console.log(data)
         this.updateUser({vm:this,data:data})
      }
     
      // else if (type==='profile') this.updateUser({
      //   first_name: this.user.first_name,
      //   phone: this.user.phone,
      // })
      // else if (type==='photo') this.updateUser({
      //   photo: this.photo
      // })

    }
  },
  watch:{
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getProducts()
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
