<template>
  <div  v-if="youtube.length>0"> 
    <div  class="d-flex bg-dark align-items-center p-3 my-3 text-white-50 bg-dark  box-shadow" style="border-radius: 30px;">
      <iframe width="300" height="200" src="https://www.youtube.com/embed/sQ8A3FFXjGA?si=8sjNXgTlWQPqJtTo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <div class="lh-100 ms-5">
        <h1 class="mb-0 text-white lh-100">Telos Market </h1>
        <small>Key Information About the Project in One Video</small>
      </div>
    </div>

    <div class="my-3 p-3 bg-white rounded box-shadow ">
      <h3 class="border-bottom border-gray pb-2 mb-0 ">Recent Reports</h3>
      <div class="media text-muted pt-3 row border-bottom pb-3"  v-for="yo in youtube"  v-bind:key="yo.uid">
        <div class="col-md-4 col-lg-3 col-xs-12 col-sm-6">
          <iframe  v-if="!yo.is_text_report" class="w-100 myiframe "  :src="yo.link_emb" allowfullscreen></iframe>
          <div v-else> <img class="w-100 myiframe"  :src="yo.img" alt=""> </div>
        </div>
        
        <div class="col">
          <p class="media-body pb-3 mb-0 small lh-125  border-gray">
            <strong class="d-block text-gray-dark"> {{$t('youtube.opublikov') }} {{yo.ago}} {{$t('youtube.day_ago') }}</strong>
            
            <a style="text-decoration: underline; cursor:pointer" @click="open_link(yo.link)">{{yo.name}}</a>
          </p>
        </div>

        
      </div>
     
    </div>

   </div>
  <div class="mt-6 " style=" top:0; left:0; text-align: center;
    position: fixed; height: 100vh; width: 100vw; display: flex;
    height:60vh; justify-content: center; align-items: center; 
    flex-direction: column;" v-else>
    <loader></loader>
    <p style="opacity: 0.5; font-size:13px" class="pt-2">Loading...</p>
    
  </div>
    
</template>
<style> 

.myiframe{
  border-radius: 20px !important;

}

@media only screen and (max-width : 600px) {
  .scrolls .object {
    width: 80%;
  }
}

</style>
<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import loader from "../../views/components/Loader.vue";

// import Auto from "../../components/Icon/Auto.vue";
import { robots } from "../../robots.js";
import { mapGetters,mapActions,mapMutations  } from "vuex";
// import RoundSlider from 'vue-round-slider'

export default {
  name: "authors-table",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      robots: robots,
      rub_curr:false,
      deposit: 250,
      period: 190,
      check_index: 0,
      robot:{ "name": "Бонсай", "slug": "alpha", "revenue_from": 0.88, "revenue_to": 0.98, "minimum": 10, "maximum": 499, "checked": true },
    };
  },
  mounted() {
    // setTooltip();
    this.getYoutube();
  },
  watch:{
    
  },

  computed:{
     ...mapGetters(["youtube"]),
    
  },
  components: {
    // Auto
    loader,
  },
  methods:{
    ...mapMutations(["toggleConfigurator","toggleTopUpOwn"]),
    ...mapActions(["getBotsUser","getYoutube"]),
    getImgUrl(pic) {
        return require('../../assets/img/'+pic)
    },
    open_link(link){
      window.open(link,'_blank')
    }
  }
};
</script>
