<template>
  
  <div class="py-4 container-fluid">
    <!-- <div class="row  pb-0 pt-2" v-if="!user.telegram_connected">
      <telegram />
    </div> -->
    <div class="mt-3 row">
       <div class="mt-6 col-12 col-md-5 col-xl-5 mt-md-0">
        <div class="card-transparent h-100">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://uploads-ssl.webflow.com/5a9ee6416e90d20001b20038/62ee054258b87843c84aa25c_Rectangle%201%20(62).svg');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  <i
                  class="fa fa-shield me-2"
                  aria-hidden="true"
                ></i> Безопасность 
                </h5>
                <p class="text-white">
                <b>Пароль</b>
                </p>
 

                <div class="input-group mb-0" >
                  <input 
                    id="oldpassword"
                    type="password"
                    
                    v-model="old_password"
                    placeholder="старый пароль"
                    name="oldpassword"
                    class="form-control height-40" 
                    aria-label="старый пароль" />
                  <button class="btn btn-outline-secondary height-40"  style='padding-top:11px;' type="button" @click="send_new_password()">Сбросить</button>
                </div>
                <div class="input-group mb-2" >
                  <input 
                    id="new_password_1"
                    type="password"
                    v-model="new_password_1"
                    placeholder="новый пароль"
                    name="new_password_1"
                    class="form-control height-40" 
                    aria-label="новый пароль" />
                </div>
                <div class="input-group mb-2" >
                  <input 
                    id="new_password_2"
                    type="password"
                    v-model="new_password_2"
                    placeholder="новый пароль (еще раз)"
                    name="new_password_2"
                    class="form-control height-40" 
                    aria-label="новый пароль (еще раз)" />
                </div>
                  <button class="btn btn-outline-secondary height-40" 
                    :disabled="loading" 
                    @click="change_password_callback()"
                    style='padding-top:11px;' type="button" >
                    <template v-if="loading">
                      loading ...
                    </template>
                    <template v-else>
                      Сменить пароль
                    </template>
                    </button>
              

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 col-12 col-md-4 col-xl-4 mt-md-0">
        <profile-info-card
          title="Информация профиля"
          description=""
          :info="info"
          :social="[
            {
              link: 'https://www.facebook.com/CreativeTim/',
              icon: faFacebook,
            },
            {
              link: 'https://twitter.com/creativetim',
              icon: faTwitter,
            },
            {
              link: 'https://www.instagram.com/creativetimofficial/',
              icon: faInstagram,
            },
          ]"
          :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
        />
      </div>
      <div class="col-12 col-md-3 col-xl-3 ">
        <div class="card h-100 ">
          <div class="p-3 pb-0 card-header ">
            <h6 class="mb-0">Уведомления</h6>
          </div>
          <div class="p-3 card-body"  v-if='user.notiffications'>
            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              Финансы
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item ">
                <soft-switch
                  id="flexSwitchCheckDefault"
                  name="notiff_report_week"
                  class="ps-0 ms-auto "
                  label-class="mb-0  text-body ms-3 text-truncate w-70"
                  :checked='user.notiffications.week_report'
                  @change="change('checkbox',$event)"
                  >Еженедельный отчет</soft-switch
                >
              </li>
            </ul>
            <h6
              class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
            >
            
              Безопасность аккаунта
            </h6>
            <ul class="list-group">
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault3"
                  name="notiff_new_login"
                  :checked='user.notiffications.new_login'
                  @change="change('checkbox',$event)"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-70"
                  >Новый вход</soft-switch
                >
              </li>
              <li class="px-0 border-0 list-group-item">
                <soft-switch
                  id="flexSwitchCheckDefault4"
                  name="notiff_change_password"
                  @change="change('checkbox',$event)"
                  class="ps-0 ms-auto"
                  label-class="mb-0 text-body ms-3 text-truncate w-70"
                  :checked='user.notiffications.change_password'
                  >Смена пароля</soft-switch
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>

.height-40{
  height:40px !important;
}
input {
  background-color: rgba(0, 0, 0, 0.05);
  color: #fff;
}
input:focus {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
</style>
<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
// import Telegram from "./components/Telegram.vue";

import ProfileInfoCard from "../ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import referal from "@/assets/img/reg.jpeg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapActions } from "vuex";
import profile_photo from "@/assets/img/profile.webp";

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    // Telegram
    // DefaultProjectCard,
    // PlaceHolderCard,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      photo: profile_photo,
      referal: referal,
      old_password: "",
      // ref_link:'1234',
      new_password_1: "",
      new_password_2: "",
    };
  },
  computed:{
    ...mapGetters(["user"]),
    ref_link(){
      return `https://tmarket.me/sign-up?ref=${this.user.uid}`
    },
    info (){
      return {
            fullName: this.user.first_name,
            mobile: this.user.phone,
            email: this.user.email,
          }
    }
  },
  methods: {
    ...mapActions(["getUserData","updateUser","logout","restore", "change_password"]),
    send_new_password(){
      var data = {'email':this.user.email, 'no_redirect': true}
      this.restore({vm:this,data:data})
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');
    },
    openInstruction(){
      alert('Инструкция находится на модерации, дата официальной публикации 15 ноября 2022')
    },  
    change_password_callback(){
      this.loading = true
      var data = {
        email:this.user.email, 
        old_password: this.old_password,
        new_password_1: this.new_password_1,
        new_password_2: this.new_password_2,
      }
      this.change_password({vm:this,data:data})
    },
    change(type,event){
      console.log('change',event.target.checked,event.target.name)

      if (type==='checkbox') {
        var data = {}
        data[event.target.name] = event.target.checked
        console.log(data)
         this.updateUser({vm:this,data:data})
      }
     
      // else if (type==='profile') this.updateUser({
      //   first_name: this.user.first_name,
      //   phone: this.user.phone,
      // })
      // else if (type==='photo') this.updateUser({
      //   photo: this.photo
      // })

    }
  },
  watch:{
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
