<template>
  <navbar btn-background="bg-gradient-primary" />
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/hover.jpg') + ')',
    }"
  >
    <span class="mask opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-4">
          <img
            src="../assets/img/curved-images/gocash.png"
            class="w-90 mt-5"
            alt="Telos Market trade"
          />
        </div>
         
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n15git mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-8 col-lg-8 col-md-7">
        <calc-profit />
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import CalcProfit from "./components/CalcProfitGeneral";

import { mapMutations,mapActions } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    CalcProfit,
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
     vphone(phone) {
      if(phone.length<8){
        this.errors.phone = true
        return 1
      }
      else{
        this.errors.phone = false
        return 0
      }
     },
     vemail(email) {
      if(email.length<8){
        this.errors.email = true
        return 1
      }
      else{
        this.errors.email = false
        return 0
      }
     },
     vname(name) {
        if(name.length<2){
          this.errors.name = true
          return 1
        }
        else{
          this.errors.name = false
          return 0
        }
     },
     vpassword(pass1,pass2) {
        if(pass1.length<5){
          this.errors.password = true
          return 1
        }
        else{
          this.errors.password = false
        }

        if(pass1 != pass2){
          this.errors.repeat_password = true
          return 1
        }
        else{
          this.errors.repeat_password = false
        }
        return 0

      },
     getFormValues (submitEvent) {
      this.loading = true
      this.data = {
        'email':submitEvent.target.elements.email.value,
        'phone':submitEvent.target.elements.phone.value,
        'name':submitEvent.target.elements.name.value,
        'password':submitEvent.target.elements.password.value,
        'repeat_password':submitEvent.target.elements.repeat_password.value,
      }
      var error = 0
      error+=this.vphone(this.data.phone)
      error+=this.vemail(this.data.email)
      error+=this.vname(this.data.name)
      error+=this.vpassword(this.data.password,this.data.repeat_password)
      if (error>0){
        this.loading = false
        return 
      }
      this.signup({vm:this,data:this.data})
       .then(response => {
        console.log(response)
         this.loading = false
      })
      .catch(error => {
        console.log(error)
        this.loading = false
      })
      
    }

  },
};
</script>
