<template>
    <div class="h-100 mt-5 " style="">
      <div
        class="overflow-hidden bg-dark position-relative border-radius-lg  h-100"
        style="
        
        "
      >
        <!-- <span class="mask bg-gradient-dark"></span> -->
        <div class="card-body position-relative z-index-1 p-3 h-100">
          <div class="d-flex flex-column h-100">
            <p class="mb-1 pt-2 muted text-light mb-3">
                <i
                  class="fa fa-shield me-2"
                  aria-hidden="true"
                ></i>
                {{$t('telegram.telegram_app') }}</p>
            <!-- <h5 class="text-white font-weight-bolder mb-4 pt-2">
              Telegram
            </h5> -->
            <p class="text-white mb-4 small">
              {{$t('telegram.use_tg') }} <br> 
            </p>
            <div
              class="text-light font-weight-bold ps-2 pe-2 mb-0 icon-move-left mt-auto "
              style="width:fit-content; cursor:pointer; border: 1px solid #fff; padding:5px 5px; border-radius:10px;"
              @click="connect_telegram()"
            >

            {{$t('telegram.podkl') }}
              <i
                class="fas fa-arrow-right text-sm ms-1"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "TelegramCard",
  props: {
    todos: {
      type: Array,
      default: () => [],
    },
  },
  methods:{
    connect_telegram(){
      var token = localStorage.getItem('token')
      console.log(token,'tg')
      window.location.href = `tg://resolve?domain=telosmarketbot&start=${token}`  ;
    },
  },
  computed: {
    getLength() {
      return this.todos.length;
    },
  },
};
</script>
