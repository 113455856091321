<template>

  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-dark ">
                    {{$t('sign_in.welcome') }}
                  </h3>
                  <p class="mb-0">{{$t('sign_in.email_pass') }}</p>
                </div>

               
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="getFormValues"> 
                    <input
                      id="email"
                      type="text"
                      class="lav-sum-replenishment-input w-100"
                      :placeholder="$t('sign_in.email')"
                      name="email"
                    />
                    <br>
                    <input
                      id="password"
                      class="lav-sum-replenishment-input w-100"
                      type="password"
                      :placeholder="$t('sign_in.parol')"
                      name="password"
                    />
                    
                    <div class="mt-2" >
                      <soft-switch id="rememberMe" ame="rememberMe" checked>
                        {{$t('sign_in.remember') }}  
                      </soft-switch>


                    </div>

                    
                    <div class="text-center">
                      <button
                        class="my-3 mb-2 align-items-center justify-content-center d-flex btn-dark w-100"
                        style="height:50px; border-radius: 15px; cursor:pointer;"

                        :disabled="loading"
                        > 
                        <template v-if="loading">
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">{{$t('sign_in.loading') }}</span>
                            </div>
                        </template>

                        <template v-else>
                          {{$t('sign_in.vhod') }}
                        </template>
                      </button>
                    </div>
                  </form>
                </div>
                  
                <div class="px-1 ms-3 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-2 text-sm">
                    {{$t('sign_in.no_acc') }}
                    <router-link
                    style="color:orange; font-weight: 600;"
                      :to="{ name: 'Sign Up' }"
                      class=""
                      >{{$t('sign_in.registration') }}</router-link
                    >
                  </p>
                  <p class="mx-auto mb-4 text-sm">
                    {{$t('sign_in.dont_passw') }}
                    <router-link
                    style="color:orange; font-weight: 600;"
                      :to="{ name: 'Restore' }"
                      class=""
                      >{{$t('sign_in.restore') }}</router-link
                    >
                  </p>
                  <div class="p-3 text-start small" style="background-color: #e7e7e7; border-radius: 10px; color:#424242; opacity: 0.7;">
                    <text style="">
                      {{$t('sign_in.telegram_auth.0') }}


                      <a style="color:orange; font-weight: bold;" target="_blank" href="https://t.me/telosmarketbot?start=mypassword">
                        {{$t('sign_in.telegram_auth.1') }}
                      </a> 
                      
                      {{$t('sign_in.telegram_auth.2') }}
                    
                    </text>
                  </div>
              </div>

              </div>
              
            </div>
            
            <div class="col-md-6 c  col-xl-8 mx-auto col-lg-7" >
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none"
              >
                <div
                  class="bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n7"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/signin.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<style>

  @media screen and (min-width: 900px) {
    .card-plain{
      margin-top: 8rem!important;
    }
  }

.lav-sum-replenishment-input {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #e7e7e7;
  border-radius: 16px;
  padding: 10px 22px;
  font-size: 15px;
  line-height: 34px;
  color: #424242;
  border:0px;
}

.lav-sum-replenishment-input::-webkit-outer-spin-button,
.lav-sum-replenishment-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.lav-sum-replenishment-input,
.lav-sum-replenishment-input:hover,
.lav-sum-replenishment-input:focus {
  appearance: none;
  -moz-appearance: textfield;
  border-color:#000000 !important;
  box-shadow:0 0 0 0px #ffffff !important;
}



</style>
<script>


// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
// import { telegramLoginTemp } from 'vue3-telegram-login'
import SoftSwitch from "@/components/SoftSwitch.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapActions } from "vuex";
export default {
  name: "SignIn",
  components: {
    // Navbar,
    // AppFooter,
    SoftSwitch,

  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig","login"]),
    ...mapActions(["login","getUserData"]),
    yourCallbackFunction (user) {
   
      console.log(user)
    },

    getFormValues (submitEvent) {
      this.loading = true
      var self = this;
      setTimeout(function () { self.loading = false } , 1500)
      var data = {'email':submitEvent.target.elements.email.value,'password': submitEvent.target.elements.password.value}
      this.login({vm:this,data:data})
    }

  },
};
</script>
