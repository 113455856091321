<template>
  <div class="fixed-plugin " >

    <div class="  card " style="background:#000;overflow-y: scroll; overflow-x: hidden !important; ">
      <div class="pt-3 pb-0 bg-transparent card-header" style="position: absolute; right:10px">
        <div @click="toggle" class="mt-3 float-end "  >
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.6107 2.91675H11.3899C6.08158 2.91675 2.91699 6.08133 2.91699 11.3897V23.5959C2.91699 28.9188 6.08158 32.0834 11.3899 32.0834H23.5962C28.9045 32.0834 32.0691 28.9188 32.0691 23.6105V11.3897C32.0837 6.08133 28.9191 2.91675 23.6107 2.91675ZM22.4003 20.8542C22.8232 21.2772 22.8232 21.9772 22.4003 22.4001C22.1816 22.6188 21.9045 22.7209 21.6274 22.7209C21.3503 22.7209 21.0732 22.6188 20.8545 22.4001L17.5003 19.0459L14.1462 22.4001C13.9274 22.6188 13.6503 22.7209 13.3732 22.7209C13.0962 22.7209 12.8191 22.6188 12.6003 22.4001C12.1774 21.9772 12.1774 21.2772 12.6003 20.8542L15.9545 17.5001L12.6003 14.1459C12.1774 13.723 12.1774 13.023 12.6003 12.6001C13.0232 12.1772 13.7232 12.1772 14.1462 12.6001L17.5003 15.9542L20.8545 12.6001C21.2774 12.1772 21.9774 12.1772 22.4003 12.6001C22.8232 13.023 22.8232 13.723 22.4003 14.1459L19.0462 17.5001L22.4003 20.8542Z" fill="white"/>
              </svg>
        </div>
      </div>
       <iframe style="height:100vh;" src="https://calc.tmarket.me/"></iframe>
    </div>
  </div>
</template>

<script>
// import SoftRadio from "@/components/SoftRadio.vue";
// import SoftAlert from "@/components/SoftAlert.vue";
// import SoftInput from "@/components/SoftInput.vue";
import { mapMutations, mapActions,mapGetters } from "vuex";


export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      fixedKey: "",
      showPromoCode: false,
      loading: false,
      promocode: '',
      check_index: 0,
      price: 600,
      min: 600,
      robot:{name: "Бонсай",slug:"alpha", revenue_from: "0.88", revenue_to: "0.98", minimum:10,maximum:499,checked:true},
    };
  },
  components: {
    // SoftRadio,
    // SoftAlert,
    // SoftInput
  },

  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor","getUserData", "newPayment"]),

    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },
    sidebarType(type) {
      this.toggleSidebarColor(type);
    },
    toggle_promocode(){
      this.showPromoCode = !this.showPromoCode;
    },
    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
    topUp(){
      if (this.price < this.minLimit){
        this.$toast.error("Сумма должна превышать минимальный порог", { });
        return 1
      }
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 5000);
      // if(this.price < 1000) alert('Введите корректную сумму пополнения')
      var data = {
        robot: this.robot.slug,
        value: this.price,
        email: this.user.email,
        phone: this.user.phone,
        promocode: this.promocode, 
      }
      this.newPayment({vm:this,data:data})
      
    },

  },
  mounted() {

  },
  computed: {
     ...mapGetters(["user","userBots"]),
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    deposited(){
      if (this.user?.deposits)
        return this.user?.deposits[this.robot.slug] 
      else return 0
    },
    minLimit() {
      // var limit = this.robot.minimum * 60  - this.deposited
      // if (limit < 0) return 600
      return 90
    },

  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-dark";
    // Deactivate sidenav type buttons on resize and small screens
    this.sidebarColor('warning')
  },
};
</script>
