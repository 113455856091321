<template>

  
  <div  style="max-height:700px;" >
    <div class="spinner"></div>
  </div>
</template>
<style >

.spinner {
   position: relative;
   width: 56px;
   height: 56px;
}

.spinner::before,
.spinner::after {
   content: '';
   width: 100%;
   height: 100%;
   animation: spinner-rfi6tk 1s infinite linear;
   box-sizing: border-box;
   border: 11.2px solid rgba(0,0,0,0.1);
   border-radius: 50%;
   position: absolute;
}

.spinner::before {
   animation-direction: reverse;
   border-right-color: #000000;
   right: calc(50% - 5.6px);
}

.spinner::after {
   border-left-color: #000000;
   left: calc(50% - 5.6px);
}

@keyframes spinner-rfi6tk {
   0% {
      transform: rotate(0deg);
   }

   50%, 100% {
      transform: rotate(360deg);
   }
}
html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

body .dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}
body .dribbble img {
  display: block;
  height: 28px;
}
</style>
<script>

</script>