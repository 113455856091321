
<style>
  .instruction {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }

  .instruction__step {
    margin-top: 20px;
  }

  .instruction__step-number {
    font-weight: bold;
  }
</style>
<template>
<div class="py-4 container-fluid">
  <div class="instruction">
    <h3>Пошаговая инструкция:</h3>
    <p>Мгновенно продавайте свои старые вещи и получайте прибыль с помощью "Барахолки". <br>Чтобы продать свой товар, выполните следующие простые действия:</p>

    <div class="instruction__step">
      <span class="instruction__step-number">Первый шаг</span>
      <p>Выберите опцию «Продать товар» в меню или «Сканировать товар» на главном экране.</p>
    </div>

    <div class="instruction__step">
      <span class="instruction__step-number">Второй шаг</span>
      <p>Нажмите кнопку «Продолжить».</p>
    </div>

    <div class="instruction__step">
      <span class="instruction__step-number">Третий шаг</span>
      <p>Наведите камеру телефона на объект так, чтобы он был четко в кадре.</p>
    </div>

    <div class="instruction__step">
      <span class="instruction__step-number">Четвертый шаг</span>
      <p>Нажмите на значок камеры.</p>
    </div>

    <div class="instruction__step">
      <span class="instruction__step-number">Пятый шаг</span>
      <p>ИИ предложит вам цену, которую мы готовы заплатить за предмет.</p>
    </div>
    <div class="instruction__step">
      <span class="instruction__step-number">Шестой шаг</span>
      <p>Выберите опцию «Продать», если вы согласны с ценой, и деньги будут мгновенно зачислены на ваш счет. Или выберите «Отказаться», если вас не устраивает цена. В этом случае мы рекомендуем повторить процесс сканирования.</p>
    </div>
    </div>
  </div>
</template>

<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
