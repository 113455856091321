<template>
<div class="py-4 container-fluid">
  - info@l1st.online <br>
  - 880012239321 <br>
</div>
</template>

<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
