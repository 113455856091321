<template>
  <router-link 
    class="nav-link" 
    style="transition: none;"
     :to="to" v-bind="$attrs">
    <div
      class="text-center  icon-sm  d-flex align-items-center justify-content-center"
      :class="this.$store.state.isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }} </span
    >
    
  </router-link>
</template>

<style scoped> 
.nav-link-text{
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

</style>
<script>
export default {
  name: "sidenav-collapse",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
};
</script>
