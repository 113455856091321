<style>
  .investment-tracker {
    text-align: left;
    padding: 20px;
  }
</style>

<template>

<div class="py-4 container-fluid">
  <div class="investment-tracker">
  <h3>Отслеживайте свои инвестиции с легкостью</h3>
  <p>Наша платформа предоставляет вам исчерпывающую историю ваших инвестиций, включая:</p>
  <ul>
    <li>Сводка успешных транзакций</li>
    <li>Обновления статуса сделки</li>
    <li>Фактический и потенциальный полученный доход</li>
  </ul>
</div>
</div>

</template>

<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
