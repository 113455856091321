<template>
  <div class="py-4 container-fluid">

    
   
    

    <div class="row mt-4">
     
     <div class="col-lg-7 mb-2">
       <div class="card-transparent h-100">
         <div
           class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
           style="
             background-image: url('https://uploads-ssl.webflow.com/5a9ee6416e90d20001b20038/62ee054258b87843c84aa25c_Rectangle%201%20(62).svg');
           "
         >
           <span class="mask bg-gradient-dark"></span>
           <div class="card-body position-relative z-index-1 p-3 h-100">
             <div class="d-flex flex-column h-100">
               <p class="mb-1 pt-2 muted text-success">
                   <i
                     class="fa fa-shield me-2"
                     aria-hidden="true"
                   ></i>
                   безопасная сделка</p>
               <h5 class="text-white font-weight-bolder mb-4 pt-2">
                Telos Market  P2P
               </h5>
               <p class="text-white mb-4">
                 Пополнить депозит с комиссией от 0.9% до 1.8%, зачисление до 5 минут 
               </p>
               
               <a
                 class="text-light font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                 style="cursor:pointer"
                 @click="toggleTopUpOwn"
               >
                 Пополнить
                 <i
                   class="fas fa-arrow-right text-sm ms-1"
                   aria-hidden="true"
                 ></i>
               </a>
             </div>
           </div>
         </div>
       </div>
     </div>

     <div class="col-lg-5 mb-2">
       <div class="card-transparent h-100">
         <div
           class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
           style="
             background-image: url('https://uploads-ssl.webflow.com/5a9ee6416e90d20001b20038/62ee076edf1bb81c150bde2b_Rectangle%201%20(92).svg');
           "
         >
           <span class="mask bg-gradient-dark"></span>
           <div class="card-body position-relative z-index-1 p-3 h-100">
             <div class="d-flex flex-column h-100">
               <h5 class="text-white font-weight-bolder mb-4 pt-2">
                 Пополнить криптовалютой 
               </h5>
               <p class="text-white">
               <b>Ваш крипто Кошелек</b>
               </p>
               <p class="text-white mb-4">
                Моментальное пополнение криптовалютой без комиссии в любой крипто-сети
               </p>
               <a
                 class="text-warning font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                 style="cursor:pointer"
                 @click="toggleTopUpCrypto"
               >
                 Пополнить
                 <i
                   class="fas fa-arrow-right text-sm ms-1"
                   aria-hidden="true"
                 ></i>
               </a>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  </div>
</template>

<style scoped>
.bg-dark-outlined{
  border: 1px solid black;
  color:black
}
</style>

<script>
// import Telegram from "./components/Telegram.vue";
import setTooltip from "@/assets/js/tooltip.js";
// import RankingList from "@/examples/Cards/RankingList.vue";
// import RankingListCard from "@/examples/Cards/RankingListCard.vue";

// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../../../assets/img/icons/flags/US.png";
import DE from "../../../assets/img/icons/flags/DE.png";
import GB from "../../../assets/img/icons/flags/GB.png";
import BR from "../../../assets/img/icons/flags/BR.png";
import { mapMutations,mapGetters,mapActions  } from "vuex";

import {
  faHandPointer,
  faUsers,

  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      change: {
        in:{
          icon: 'fa-arrow-down',
          color: 'primary',
          sub: 'Депозит'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'danger',
          sub: 'Успешный вывод'
        },
        reinvest:{
          icon: '',
          color: 'dark',
          sub: 'Транзакция'
        },
        reinvest_in:{
          icon: 'fa-arrow-left',
          color: 'info',
          sub: 'Выведено (реинвест)'
        },
        reinvest_out:{
          icon: 'fa-arrow-right',
          color: 'info',
          sub: 'Депозит (реинвест)'
        }
      },
      req: {
        in:{
          icon: 'fa-arrow-down',
          color: 'info',
          sub: 'Депозит'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'info',
          sub: 'Заявка на вывод'
        },
        reinvest:{
          icon: 'fa-arrow-up',
          color: 'dark',
          sub: 'Реинвестирование'
        }
      },
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
    ...mapActions(["getUserData","getTransactions","getBotsUser"]),
    withdraw(id){
      this.toggleWithdraw(id)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    callSupport(){
      if(this.isMobile){
        this.$toast.info("Сейчас начнется вызов", { });
        location.href='tel:+78007074730';
      }
      else{
        this.$toast.info("Вы с компьютера или с планшета, наберите номер на мобильном", { });
      }

    },
  },
  computed:{
    ...mapGetters(["user","userPlot","userTransactions","userBots","userRequests"]),
    cards(){
      return {
            title: 'Транзации',
            item:this.transactions
          }
     },
     requests(){
      var tt = []
      this.userRequests.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }

          tt.push({
            title: element.portfolio.name,
            date: `${this.req[element.in_out].sub} от ${element.date}`,
            amount: `${t_amount} $`,
            icon:  this.req[element.in_out].icon,
            color: this.req[element.in_out].color,
        })
      });
      return tt

     },

     transactions(){
      var tt = []
      this.userTransactions.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }
          if (element.source === 'reinvest') element.in_out =  `reinvest_${element.in_out}`
         
         var sub =  'Транзакция'
         var icon =  ''
         var color = 'dark'

          if (this.change[element.in_out]) {
            sub = this.change[element.in_out].sub 
            icon = this.change[element.in_out].icon
            color = this.change[element.in_out].color
          }

          tt.push({
            title: element.portfolio.name,
            date: `${sub} от ${element.date}`,
            amount: `${t_amount} $`,
            icon:  icon,
            color: color,
        })
      });
      return tt

     }
  },
  components: {
    // MiniStatisticsCard,
    // ReportsBarChart,
    // Telegram,
    // GradientLineChart,
    // RankingList,
    // RankingListCard,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
   mounted() {
    setTooltip();
    this.getTransactions()
    this.getBotsUser()
  },
};
</script>
