<style>
  .text-container {
        font-family: "Inter", sans-serif;
        font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>
<template>

<div class="py-4 container-fluid">
  <div class="text-container">
  <h3>Команда:</h3>
  <p>
    Поделившись приложением с друзьями, вы можете получить дополнительные бонусы. Информация о размере и условиях участия будет доступна в ближайшее время. Следите за обновлениями!
  </p>
</div>
</div>
</template>


<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
