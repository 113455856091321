<template>
  <div class="container-fluid mt-4 ">
    <div class="row mt-4">
      <div class="col-12">
        <you-tube />
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-dark-outlined{
  border: 1px solid black;
  color:black
}
</style>
<script>
import setTooltip from "@/assets/js/tooltip.js";
// import SoftBadge from "@/components/SoftBadge.vue";
import YouTube from "./components/YouTube";

// import NavPill from "./components/NavPill.vue";
// import MasterCard from "@/examples/Cards/MasterCard.vue";
// import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
// import PaymentMethodsCard from "./components/PaymentMethodsCard.vue";
// import InvoiceCard from "./components/InvoiceCard.vue";
// import BillingCard from "./components/BillingCard.vue";
import { mapMutations,mapGetters,mapActions  } from "vuex";

export default {
  name: "Reports",
  components: {
    // NavPill,
    YouTube,
    // MasterCard,
    // DefaultInfoCard,
    // PaymentMethodsCard,
    // InvoiceCard,
    // BillingCard,
    // SoftBadge,
  },
 
  data() {
    return {
      change: {
        in:{
          icon: 'fa-arrow-down',
          color: 'primary',
          sub: 'Депозит'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'danger',
          sub: 'Успешный вывод'
        },
        reinvest:{
          icon: '',
          color: 'dark',
          sub: 'Транзакция'
        },
        reinvest_in:{
          icon: 'fa-arrow-left',
          color: 'info',
          sub: 'Withdrawn (реинвест)'
        },
        reinvest_out:{
          icon: 'fa-arrow-right',
          color: 'info',
          sub: 'Deposit (Reinvest)'
        }
      },
      req: {
        in:{
          icon: 'fa-arrow-down',
          color: 'info',
          sub: 'Deposit'
        },
        out:{
          icon: 'fa-arrow-up',
          color: 'info',
          sub: 'Withdrawn Request'
        },
        reinvest:{
          icon: 'fa-arrow-up',
          color: 'dark',
          sub: 'Reinvest'
        }
      }
      
    };
  },
  mounted() {
    setTooltip();
  },
  
  computed:{
     ...mapGetters(["userTransactions","userBots","userRequests"]),
     cards(){
      return {
            title: 'Транзации',
            item:this.transactions
          }
     },
     requests(){
      var tt = []
      this.userRequests.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }

          tt.push({
            title: element.portfolio.name,
            date: `${this.req[element.in_out].sub} от ${element.date}`,
            amount: `${t_amount} $`,
            icon:  this.req[element.in_out].icon,
            color: this.req[element.in_out].color,
        })
      });
      return tt

     },

     transactions(){
      var tt = []
      this.userTransactions.forEach(element => {
          var t_amount = '-'
          if (element.value){
            t_amount = element.value.toLocaleString('en')
          }
          if (element.source === 'reinvest') element.in_out =  `reinvest_${element.in_out}`
         
         var sub =  'Транзакция'
         var icon =  ''
         var color = 'dark'

          if (this.change[element.in_out]) {
            sub = this.change[element.in_out].sub 
            icon = this.change[element.in_out].icon
            color = this.change[element.in_out].color
          }

          tt.push({
            title: element.portfolio.name,
            date: `${sub} от ${element.date}`,
            amount: `${t_amount} $`,
            icon:  icon,
            color: color,
        })
      });
      return tt

     }
  },
  methods: {
    ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
    ...mapActions(["getTransactions","getBotsUser"]),
    withdraw(id){
      this.toggleWithdraw(id)
    },
  },
};
</script>
