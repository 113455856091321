<template>
<div class="py-4 container-fluid">
  Инвестиции:
Ваши вещи — это ваши инвестиции. 

Благодаря нашей обширной базе продуктов, полученной из нескольких источников, мы можем точно оценить стоимость ваших товаров всего за несколько секунд. Наш искусственный интеллект рассчитывает сумму, принимая во внимание нашу прибыль от продажи ваших товаров, поэтому мы уверенно переводим средства на ваш баланс еще до их получения.
</div>  
</template>

<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
