<style>
  .text-container {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>
<template>

<div class="py-4 container-fluid">
  <div class="text-container">
  <h3>Как это работает?</h3>
  <p>
    Наше приложение позволяет вам продавать свои товары быстро и легко. Просто сфотографируйте предмет, который вы хотите продать, и наш искусственный интеллект определит его стоимость на основе базы данных из 800 000 предметов. Платеж будет переведен непосредственно на ваш счет, и мы организуем доставку курьером.
  </p>
</div>
</div>
</template>

<script>
export default {
 name: "TextModule",
 
 props: {
  
 },
 methods: {
  
 },
};
</script>
