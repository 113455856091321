
<template>


   
  <div class="alex-container" style="margin-top: -24px;">

    

    <div class="alex-color__block">
       <p class="alex-color__block-label">Осталось мест:</p>
       <p class="alex-color__block-value"><span>20/</span>30</p>
    </div>
    <div class="alex-office__container">
       <div class="alex-office__block">
          <div class="alex-office__block-img">
             <img src="../../../assets/alex-image/android.svg" alt="">
          </div>
          <div class="alex-office__block_content">
             <div class="alex-office__block_content-title">---</div>
             <div class="alex-office__block_content-text">Экcклюзивный инвестиционный пул,
                в который можно попасть всего
                2 раза в год.</div>
          </div>
       </div>
       <div class="alex-office__info_blocks">
          <div class="alex-office__info_blocks-item">
             <div class="alex-office__info_blocks-item-title">Доходность</div>
             <div class="alex-office__info_blocks-item-value">30-3000%</div>
          </div>
          <div class="alex-office__info_blocks-item">
             <div class="alex-office__info_blocks-item-title">Риск</div>
             <div class="alex-office__info_blocks-item-value">Средний</div>
          </div>
          <div class="alex-office__info_blocks-item">
             <div class="alex-office__info_blocks-item-title">Срок</div>
             <div class="alex-office__info_blocks-item-value">4 месяца</div>
          </div>
       </div>
       <div class="alex-office__info">
          <div class="alex-office__info-description">Мы закупаем токены компаний
             (по закрытой информации гонконгского хэджфонда) которые только готовятся выйти на биржу.</div>
          <div class="alex-office__info-title">Это позволяет нам:</div>
          <div class="alex-office__info_item">
             <div class="alex-office__info_item-img">
                <img src="../../../assets/alex-image/arrow-down.svg" alt="">
             </div>
             <div class="alex-office__info_item-text">Получить цену ниже, чем будет
                в самом начале публикаций на биржах</div>
          </div>
          <div class="alex-office__info_item">
             <div class="alex-office__info_item-img">
                <img src="../../../assets/alex-image/wallet-money.svg" alt="">
             </div>
             <div class="alex-office__info_item-text">Делать большую прибыль в рамках 4-6 месяцев</div>
          </div>
          <div class="alex-office__info_item">
             <div class="alex-office__info_item-img">
                <img src="../../../assets/alex-image/security-safe.svg" alt="">
             </div>
             <div class="alex-office__info_item-text">Обеспечивать для вас минимальный риск, так как мы заходим в
                позиции
                80 на 20</div>
          </div>
          <div class="alex-office__info_item">
             <div class="alex-office__info_item-img">
                <img src="../../../assets/alex-image/document-upload.svg" alt="">
             </div>
             <div class="alex-office__info_item-text">Отправлять отчеты участникам пула стабильно раз в неделю,
                позволяя
                вам полностью контролировать ситуацию.</div>
          </div>
       </div> <!-- alex-office__info END -->
       <div class="alex__replenishment">
          <div class="alex__replenishment-text">Минимальная сумма пополнения</div>
          <div class="alex__replenishment-amount">100 000 <span>$</span>
          </div>
       </div>
       <div class="alex-btn-box">
          <div @click="iamin()" class="alex-btn-top-up pt-4 pb-4">
            <template v-if="loading">
              <div class="spinner-grow spinner-grow-sm me-2" role="status">
                <span class="visually-hidden">loading ...</span>
              </div>
              loading ...
            </template>
            <template v-else>
              Оставить заявку

            </template>
          </div>
          <div style=" "  v-if='showyoutube'>
            <iframe style="width: 100%; height:300px; border-radius:20px;" src="https://www.youtube.com/embed/lSUrCZKAU58?si=RnD-aBWhmpiNMLHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div @click="openyoutube()" class="alex-btn-see-results">
            <template  v-if='showyoutube'>
              закрыть
            </template>
            <template v-else>
              Смотреть видео отчет
            </template>
          </div>
       </div>
       


    </div> <!-- alex-office__container END -->

    <div class="alex-block-separator"></div>

    <div class="alex-office__container" style="display: none;">
       <div class="alex-office__block">
          <div class="alex-office__block-img">
             <img src="../../../assets/alex-image/android.svg" alt="">
          </div>
          <div class="alex-office__block_content">
             <div class="alex-office__block_content-title">Вайлдбериз и Озон</div>
             <div class="alex-office__block_content-text">Робот</div>
          </div>
       </div>
       <div class="alex-office__info_blocks">
          <div class="alex-office__info_blocks-item">
             <div class="alex-office__info_blocks-item-title">Доходность</div>
             <div class="alex-office__info_blocks-item-value">30-35% / МЕС</div>
          </div>
          <div class="alex-office__info_blocks-item">
             <div class="alex-office__info_blocks-item-title">Риск</div>
             <div class="alex-office__info_blocks-item-value">Низкий</div>
          </div>
          <div class="alex-office__info_blocks-item">
             <div class="alex-office__info_blocks-item-title">Срок</div>
             <div class="alex-office__info_blocks-item-value">3 месяца</div>
          </div>
       </div>
       <h2 class="alex-office__container-title">Инвестируем вместе с вами в товары и маркетинг уже действующих
          магазинов</h2>
       <div class="alex-office__info">
          <div class="alex-office__info-title">Условия:</div>
          <div class="alex-office__info_item">
             <div class="alex-office__info_item-img">
                <img src="../../../assets/alex-image/security-safe.svg" alt="">
             </div>
             <div class="alex-office__info_item-text">Инвестируя вы становитесь реальным совладельцев нескольких
                крупных магазинов на самых лучших маркетплейсах</div>
          </div>
          <div class="alex-office__info_item">
             <div class="alex-office__info_item-img">
                <img src="../../../assets/alex-image/document-upload.svg" alt="">
             </div>
             <div class="alex-office__info_item-text">Показываем результаты продаж каждый день</div>
          </div>
       </div> <!-- / alex-office__info END -->
       <div class="alex__replenishment">
          <div class="alex__replenishment-text">Минимальная сумма пополнения</div>
          <div class="alex__replenishment-amount">300 000 <span>$</span>
          </div>
       </div>
       <div class="alex-btn-box">
          <div class="alex-btn-top-up">Пополнить</div>
          <div class="alex-btn-see-results">Посмотреть результаты прошлого пула</div>
       </div>
    </div> <!-- / alex-office__container END -->
 </div>

</template>

<script>
import { mapActions  } from "vuex";
export default {
 name: "TextModule",
 data() {
    return {
      showyoutube:false,
      loading:false,
    }
  },
 props: {
  
 },
 methods: {
    ...mapActions(["hongkonk",]),
  iamin(){
    this.loading = true
    this.hongkonk({vm:this})
    setTimeout(() => {
      this.loading = false
    }, 1000);
  },
  openyoutube(){
    this.showyoutube = !this.showyoutube
  },
 },
};
</script>



<style scoped>

/* 400 */
@font-face {
  font-family: 'RFDewi-Regular';
  src: url('../../../assets/fonts/RFDewi-Regular.woff') format('woff')
}

/* 600 */
@font-face {
  font-family: 'RFDewi-Semibold';
  src: url('../../../assets/fonts/RFDewi-Semibold.woff') format('woff')
}

/* 700 */
@font-face {
  font-family: 'RFDewi-Bold';
  src: url('../../../assets/fonts/RFDewi-Bold.woff') format('woff')
}

.my-bg{
  background-color: #FEFEFE !important;
}
.alex-header {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'RFDewi-Semibold';
  font-size: 16px;
  padding: 22px 18px 22px 16px;
}

.alex-header svg {
  rotate: 180deg;
  position: absolute;
  left: 16px;
}

.alex-color__block {
  padding: 22px 29px 22px 30px;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  width: 100%;
  align-items: center;
  background: linear-gradient(270deg, #FF2749 -6.64%, #6039FB 141.62%);
}

.alex-color__block-label {
  color: #FFF;
  font-size: 20px;
  font-family: 'RFDewi-Regular';
  line-height: normal;
  letter-spacing: 0.2px;
}

.alex-color__block-value {
  color: rgba(255, 255, 255, 0.50);
  font-size: 30px;
  font-family: 'RFDewi-Semibold';
  line-height: normal;
  letter-spacing: 0.3px;
}

.alex-color__block-value span {
  font-family: 'RFDewi-Bold';
  color: #FFF;
}

.alex-office__container {
  margin-top: 35px;
  padding: 0 17px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.alex-office__block {
  display: flex;
  gap: 16px;
}

.alex-office__block_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 4px 0 0;
}

.alex-office__container-title {
  color: #151515;
  font-family: 'RFDewi-Bold';
  font-size: 24px;
  letter-spacing: -.3px;
}

.alex-office__block_content-title {
  color: #070707;
  font-family: 'RFDewi-Bold';
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
}

.alex-office__block_content-text {
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

.alex-office__info_blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 10px;
}

.alex-office__info_blocks-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 15px;
  background: #242121;
  padding: 10px 20px;
  flex: 1;
  white-space: nowrap;
}

.alex-office__info_blocks-item:first-child {
  width: 100%;
  flex-basis: auto;
  align-items: center;
}

.alex-office__info_blocks-item-title {
  color: #D8E3FC;
  font-size: 18px;
}

.alex-office__info_blocks-item-value {
  color: #FFF;
  font-size: 26px;
  font-family: 'RFDewi-Bold';
}

.alex-office__info {
  display: flex;
  flex-direction: column;
  padding: 30px 18px;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #BABDC5;
  background: #F6F6F8;
}

.alex-office__info-description {
  color: #373737;
  font-size: 18px;
}

.alex-office__info-title {
  color: #1B1B1B;
  font-size: 22px;
  font-family: 'RFDewi-Semibold';
  margin-top: 10px;
  margin-bottom: 15px;
}

.alex-office__info_item {
  display: flex;
  padding: 10px;
  gap: 15px;
  border-radius: 9px;
  background: #ECEEF3;
}

.alex-office__info_item-text {
  color: #373737;
  font-size: 16px;
}

.alex__replenishment {
  display: flex;
  justify-content: space-between;
  font-family: 'RFDewi-Semibold';
}

.alex__replenishment-text {
  color: #000;
  font-size: 18px;
  max-width: 220px;
}

.alex__replenishment-amount {
  font-size: 24px;
}

.alex__replenishment-amount span {
  font-family: 'RFDewi-Bold';
}

.alex-btn-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.alex-btn-top-up {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #000;
  color: #000;
  font-family: 'RFDewi-Semibold';
  font-size: 26px;
  background: transparent;
}

.alex-btn-see-results {
  display: flex;
  border-radius: 16px;
  border: 1px solid #BABDC5;
  background: #ECEEF3;
  padding: 17px 55px;
  align-items: center;
  color: #3A3A3A;
  font-family: 'RFDewi-Semibold';
  text-align: center;
  justify-content: center;
  font-size: 18px;
}

.alex-block-separator {
  width: 287px;
  height: 2px;
  background: rgba(0, 0, 0, 0.51);
  margin: 49px auto;
  border-radius: 1px;
}

/* replenishment */
.alex-replenishment {
  padding-top: 110px;
  padding-bottom: 70px;
  display: flex;
  position: relative;
  padding-left: 20px;
  max-width: 640px;
  margin: 0 auto;
}

.alex-replenishment-col {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.18);
  min-width: 20px;
  height: 100%;
}

.alex-replenishment-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alex__replenishment-title {
  color: #000;
  font-size: 26px;
  letter-spacing: 0.26px;
  font-family: 'RFDewi-Semibold';
}

.alex__replenishment-description {
  color: #AAA;
  font-size: 18px;
  letter-spacing: 0.18px;
  margin-top: 5px;
}

.alex__replenishment_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.alex__replenishment_btns-btn {
  display: flex;
  gap: 10px;
  color: #fff;
  font-family: 'RFDewi-Bold';
  padding: 15px 10px;
  border-radius: 7px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  height: max-content;
}

.alex-btn-black {
  background: #141414;
}

.alex-btn-blue {
  background: linear-gradient(97deg, #1750D1 2.83%, #27E2FC 108.6%);
  line-height: 15px;
}

.alex-btn-pink {
  background: linear-gradient(99deg, #8020D3 16.22%, #F75593 118.6%);
}

.alex-replenishment_available {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.alex-input__box {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #c3c3c3;
  background: #FFF;
  padding: 14px 14px 15px 14px;
}

.alex-input__box p {
  color: #000;
  font-size: 24px;
}

.alex-input__box input {
  width: 100%;
  padding-left: 30px;
  font-size: 20px;
  letter-spacing: 0.2px;
  margin-top: 4px;
  border:none;
}


.alex-input__box input::placeholder {
  color: #8f8f8f !important;
}
input:focus{
  border:none !important;
  box-shadow:none !important;
  border-color:none !important;
}

.alex-input__box input::-webkit-outer-spin-button,
.alex-input__box input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.alex-input__box input[type='number'] {
  -moz-appearance: textfield !important;
}

.alex-replenishment-available-text {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 14px;
  font-style: italic;
  line-height: normal;
}

.alex-replenishment__choice {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 18px;
  background: #F6F6F8;
  padding: 8px 7px;
  margin: 40px 0;
}

.alex-replenishment__choice-item {
  display: flex;
  color: #3A3A3A;
  padding: 20px 0;
  font-size: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-family: 'RFDewi-Semibold';
  letter-spacing: 0.14px;
  flex: 1;
  padding: 20px 10px;
  min-width: 140px;
  height: 56px;
}

.alex-choice-item-select {
  color: #FFF;
  background: #242121;
}

.alex-replenishment__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 35px;
}

.alex-replenishment__cards-item {
  width: 79px;
  height: 45px;
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  border: 1px solid #BABDC5;
  background: #F6F6F8;
  padding: 4px 3px;
  gap: 3px;
}

.alex-replenishment__cards-item img {
  width: 28px;
  height: 17px;
  object-fit: cover;
}

.alex-replenishment__cards-item p {
  color: #333;
  font-size: 12px;
  letter-spacing: 0.12px;
  width: 100%;
  text-align: right;
}

.alex-replenishment__cards-add {
  width: 79px;
  height: 45px;
  display: flex;
  border-radius: 11px;
  background: #F6F6F8;
  color: #161616;
  font-size: 32px;
  justify-content: center;
  align-items: center;
}

.alex-replenishment__cards .alex-input__box {
  margin-top: 10px;
}

.alex-replenishment__cards input {
  padding-left: 0;
}

.alex-checkbox-transform input {
  display: none;
}

.alex-checkbox__label:before {
  content: ' ';
  display: block;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: transparent;
}

.alex-checkbox__label:after {
  content: '';
  display: block;
  height: 23px;
  width: 23px;
  border-radius: 4px;
  transition: 200ms;
  position: absolute;
  top: 0px;
  left: 0px;
  /* background: #fff200; */
  transition: 100ms ease-in-out;
}

.alex-checkbox__input:checked~.alex-checkbox__label:after {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 11px;
  width: 15px;
}



.alex-checkbox__label {
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.16px;
  opacity: 0.5;
  margin-left: 10px;
}

.alex-replenishment__user-data {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.alex-replenishment__user-data input {
  padding-left: 0;
}

.alex-replenishment__commission {
  display: flex;
  justify-content: space-between;
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.16px;
}

/* replenishment END */
/* replenishment */
.alex-choice__wrapper {
  margin-top: 30px;
}

.alex-choice__wrapper-label {
  color: #AAA;
  font-size: 18px;
  letter-spacing: 0.18px;
}

.alex-choice__wrapper_items {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.alex-choice__wrapper_item {
  color: #000;
  font-family: 'RFDewi-Semibold';
  font-size: 18px;
  border-radius: 10px;
  background: #F6F6F8;
  padding: 17px 17px;
}

.alex-choice__item-select {
  color: #FFF;
  background: #242121;
}

.alex-promocode {
  margin: 35px 0;

}

.alex-promocode .alex-checkbox__label {
  font-style: italic;
}

.alex-choice__money_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 7px;
}

.alex-choice__money_item {
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #D3D4DA;
  background: #FFF;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 0.18px;
  flex: 1;
  white-space: nowrap;
}

.alex-choice__money_item:hover {
  color: #FFF;
  background: #242121;
  border: 1px solid transparent;
}

.alex-choice__money_wrapper .alex-input__box p {
  font-size: 20px;
}

.alex-choice__money_wrapper input {
  padding-left: 5px;
  margin-top: 0;
}

.attention__block {
  margin-top: 35px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.attention__block-up-row {
  background: #FAB23C;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attention__block p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background: #FFFEF5;
  color: #533D05;
  font-size: 12px;
  padding: 10px 20px 15px;
  letter-spacing: 0.12px;
  text-align: center;
}

.attention__block p span {
  font-weight: 700;
}

.alex-qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
}

.alex-qr-code_btn-copy {
  margin-top: 30px;
  width: 156px;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 32px;
  background: #0D0D0D;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.alex-qr-code p {
  margin-top: 15px;
  color: #4F4F4F;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20.333px;
  letter-spacing: 0.36px;
}

.alex-replenishment .alex-block-separator {
  width: 100%;
  margin: 25px 0;
}

.alex-replenishment-footnote {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.12px;
}

/* replenishment END  */
@media screen and (max-width: 360px) {
  .alex-office__info_blocks-item {
     align-items: center;
  }
}

</style>