<template>
  <footer class=" footer" style="height:200px !important;">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-4 text-center col-lg-8 " style='display:none' >
          <a
            href="javascript:;"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Company
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            About Us
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Team
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Products
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Blog
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="mb-2 text-secondary me-xl-5 me-3 mb-sm-0"
          >
            Pricing
          </a>
        </div>
        <div class="mx-auto mt-2 mb-4 text-center col-lg-8">
          <!-- <a
            href="https://t.me/gocashcrypto"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-telegram"></span>
          </a> -->
          <!-- <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-vk"></span>
          </a> -->
          <!-- <a
            href="https://www.youtube.com/channel/UCB4COElFmKgNtmryyENZfjw/videos"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-youtube"></span>
          </a> -->
          <!-- <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-pinterest"></span>
          </a>
          <a
            href="javascript:;"
            target="_blank"
            class="text-secondary me-xl-4 me-4"
          >
            <span class="text-lg fab fa-github"></span>
          </a> -->
        </div>
      </div>
      <!-- <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Все права защищены. © {{ new Date().getFullYear() }} Market
          </p>
        </div>
      </div> -->
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer"
};
</script>
