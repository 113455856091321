
<template>

<div class="mb-10" >
  <div @click="back" style="position:absolute; right:20px;top:20px;cursor:pointer;" >
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6107 2.91675H11.3899C6.08158 2.91675 2.91699 6.08133 2.91699 11.3897V23.5959C2.91699 28.9188 6.08158 32.0834 11.3899 32.0834H23.5962C28.9045 32.0834 32.0691 28.9188 32.0691 23.6105V11.3897C32.0837 6.08133 28.9191 2.91675 23.6107 2.91675ZM22.4003 20.8542C22.8232 21.2772 22.8232 21.9772 22.4003 22.4001C22.1816 22.6188 21.9045 22.7209 21.6274 22.7209C21.3503 22.7209 21.0732 22.6188 20.8545 22.4001L17.5003 19.0459L14.1462 22.4001C13.9274 22.6188 13.6503 22.7209 13.3732 22.7209C13.0962 22.7209 12.8191 22.6188 12.6003 22.4001C12.1774 21.9772 12.1774 21.2772 12.6003 20.8542L15.9545 17.5001L12.6003 14.1459C12.1774 13.723 12.1774 13.023 12.6003 12.6001C13.0232 12.1772 13.7232 12.1772 14.1462 12.6001L17.5003 15.9542L20.8545 12.6001C21.2774 12.1772 21.9774 12.1772 22.4003 12.6001C22.8232 13.023 22.8232 13.723 22.4003 14.1459L19.0462 17.5001L22.4003 20.8542Z" fill="white"/>
      </svg>
  </div>

  <loader  v-if="!userRef.structure" />
  <section v-else class="lav-container">
  <div class="lav-referral-id lav-mobile">#854888</div>
    <div class="lav-referral" style="">
       <div class="lav-referral__level m" style="max-width:100vw; ">
        <template 
            v-bind:key="i" v-for="i in userRef.level_available" 
            >
            <div @click="cur_level=i" class="cursor-pointer lav-referral__level_item " :class="cur_level==i ? 'lav-open' : '' ">
              <div class="lav-referral__level_item-number ">{{ i }}</div>
              <div class="lav-referral__level_item-name ">ур</div>
           </div>
          </template>
          
       </div>

       <template v-for="(data,level) in userRef.structure" v-bind:key="level">

        <div class="mt-4" v-if="level===cur_level">

       <h1 class="lav-referral-title">{{$t('statref.obem1') }}</h1>
       <div class="lav-referral-volume">{{userRef.revenue[level].toLocaleString('en') }} {{$t('statref.valuta1') }}</div>
       
       <div class="row text-center mt-3">
        <div class="lav-referral_enrollment-text text-me"> {{$t('statref.earned_obshii') }}  {{(userRef.total[level]) }}{{$t('statref.valuta') }} ({{userRef.percent[level]*100 }} % )</div>
        <div class="lav-referral_enrollment-text text-me">{{$t('statref.send_na_schet') }}  {{(userRef.paid[level])}} {{$t('statref.valuta') }} </div>
        <div class="lav-referral_enrollment-text text-me"> {{$t('statref.dohoddohod') }} +{{userRef.passive_income[level]*100 }} %</div>
     </div>

       <div class="lav-referral__composition mt-5">
          <div class="lav-referral__composition_item">
             <img class="lav-referral__composition_item-logo" src="../assets/img/frame.svg"
                alt="логотип с изображением человека">
             <div class="lav-referral__composition_item-text">{{data.length }} {{$t('statref.chel') }}</div>
          </div>
          <div class="lav-referral__composition_item">
             <img class="lav-referral__composition_item-logo" src="../assets/img/percentage-circle.svg"
                alt="логотип с изображением знака проценты">
             <div class="lav-referral__composition_item-text">{{userRef.percent[level]*100 }} %</div>
          </div>
          <div class="lav-referral__composition_item">
             <img class="lav-referral__composition_item-logo" src="../assets/img/add-circle.svg"
                alt="логотип с изображением знака плюс">
             <div class="lav-referral__composition_item-text">+{{userRef.passive_income[level]*100 }} %</div>
          </div>
       </div>
      
          <div class="mt-5"></div>
          <ref-item class="" :level="level" :item="item" v-for="(item) in data.sort(compareById)"  v-bind:key="item.id" />
        </div>
        
    
      </template>
      


 

    </div>
 </section>

  <div class="text-container">
  
  <template v-if="userRef.structure">
  <div v-if="!userRef.structure[cur_level]" class="text-center mt-8">
    <div class="row"> 
      <svg width="208" height="232" viewBox="0 0 208 232" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_6_5018)">
          <path d="M67.4905 81.5899L40.1672 192.673C39.016 197.345 39.3612 202.264 41.1535 206.726C42.9457 211.187 46.0927 214.961 50.1428 217.506C60.5653 224.046 77.7964 231.125 104.146 231.937C132.076 232.797 150.656 224.701 161.643 217.353C165.526 214.753 168.516 211.005 170.204 206.623C171.892 202.24 172.195 197.438 171.071 192.875L143.697 81.5899" fill="#F4F4F4"/>
          <path d="M105.593 86.7493C141.82 86.7493 171.187 76.7566 171.187 64.4299C171.187 52.1032 141.82 42.1105 105.593 42.1105C69.3672 42.1105 40.0001 52.1032 40.0001 64.4299C40.0001 76.7566 69.3672 86.7493 105.593 86.7493Z" fill="#757575"/>
          <path d="M105.594 87.1197C88.0338 87.1197 71.5202 84.7913 59.0947 80.5621C46.5446 76.2916 39.6328 70.5621 39.6328 64.4289C39.6328 58.2957 46.5439 52.5663 59.0947 48.2958C71.5202 44.0678 88.0338 41.7381 105.594 41.7381C123.153 41.7381 139.667 44.0669 152.092 48.2958C164.642 52.5663 171.554 58.2957 171.554 64.4289C171.554 70.5621 164.643 76.2916 152.092 80.5621C139.667 84.7913 123.153 87.1197 105.594 87.1197ZM105.594 42.4809C88.1115 42.4809 71.6813 44.7956 59.329 48.9986C47.1013 53.1593 40.367 58.6396 40.367 64.4299C40.367 70.2201 47.1013 75.7004 59.329 79.8612C71.6813 84.0642 88.1118 86.3789 105.594 86.3789C123.075 86.3789 139.506 84.0642 151.858 79.8612C164.086 75.7004 170.82 70.2201 170.82 64.4299C170.82 58.6396 164.086 53.1593 151.858 48.9986C139.506 44.7956 123.075 42.4809 105.594 42.4809Z" fill="#757575"/>
          <path d="M105.594 78.2467C153.19 78.2467 191.775 67.5362 191.775 54.324C191.775 41.1119 153.19 30.4013 105.594 30.4013C57.9971 30.4013 19.4125 41.1119 19.4125 54.324C19.4125 67.5362 57.9971 78.2467 105.594 78.2467Z" fill="black"/>
          <path d="M105.593 78.6171C82.5416 78.6171 60.8649 76.1239 44.5567 71.5969C28.1053 67.0302 19.045 60.8961 19.045 54.3231C19.045 47.75 28.1053 41.6169 44.5567 37.0492C60.8649 32.5222 82.5416 30.0293 105.593 30.0293C128.645 30.0293 150.322 32.5222 166.63 37.0492C183.081 41.6159 192.142 47.7504 192.142 54.3231C192.142 60.8958 183.081 67.0293 166.63 71.5969C150.322 76.1239 128.645 78.6171 105.593 78.6171ZM105.593 30.7718C82.6056 30.7718 60.998 33.2557 44.7514 37.7654C36.873 39.9522 30.6938 42.495 26.3848 45.3228C22.0016 48.1995 19.7792 51.2278 19.7792 54.324C19.7792 57.4202 22.0016 60.4489 26.3848 63.3253C30.6938 66.1531 36.873 68.6959 44.7514 70.8829C60.998 75.3933 82.6043 77.8763 105.593 77.8763C128.583 77.8763 150.189 75.3927 166.435 70.8829C174.314 68.6959 180.493 66.1531 184.802 63.3253C189.185 60.4489 191.407 57.4202 191.407 54.324C191.407 51.2278 189.185 48.1995 184.802 45.3228C180.493 42.495 174.314 39.9522 166.435 37.7654C150.189 33.2557 128.581 30.773 105.593 30.773V30.7718Z" fill="#757575"/>
          <path d="M145.247 40.3812C145.247 50.9569 127.081 53.4482 108.138 53.9008C87.4993 54.3938 65.9415 50.795 65.9415 40.3812C65.9711 29.7892 70.162 19.6413 77.5952 12.1622C85.0284 4.68317 95.0973 0.483246 105.595 0.483246C116.092 0.483246 126.161 4.68317 133.594 12.1622C141.027 19.6413 145.218 29.7892 145.248 40.3812H145.247Z" fill="white"/>
          <path d="M140.1 38.2875C140.005 38.2875 139.913 38.25 139.845 38.183C139.776 38.116 139.736 38.0248 139.733 37.9286C139.622 34.1323 138.878 30.382 137.534 26.8346C137.499 26.7429 137.502 26.6409 137.542 26.5512C137.581 26.4615 137.655 26.3913 137.746 26.3562C137.837 26.3211 137.938 26.3239 138.027 26.3639C138.115 26.4039 138.185 26.478 138.22 26.5697C139.593 30.1947 140.353 34.0268 140.467 37.9059C140.47 38.004 140.434 38.0994 140.368 38.1709C140.301 38.2425 140.209 38.2844 140.112 38.2875H140.1Z" fill="#757575"/>
          <path d="M134.617 20.625C134.556 20.6251 134.495 20.6098 134.442 20.5804C134.388 20.551 134.342 20.5085 134.309 20.4567C129.465 12.9951 121.925 7.73653 113.295 5.80082C113.202 5.77767 113.122 5.7189 113.071 5.63701C113.021 5.55513 113.004 5.45659 113.024 5.36235C113.045 5.2681 113.101 5.18559 113.18 5.13235C113.26 5.0791 113.357 5.05933 113.451 5.07724C122.269 7.05488 129.973 12.4279 134.924 20.0521C134.96 20.1079 134.98 20.1726 134.983 20.2392C134.986 20.3059 134.971 20.372 134.939 20.4307C134.908 20.4894 134.861 20.5384 134.804 20.5726C134.748 20.6068 134.683 20.6249 134.617 20.625Z" fill="#757575"/>
          <path d="M104.293 54.3167C86.7613 54.3167 73.4145 51.3417 68.1851 46.1916C66.4519 44.484 65.5735 42.5289 65.5735 40.3808C65.5586 35.0684 66.5829 29.8052 68.5874 24.8928C70.592 19.9804 73.5375 15.5154 77.2551 11.7536C80.9728 7.99183 85.3896 5.0072 90.2524 2.97075C95.1152 0.934291 100.328 -0.113968 105.593 -0.113968C110.858 -0.113968 116.072 0.934291 120.934 2.97075C125.797 5.0072 130.214 7.99183 133.932 11.7536C137.649 15.5154 140.595 19.9804 142.599 24.8928C144.604 29.8052 145.628 35.0684 145.613 40.3808C145.613 45.3695 141.672 49.0408 133.897 51.2928C127.915 53.0256 119.492 53.9997 108.146 54.2706C106.841 54.3013 105.557 54.3167 104.293 54.3167ZM105.593 0.739903C95.1769 0.751756 85.1912 4.93193 77.8262 12.3633C70.4611 19.7948 66.3183 29.8705 66.3064 40.3802C66.3064 42.3213 67.1107 44.0979 68.6971 45.6609C74.1633 51.0474 88.9025 53.9882 108.128 53.5301C123.905 53.1536 144.881 51.3801 144.881 40.3812C144.869 29.8713 140.726 19.7952 133.361 12.3638C125.996 4.93229 116.01 0.752293 105.594 0.740863L105.593 0.739903Z" fill="#757575"/>
          <path d="M41.425 57.5261C44.2688 57.5261 46.5742 55.1999 46.5742 52.3305C46.5742 49.4611 44.2688 47.1349 41.425 47.1349C38.5812 47.1349 36.2759 49.4611 36.2759 52.3305C36.2759 55.1999 38.5812 57.5261 41.425 57.5261Z" fill="white"/>
          <path d="M41.4251 57.8965C40.3341 57.8965 39.2676 57.5701 38.3605 56.9585C37.4533 56.3469 36.7463 55.4776 36.3288 54.4605C35.9113 53.4435 35.802 52.3243 36.0149 51.2446C36.2277 50.1649 36.7531 49.1732 37.5246 48.3947C38.296 47.6163 39.2789 47.0862 40.349 46.8714C41.419 46.6567 42.5282 46.7669 43.5361 47.1882C44.5441 47.6095 45.4056 48.3229 46.0117 49.2382C46.6179 50.1535 46.9414 51.2297 46.9414 52.3305C46.9397 53.8062 46.358 55.2209 45.3239 56.2644C44.2897 57.3079 42.8876 57.8948 41.4251 57.8965ZM41.4251 47.505C40.4793 47.505 39.5547 47.788 38.7682 48.3183C37.9818 48.8485 37.3688 49.6021 37.0068 50.4839C36.6449 51.3656 36.5502 52.3359 36.7347 53.2719C36.9192 54.208 37.3747 55.0678 38.0435 55.7426C38.7123 56.4175 39.5645 56.8771 40.4921 57.0633C41.4198 57.2495 42.3814 57.1539 43.2553 56.7887C44.1291 56.4234 44.876 55.8049 45.4015 55.0114C45.927 54.2179 46.2075 53.2849 46.2075 52.3305C46.2062 51.0511 45.702 49.8245 44.8054 48.9198C43.9088 48.0151 42.6931 47.5063 41.4251 47.505Z" fill="#757575"/>
          <path d="M105.594 72.4056C108.437 72.4056 110.743 70.0795 110.743 67.21C110.743 64.3406 108.437 62.0144 105.594 62.0144C102.75 62.0144 100.444 64.3406 100.444 67.21C100.444 70.0795 102.75 72.4056 105.594 72.4056Z" fill="white"/>
          <path d="M105.594 72.776C104.502 72.776 103.436 72.4496 102.529 71.838C101.622 71.2264 100.915 70.3571 100.497 69.34C100.08 68.323 99.9704 67.2038 100.183 66.1241C100.396 65.0444 100.921 64.0527 101.693 63.2742C102.464 62.4958 103.447 61.9657 104.517 61.7509C105.587 61.5362 106.697 61.6464 107.704 62.0677C108.712 62.489 109.574 63.2024 110.18 64.1177C110.786 65.033 111.11 66.1092 111.11 67.21C111.108 68.6857 110.526 70.1004 109.492 71.1439C108.458 72.1874 107.056 72.7743 105.594 72.776ZM105.594 62.3848C104.648 62.3848 103.723 62.6678 102.937 63.198C102.15 63.7282 101.537 64.4818 101.175 65.3635C100.814 66.2452 100.719 67.2154 100.903 68.1513C101.088 69.0873 101.543 69.9471 102.212 70.6219C102.881 71.2967 103.733 71.7563 104.661 71.9425C105.588 72.1286 106.55 72.0331 107.424 71.6679C108.297 71.3027 109.044 70.6842 109.57 69.8907C110.095 69.0972 110.376 68.1643 110.376 67.21C110.374 65.9307 109.87 64.7042 108.973 63.7996C108.077 62.895 106.861 62.3862 105.594 62.3848Z" fill="#757575"/>
          <path d="M169.751 57.5261C172.594 57.5261 174.9 55.1999 174.9 52.3305C174.9 49.4611 172.594 47.1349 169.751 47.1349C166.907 47.1349 164.601 49.4611 164.601 52.3305C164.601 55.1999 166.907 57.5261 169.751 57.5261Z" fill="white"/>
          <path d="M169.75 57.8965C168.659 57.8965 167.593 57.5701 166.686 56.9585C165.779 56.3469 165.072 55.4776 164.654 54.4605C164.237 53.4435 164.127 52.3243 164.34 51.2446C164.553 50.1649 165.078 49.1732 165.85 48.3947C166.621 47.6163 167.604 47.0862 168.674 46.8714C169.744 46.6567 170.853 46.7669 171.861 47.1882C172.869 47.6095 173.731 48.3229 174.337 49.2382C174.943 50.1535 175.267 51.2297 175.267 52.3305C175.265 53.8062 174.683 55.2209 173.649 56.2644C172.615 57.3079 171.213 57.8948 169.75 57.8965ZM169.75 47.505C168.805 47.505 167.88 47.788 167.094 48.3183C166.307 48.8485 165.694 49.6021 165.332 50.4839C164.97 51.3656 164.875 52.3359 165.06 53.2719C165.245 54.208 165.7 55.0678 166.369 55.7426C167.038 56.4175 167.89 56.8771 168.817 57.0633C169.745 57.2495 170.707 57.1539 171.581 56.7887C172.454 56.4234 173.201 55.8049 173.727 55.0114C174.252 54.2179 174.533 53.2849 174.533 52.3305C174.532 51.0511 174.027 49.8245 173.131 48.9198C172.234 48.0151 171.018 47.5063 169.75 47.505Z" fill="#757575"/>
          <path d="M142.386 67.21C145.23 67.21 147.535 64.8839 147.535 62.0144C147.535 59.145 145.23 56.8188 142.386 56.8188C139.542 56.8188 137.237 59.145 137.237 62.0144C137.237 64.8839 139.542 67.21 142.386 67.21Z" fill="white"/>
          <path d="M142.386 67.5804C141.295 67.5804 140.228 67.254 139.321 66.6424C138.414 66.0308 137.707 65.1615 137.289 64.1444C136.872 63.1274 136.763 62.0082 136.976 60.9285C137.188 59.8488 137.714 58.8571 138.485 58.0786C139.257 57.3002 140.24 56.7701 141.31 56.5553C142.38 56.3406 143.489 56.4508 144.497 56.8721C145.505 57.2934 146.366 58.0068 146.972 58.9221C147.579 59.8374 147.902 60.9136 147.902 62.0144C147.9 63.4901 147.319 64.9048 146.285 65.9483C145.25 66.9918 143.848 67.5787 142.386 67.5804ZM142.386 57.1889C141.44 57.1889 140.515 57.4718 139.729 58.002C138.942 58.5322 138.329 59.2858 137.967 60.1676C137.605 61.0493 137.511 62.0196 137.695 62.9556C137.879 63.8917 138.335 64.7515 139.004 65.4264C139.673 66.1013 140.525 66.5609 141.452 66.7472C142.38 66.9334 143.342 66.8378 144.216 66.4726C145.089 66.1074 145.836 65.4889 146.362 64.6953C146.887 63.9018 147.168 62.9688 147.168 62.0144C147.167 60.7351 146.662 59.5085 145.766 58.6038C144.869 57.6991 143.654 57.1903 142.386 57.1889Z" fill="#757575"/>
          <path d="M68.801 67.9173C71.6448 67.9173 73.9502 65.5911 73.9502 62.7217C73.9502 59.8522 71.6448 57.5261 68.801 57.5261C65.9572 57.5261 63.6519 59.8522 63.6519 62.7217C63.6519 65.5911 65.9572 67.9173 68.801 67.9173Z" fill="white"/>
          <path d="M68.8011 68.288C67.71 68.288 66.6435 67.9616 65.7364 67.35C64.8292 66.7384 64.1222 65.8691 63.7047 64.852C63.2872 63.835 63.1779 62.7158 63.3908 61.6361C63.6036 60.5564 64.129 59.5646 64.9005 58.7862C65.6719 58.0078 66.6548 57.4777 67.7249 57.2629C68.7949 57.0482 69.9041 57.1584 70.912 57.5797C71.92 58.0009 72.7815 58.7144 73.3877 59.6297C73.9938 60.545 74.3173 61.6211 74.3173 62.722C74.3156 64.1977 73.7339 65.6124 72.6998 66.6559C71.6656 67.6994 70.2635 68.2863 68.8011 68.288ZM68.8011 57.8965C67.8551 57.8962 66.9303 58.1789 66.1437 58.709C65.357 59.2391 64.7438 59.9926 64.3816 60.8743C64.0194 61.7561 63.9245 62.7264 64.1088 63.6625C64.2932 64.5987 64.7486 65.4587 65.4174 66.1337C66.0862 66.8087 66.9383 67.2684 67.8661 67.4547C68.7938 67.641 69.7555 67.5455 70.6294 67.1802C71.5034 66.815 72.2504 66.1965 72.7759 65.4028C73.3014 64.6092 73.5819 63.6761 73.5818 62.7217C73.5805 61.4426 73.0764 60.2163 72.1802 59.3117C71.2839 58.4072 70.0687 57.8982 68.8011 57.8965Z" fill="#757575"/>
          <path d="M160.193 35.8145C159.81 36.9872 159.025 37.9838 157.979 38.6254C156.933 39.2671 155.695 39.5119 154.486 39.3161C153.278 39.1202 152.178 38.4964 151.384 37.5566C150.59 36.6167 150.153 35.4222 150.153 34.1872C150.153 34.0711 150.158 33.9575 150.165 33.844C153.682 34.4367 157.032 35.0934 160.193 35.8145Z" fill="white"/>
          <path d="M155.302 39.7526C153.839 39.7511 152.437 39.1643 151.403 38.1207C150.369 37.0772 149.787 35.6623 149.785 34.1866C149.785 34.0624 149.791 33.9406 149.798 33.819L149.825 33.4105L150.225 33.478C153.761 34.074 157.142 34.7384 160.275 35.4523L160.669 35.5426L160.543 35.9299C160.18 37.0432 159.478 38.0124 158.537 38.6989C157.595 39.3855 156.463 39.7542 155.302 39.7526ZM150.521 34.2796C150.542 35.3834 150.938 36.4464 151.642 37.2915C152.346 38.1365 153.316 38.7125 154.389 38.9234C155.463 39.1342 156.577 38.9672 157.543 38.4502C158.51 37.9332 159.272 37.0974 159.701 36.0822C156.823 35.4344 153.739 34.8289 150.521 34.2796Z" fill="#757575"/>
          <path d="M60.4804 34.1872C60.4801 35.4118 60.0512 36.5969 59.2694 37.5335C58.4875 38.4701 57.403 39.0981 56.2071 39.3065C55.0111 39.515 53.7807 39.2906 52.7327 38.673C51.6848 38.0553 50.8867 37.0841 50.4792 35.9306C53.6285 35.2044 56.9665 34.5378 60.4731 33.9377C60.4779 34.0193 60.4804 34.1031 60.4804 34.1872Z" fill="white"/>
          <path d="M55.3318 39.7526C54.1935 39.7509 53.0833 39.395 52.1529 38.7332C51.2224 38.0715 50.5169 37.1362 50.1326 36.055L49.9938 35.6622L50.3967 35.5694C53.5616 34.8398 56.9309 34.168 60.4109 33.5727L60.8142 33.5036L60.8389 33.9153C60.8443 34.0042 60.8468 34.0957 60.8468 34.1872C60.8452 35.6626 60.2636 37.0771 59.2297 38.1204C58.1958 39.1637 56.7941 39.7507 55.3318 39.7526ZM50.9819 36.1951C51.4296 37.1817 52.1937 37.9873 53.1509 38.4819C54.1081 38.9764 55.2026 39.131 56.2576 38.9208C57.3126 38.7105 58.2666 38.1476 58.9656 37.323C59.6646 36.4984 60.0678 35.4602 60.1101 34.3759C56.9474 34.9242 53.8811 35.5352 50.9819 36.1951Z" fill="#757575"/>
          <path d="M111.074 138.052C112.695 139.236 114.103 140.624 115.299 142.219L115.454 142.325C115.525 142.447 115.567 142.557 115.586 142.643C116.04 143.228 116.448 143.815 116.778 144.466L117.475 145.599C117.727 146.08 117.935 146.798 118.09 147.139C118.248 147.475 118.429 147.834 118.543 148.178C122.969 147.932 127.331 149.143 130.966 151.669C132.624 152.804 134.121 154.173 135.338 155.782C142.907 165.594 139.212 178.694 130.009 186.054C125.142 189.704 119.505 192.216 113.565 193.356C105.172 194.826 96.6567 195.403 88.1244 195.081L87.1994 195.03L86.802 194.137C83.3079 186.265 80.655 178.044 78.8708 169.567C77.7444 163.59 77.9482 157.397 79.4778 151.464C82.7925 140.072 93.4768 131.757 105.448 135.24C106.388 135.497 107.298 135.85 108.155 136.287L108.471 136.502C109.282 136.9 110.049 137.351 110.785 137.854L111.074 138.052ZM128.877 162.434C128.05 161.324 126.475 161.099 125.335 161.939C124.222 162.798 123.983 164.41 124.823 165.559C126.081 167.347 126.088 169.787 124.847 171.635L124.792 171.717C124.336 172.288 124.125 172.996 124.191 173.698C124.257 174.399 124.633 175.038 125.174 175.49C126.271 176.198 127.775 175.958 128.601 174.899L128.812 174.584C131.378 170.934 131.399 166.052 128.877 162.434Z" fill="black"/>
          <path d="M3.74641 81.9603C3.69822 81.9603 3.6505 81.9507 3.60597 81.9321C3.56145 81.9135 3.521 81.8862 3.48694 81.8518C3.45287 81.8174 3.42586 81.7765 3.40744 81.7316C3.38903 81.6866 3.37957 81.6385 3.37961 81.5898V71.1395C3.37817 71.0899 3.38659 71.0406 3.40439 70.9944C3.42218 70.9482 3.44898 70.9061 3.4832 70.8705C3.51741 70.835 3.55835 70.8067 3.60359 70.7874C3.64883 70.7681 3.69745 70.7582 3.74657 70.7582C3.79569 70.7582 3.84431 70.7681 3.88955 70.7874C3.93479 70.8067 3.97573 70.835 4.00995 70.8705C4.04417 70.9061 4.07097 70.9482 4.08876 70.9944C4.10655 71.0406 4.11497 71.0899 4.11353 71.1395V81.5898C4.11357 81.6385 4.1041 81.6867 4.08567 81.7317C4.06724 81.7766 4.0402 81.8175 4.0061 81.8519C3.972 81.8863 3.93151 81.9136 3.88695 81.9322C3.84239 81.9508 3.79464 81.9603 3.74641 81.9603Z" fill="#262626"/>
          <path d="M7.12589 76.7353H0.367208C0.269843 76.7353 0.176465 76.6962 0.107617 76.6268C0.0387698 76.5573 9.15527e-05 76.4631 9.15527e-05 76.3648C9.15527e-05 76.2666 0.0387698 76.1724 0.107617 76.1029C0.176465 76.0334 0.269843 75.9944 0.367208 75.9944H7.12589C7.22326 75.9944 7.31663 76.0334 7.38548 76.1029C7.45433 76.1724 7.49301 76.2666 7.49301 76.3648C7.49301 76.4631 7.45433 76.5573 7.38548 76.6268C7.31663 76.6962 7.22326 76.7353 7.12589 76.7353Z" fill="#262626"/>
          <path d="M77.9304 121.563C77.8822 121.563 77.8345 121.553 77.7899 121.535C77.7454 121.516 77.705 121.489 77.6709 121.454C77.6368 121.42 77.6098 121.379 77.5914 121.334C77.573 121.289 77.5635 121.241 77.5636 121.192V110.742C77.5621 110.693 77.5706 110.643 77.5883 110.597C77.6061 110.551 77.6329 110.509 77.6672 110.473C77.7014 110.438 77.7423 110.409 77.7876 110.39C77.8328 110.371 77.8814 110.361 77.9305 110.361C77.9797 110.361 78.0283 110.371 78.0735 110.39C78.1187 110.409 78.1597 110.438 78.1939 110.473C78.2281 110.509 78.2549 110.551 78.2727 110.597C78.2905 110.643 78.2989 110.693 78.2975 110.742V121.192C78.2975 121.241 78.2881 121.289 78.2696 121.334C78.2512 121.379 78.2242 121.42 78.1901 121.454C78.156 121.489 78.1155 121.516 78.0709 121.535C78.0264 121.553 77.9786 121.563 77.9304 121.563Z" fill="#262626"/>
          <path d="M81.3099 116.338H74.5512C74.4538 116.338 74.3604 116.299 74.2916 116.229C74.2227 116.16 74.1841 116.066 74.1841 115.967C74.1841 115.869 74.2227 115.775 74.2916 115.705C74.3604 115.636 74.4538 115.597 74.5512 115.597H81.3099C81.4072 115.597 81.5006 115.636 81.5694 115.705C81.6383 115.775 81.677 115.869 81.677 115.967C81.677 116.066 81.6383 116.16 81.5694 116.229C81.5006 116.299 81.4072 116.338 81.3099 116.338Z" fill="#262626"/>
          <path d="M22.7678 224.246C22.7196 224.246 22.6719 224.237 22.6273 224.218C22.5828 224.199 22.5424 224.172 22.5083 224.138C22.4742 224.103 22.4472 224.063 22.4288 224.018C22.4104 223.973 22.4009 223.924 22.401 223.876V213.425C22.3995 213.376 22.408 213.327 22.4258 213.28C22.4436 213.234 22.4704 213.192 22.5046 213.157C22.5388 213.121 22.5797 213.093 22.625 213.073C22.6702 213.054 22.7188 213.044 22.7679 213.044C22.8171 213.044 22.8657 213.054 22.9109 213.073C22.9562 213.093 22.9971 213.121 23.0313 213.157C23.0655 213.192 23.0923 213.234 23.1101 213.28C23.1279 213.327 23.1364 213.376 23.1349 213.425V223.876C23.1349 223.925 23.1255 223.973 23.107 224.018C23.0886 224.063 23.0616 224.103 23.0275 224.138C22.9934 224.172 22.9529 224.2 22.9083 224.218C22.8638 224.237 22.816 224.246 22.7678 224.246Z" fill="#262626"/>
          <path d="M26.1473 219.021H19.3886C19.2912 219.021 19.1978 218.982 19.129 218.913C19.0601 218.843 19.0215 218.749 19.0215 218.651C19.0215 218.553 19.0601 218.458 19.129 218.389C19.1978 218.319 19.2912 218.28 19.3886 218.28H26.1473C26.2446 218.28 26.338 218.319 26.4069 218.389C26.4757 218.458 26.5144 218.553 26.5144 218.651C26.5144 218.749 26.4757 218.843 26.4069 218.913C26.338 218.982 26.2446 219.021 26.1473 219.021Z" fill="#262626"/>
          <path d="M180.039 140.165C179.942 140.165 179.849 140.125 179.78 140.056C179.711 139.987 179.672 139.892 179.672 139.794V123.898C179.672 123.799 179.711 123.705 179.78 123.636C179.849 123.566 179.942 123.527 180.039 123.527C180.137 123.527 180.23 123.566 180.299 123.636C180.368 123.705 180.406 123.799 180.406 123.898V139.794C180.406 139.892 180.368 139.986 180.299 140.056C180.23 140.125 180.137 140.164 180.039 140.165Z" fill="#262626"/>
          <path d="M185.181 132.216H174.9C174.802 132.216 174.709 132.177 174.64 132.108C174.571 132.038 174.533 131.944 174.533 131.846C174.533 131.748 174.571 131.653 174.64 131.584C174.709 131.514 174.802 131.475 174.9 131.475H185.181C185.278 131.475 185.372 131.514 185.44 131.584C185.509 131.653 185.548 131.748 185.548 131.846C185.548 131.944 185.509 132.038 185.44 132.108C185.372 132.177 185.278 132.216 185.181 132.216Z" fill="#262626"/>
          <path d="M52.1098 186.497C52.0124 186.497 51.919 186.458 51.8502 186.388C51.7813 186.319 51.7426 186.225 51.7426 186.126V170.23C51.7426 170.132 51.7813 170.038 51.8502 169.968C51.919 169.899 52.0124 169.86 52.1098 169.86C52.2071 169.86 52.3005 169.899 52.3693 169.968C52.4382 170.038 52.4769 170.132 52.4769 170.23V186.126C52.4768 186.225 52.4381 186.319 52.3693 186.388C52.3004 186.458 52.2071 186.497 52.1098 186.497Z" fill="#262626"/>
          <path d="M57.2512 178.549H46.9701C46.8727 178.549 46.7793 178.51 46.7105 178.44C46.6416 178.371 46.603 178.276 46.603 178.178C46.603 178.08 46.6416 177.986 46.7105 177.916C46.7793 177.847 46.8727 177.808 46.9701 177.808H57.2512C57.3486 177.808 57.442 177.847 57.5108 177.916C57.5797 177.986 57.6184 178.08 57.6184 178.178C57.6184 178.276 57.5797 178.371 57.5108 178.44C57.442 178.51 57.3486 178.549 57.2512 178.549Z" fill="#262626"/>
          <path d="M158.947 213.047C158.85 213.047 158.757 213.008 158.688 212.939C158.619 212.869 158.58 212.775 158.58 212.677V196.781C158.58 196.682 158.619 196.588 158.688 196.519C158.757 196.449 158.85 196.41 158.947 196.41C159.045 196.41 159.138 196.449 159.207 196.519C159.276 196.588 159.314 196.682 159.314 196.781V212.677C159.314 212.775 159.276 212.869 159.207 212.939C159.138 213.008 159.045 213.047 158.947 213.047Z" fill="#262626"/>
          <path d="M164.089 205.099H153.808C153.71 205.099 153.617 205.06 153.548 204.991C153.479 204.921 153.441 204.827 153.441 204.729C153.441 204.631 153.479 204.536 153.548 204.467C153.617 204.397 153.71 204.358 153.808 204.358H164.089C164.186 204.358 164.28 204.397 164.349 204.467C164.417 204.536 164.456 204.631 164.456 204.729C164.456 204.827 164.417 204.921 164.349 204.991C164.28 205.06 164.186 205.099 164.089 205.099Z" fill="#262626"/>
          <path d="M205.063 89.9085C205.014 89.9085 204.967 89.899 204.922 89.8804C204.878 89.8618 204.837 89.8345 204.803 89.8001C204.769 89.7657 204.742 89.7248 204.723 89.6799C204.705 89.6349 204.696 89.5867 204.696 89.5381V81.5899C204.696 81.4916 204.734 81.3974 204.803 81.3279C204.872 81.2584 204.965 81.2194 205.063 81.2194C205.16 81.2194 205.253 81.2584 205.322 81.3279C205.391 81.3974 205.43 81.4916 205.43 81.5899V89.5381C205.43 89.5867 205.42 89.6349 205.402 89.6799C205.384 89.7248 205.357 89.7657 205.322 89.8001C205.288 89.8345 205.248 89.8618 205.203 89.8804C205.159 89.899 205.111 89.9085 205.063 89.9085Z" fill="#262626"/>
          <path d="M207.633 85.9345H202.493C202.444 85.9345 202.397 85.925 202.352 85.9063C202.308 85.8877 202.267 85.8604 202.233 85.826C202.199 85.7916 202.172 85.7508 202.153 85.7059C202.135 85.6609 202.126 85.6128 202.126 85.5641C202.126 85.5155 202.135 85.4673 202.153 85.4224C202.172 85.3774 202.199 85.3366 202.233 85.3022C202.267 85.2678 202.308 85.2405 202.352 85.2219C202.397 85.2033 202.444 85.1937 202.493 85.1937H207.633C207.731 85.1937 207.824 85.2327 207.893 85.3022C207.962 85.3717 208 85.4659 208 85.5641C208 85.6624 207.962 85.7566 207.893 85.826C207.824 85.8955 207.731 85.9345 207.633 85.9345Z" fill="#262626"/>
          </g>
          <defs>
          <clipPath id="clip0_6_5018">
          <rect width="208" height="232" fill="white"/>
          </clipPath>
          </defs>
        </svg>
    </div>
    <div class="mt-4 text-center text-me " >
      <p style="max-width: 400px; margin: auto;">
        {{ $t('statref.empty_state.0') }} <strong>{{cur_level }}</strong>.  {{ $t('statref.empty_state.1') }} 
      </p>

    </div>
</div>
  </template>
</div>
</div>
</template>


<script>
import { mapGetters,mapActions } from "vuex";
import loader from "./components/Loader.vue";
import RefItem from "@/components/RefItem.vue";
export default {
 name: "StatRef",
data() {
  return {
    cur_level: "1",
    show:{
      1:false,
      2:false,
      3:false,
      4:false,
      5:false,
      6:false,
      7:false,
      9:false,
      10:false
    }
  }
},
 props: {
  
 },
 components: {
    loader,
    RefItem,
  },
 computed:{
  ...mapGetters(["userRef","user"]),

 },
 methods: {
  ...mapActions(["getUserData","getUserRefData","logout","restore", "change_password"]),
  back(){
    this.$router.go(-1)
  },


  compareById(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }
  
  
  

 },

  unmounted() {
      this.root.style.setProperty("--bs-body-bg", "#fff");
    },
  mounted() {
      this.getUserRefData()
      this.root = document.documentElement;
      this.root.style.setProperty("--bs-body-bg", "#0C0C0C");
    },


};
</script>


<style scoped>


/* @media (prefers-reduced-motion: no-preference) {
  html {
     scroll-behavior: smooth;
  }
} */

.lav-desktop {
  display: flex !important;
}

.lav-mobile {
  display: none !important;
}

body {
  background: #0C0C0C;
  font-family: "Inter", sans-serif;
  color: #fff;
  overflow-x: hidden;
  height: auto;
}

.lav-container {
  max-width: 1512px;
  margin: 0 auto;
}

/* lav-referral */

.lav-referral {
  margin: 0 auto;
  padding: 175px 133px 306px;
}

.lav-referral__level {
  display: flex;
  gap: 38px;
  justify-content: center;
}

.lav-referral__level_item {
  width: 168px;
  height: 168px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 10px;
  border-radius: 50%;
  background-color: #191919;
  align-items: center;
  justify-content: center;
}

.lav-open.lav-referral__level_item {
  background-color: #fab23c;
  padding-top: 49px;
  justify-content: flex-start;
  justify-content: center;
}

.lav-referral__level_item-number,
.lav-referral__level_item-name {
  color:#fff;

}

.lav-referral__level_item-lock {
  width: 78px;
  height: 78px;
}

.lav-open .lav-referral__level_item-number,
.lav-open .lav-referral__level_item-name {
  display: block;
  font-size: 36px;
  color:#000;
  line-height: 35px;
  font-family: "Inter", sans-serif;
}

.lav-open .lav-referral__level_item-lock {
  display: none;
}
.text-me{
  color: #7D7D7D;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13px;
}
.lav-referral-title {
  margin-top: 142px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 47px;
  line-height: 65px;
  text-align: center;
  color: #7D7D7D;
}

.lav-referral-volume {
  margin-top: 30px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 100px;
  line-height: 130px;
  text-align: center;
  color: #FFFFFF;
}

.lav-referral__composition {
  margin: 0 auto;
  margin-top: 47px;
  width: 468px;
  height: 175px;
  display: flex;
  justify-content: space-between;
  background: #1F1F1F;
  border-radius: 26px;
  padding: 26px 37px 43px;
}

.lav-referral__composition_item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

}

.lav-referral__composition_item-logo {
  width: 65px;
  height: 65px;

}

.lav-referral__composition_item-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 21px;
  color: #FFFFFF;
}

.lav-referral__row {
  margin-top: 125px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.lav-referral-link {
  width: 460px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  line-height: 35px;
  border-radius: 30px;
  padding: 45px 63px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #fff;
  background: radial-gradient(50% 134.49% at 50% 50%, #557AD3 0%, #05379B 100%);
  gap: 42px;
}

.lav-referral_enrollment {
  position: relative;
  width: 762px;
  height: 120px;
  padding: 0 40px 0 44px;
  border-radius: 30px;
  background-color: #0C0C0C;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 29px;
  line-height: 35px;
  color: #FFFFFF;
}

.lav-referral_enrollment::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.9023984593837535) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.9023984593837535) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.9023984593837535) 0%, rgba(0, 0, 0, 1) 100%);
  border-radius: 30px;
  z-index: -1;
}

.lav-referral-statistics {
  margin-top: 24px;
  width: 460px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;
  line-height: 35px;
  border-radius: 30px;
  padding: 45px 63px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #fff;
  background: transparent;
  gap: 72px;
}

/* lav-referral END*/

/* lav-replenish */
.lav-replenish {
  padding: 78px 20px 70px;
  overflow: hidden;
}

.lav-card,
.lav-crypto {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lav-card-circle {
  position: absolute;
  width: 1528px;
  height: 1528px;
  top: -850px;
  background: radial-gradient(50% 50% at 50% 50%, #035DE5 0%, #05165E 100%);
  filter: blur(49.45px);
  z-index: -1;
  border-radius: 50%;
}

.lav-card_logs {
  display: flex;
  gap: 84px;
}

.lav-replenish-title {
  margin-top: 78px;
  font-family: "Inter", sans-serif;
  font-size: 70px;
  line-height: 91px;
  text-align: center;
  color: #FFFFFF;
}

.lav-crypto .lav-replenish-title {
  margin-top: 430px;
}

.lav-replenish-text {
  width: 568px;
  font-family: "Inter", sans-serif;
  margin-top: 25px;
  font-size: 32px;
  line-height: 41.6px;
  text-align: center;
  color: #E4E4E4;
}

.lav-card-btn,
.lav-crypto-btn {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 412px;
  height: 100px;
  background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #1A93CD 100%);
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 41.6px;
  color: #080808;
}

.lav-crypto {
  justify-content: flex-end;
}

.lav-crypto-circle {
  position: absolute;
  width: 1528px;
  height: 1528px;
  bottom: -850px;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%);
  filter: blur(49.45px);
  border-radius: 50%;
}

/* lav-replenish END */

@media screen and (width > 768px) and (width < 1520px) {
  /* lav-referral */

  .lav-referral {
     padding: 11.45vw 8.7vw 20.01vw;
  }

  .lav-referral__level {
     gap: 1.1vw;
  }

  .lav-referral__level_item {
     width: 10.99vw;
     height: 10.99vw;
     gap: 10px;
  }

  .lav-open.lav-referral__level_item {
     padding-top: 1vw;
  }

  .lav-referral__level_item-lock {
     width: 5.1vw;
     height: 5.1vw;
  }

  .lav-open .lav-referral__level_item-number,
  .lav-open .lav-referral__level_item-name {
     font-size: 2.35vw;
     line-height: 2.29vw;
  }


  .lav-referral-title {
     margin-top: 9.29vw;
     font-size: 3.07vw;
     line-height: 4.25vw;
  }

  .lav-referral-volume {
     margin-top: 1.96vw;
     font-size: 6.54vw;
     line-height: 8.5vw;
  }

  .lav-referral__composition {
     margin-top: 3.07vw;
     width: 30.61vw;
     height: 11.45vw;
     border-radius: 1.7vw;
     padding: 1.7vw 2.42vw 2.81vw;
  }

  .lav-referral__composition_item {
     gap: 1.31vw;
  }

  .lav-referral__composition_item-logo {
     width: 4.25vw;
     height: 4.25vw;

  }

  .lav-referral__composition_item-text {
     font-size: 1.9vw;
     line-height: 1.37vw;
  }

  .lav-referral__row {
     margin-top: 8.18vw;
     gap: 1.31vw;
  }

  .lav-referral-link {
     width: 30.09vw;
     font-size: 2.09vw;
     line-height: 2.29vw;
     border-radius: 1.31vw;
     padding: 2.94vw 4.12vw;
     gap: 2.75vw;
  }

  .lav-referral_enrollment {
     width: 49vw;
     height: 9vw;
     padding: 0 2.62vw 0 2.88vw;
     border-radius: 1.31vw;
     font-size: 1.9vw;
     line-height: 2.29vw;
  }

  .lav-referral_enrollment::before {
     top: -.13vw;
     left: -.13vw;
     width: calc(100% + .26vw);
     height: calc(100% + .26vw);
     border-radius: 1.31vw;
  }

  .lav-referral-statistics {
     margin-top: 1.57vw;
     width: 30.09vw;
     font-size: 2.09vw;
     line-height: 2.29vw;
     border-radius: 1.31vw;
     padding: 2.94vw 4.12vw;
     gap: 4.71vw;
  }

  /* lav-referral END*/

  /* lav-replenish */
  .lav-replenish {
     padding: 5.1vw 1.31vw 4.58vw;
     overflow: hidden;
  }

  .lav-card,
  .lav-crypto {
     height: 50%;
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
  }

  .lav-card-circle {
     position: absolute;
     width: 99.93vw;
     height: 99.93vw;
     top: -55.59vw;
     background: radial-gradient(50% 50% at 50% 50%, #035DE5 0%, #05165E 100%);
     filter: blur(3.23vw);
     z-index: -1;
     border-radius: 50%;
  }

  .lav-card_logs {
     display: flex;
     gap: 5.49vw;
  }

  .lav-replenish-title {
     margin-top: 5.1vw;
     font-family: "Inter", sans-serif;
     font-size: 4.58vw;
     line-height: 5.95vw;
     text-align: center;
     color: #FFFFFF;
  }

  .lav-crypto .lav-replenish-title {
     margin-top: 28.12vw;
  }

  .lav-replenish-text {
     width: 37.15vw;
     font-family: "Inter", sans-serif;
     margin-top: 1.64vw;
     font-size: 2.09vw;
     line-height: 2.72vw;
     text-align: center;
     color: #E4E4E4;
  }

  .lav-card_logs-item {
     width: 12vw;
  }

  .lav-card-btn,
  .lav-crypto-btn {
     margin-top: 4.58vw;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 26.95vw;
     height: 6.54vw;
     background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #1A93CD 100%);
     border-radius: 1.05vw;
     font-family: "Inter", sans-serif;
     font-size: 2.09vw;
     line-height: 2.72vw;
     color: #080808;
  }

  .lav-crypto {
     justify-content: flex-end;
  }

  .lav-crypto-circle {
     position: absolute;
     width: 99.93vw;
     height: 99.93vw;
     bottom: -55.59vw;
     z-index: -1;
     background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%);
     filter: blur(3.23vw);
     border-radius: 50%;
  }

  /* lav-replenish END */

}

@media screen and (width < 768px) {
  body {
     background: #000;
  }

  .lav-desktop {
     display: none !important;
  }

  .lav-mobile {
     display: flex !important;
  }

  .lav-referral {
     padding: 0 19px;
  }

  .lav-referral-id {
     display: flex;
     justify-content: center;
     background: #131313;
     padding: 28px 0;
     color: #a4a4a4;
  }

  .lav-referral__level {
     gap: 5px;
  }

  .lav-referral__level_item {
     width: 66px;
     height: 66px;
     margin-top: 36px;
     gap: 0;
  }

  .lav-open.lav-referral__level_item {
     padding-top: 0;
     justify-content: center;
  }

  .lav-open .lav-referral__level_item-number,
  .lav-open .lav-referral__level_item-name {
     font-size: 22px;
     line-height: 1;
  }

  .lav-referral__level_item-lock {
     width: 30px;
     height: 30px;
  }

  .lav-referral-title {
     margin-top: 78px;
     font-size: 16px;
     line-height: 21px;
  }

  .lav-referral-volume {
     margin-top: 10px;
     font-size: 36px;
     line-height: 1;
  }

  .lav-referral__composition {
     padding: 11px 17px;
     height: 77px;
     margin: 0 auto;
     margin-top: 70px;
     border-radius: 15px;
     width: fit-content;
     gap: 43px;
  }

  .lav-referral__composition_item {
     gap: 5px;
  }

  .lav-referral__composition_item-logo {
     width: 30px;
     height: 30px;
  }

  .lav-referral__composition_item-text {
     font-size: 16px;
  }

  .lav-referral__row {
     margin-top: 35px;
     gap: 34px;
  }

  .lav-referral-link {
     width: 100%;
     font-size: 22px;
     padding: 21px 22px 21px 25px;
     justify-content: space-between;
     border-radius: 15px;
  }

  .lav-referral-link svg,
  .lav-referral-statistics svg {
     width: 41px;
  }

  .lav-referral-statistics,
  .lav-referral_enrollment {
     text-transform: uppercase;
     width: 100%;
     height: auto;
     font-size: 14px;
     padding: 0px 22px 0px 25px;
     justify-content: space-between;
     border-radius: 15px;
     margin-top: 0;
  }

  .lav-referral_enrollment {
     order: 2;
     text-transform: uppercase;
  }

  .lav-referral_enrollment::before {
     display: none;
  }

  /* lav-replenish */
  .lav-replenish {
     /* height: 100dvh; */
     padding: 40px 20px 50px;
     overflow: hidden;
  }

  .lav-card,
  .lav-crypto {
     height: 50%;
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
  }

  .lav-card-circle {
     position: absolute;
     width: 100vh;
     height: 100vh;
     top: -64vh;
     background: radial-gradient(50% 50% at 50% 50%, #035DE5 0%, #05165E 100%);
     filter: blur(49.45px);
     z-index: -1;
     border-radius: 50%;
  }

  .lav-card_logs {
     display: flex;
     gap: 23px;
  }

  .lav-replenish-title {
     margin-top: 40px;
     font-family: "Inter", sans-serif;
     font-size: 34px;
     line-height: 35px;
     text-align: center;
     color: #FFFFFF;
  }

  .lav-crypto .lav-replenish-title {
     margin-top: 171px;
  }

  .lav-replenish-text {
     width: auto;
     font-family: "Inter", sans-serif;
     margin-top: 25px;
     font-size: 16px;
     line-height: 21px;
     text-align: center;
     color: #E4E4E4;
  }

  .lav-card-btn,
  .lav-crypto-btn {
     margin-top: 40px;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 270px;
     height: 65px;
     background: radial-gradient(102.83% 299.6% at 50% 50%, #FFFFFF 0%, #1A93CD 100%);
     border-radius: 16px;
     font-family: "Inter", sans-serif;
     font-size: 20px;
     line-height: 26px;
     color: #080808;
  }

  .lav-crypto {
     justify-content: flex-end;
  }

  .lav-crypto-circle {
     position: absolute;
     width: 100vh;
     height: 100vh;
     bottom: -64vh;
     z-index: -1;
     background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%);
     filter: blur(49.45px);
     border-radius: 50%;
  }

  /* lav-replenish END */

}

/* lav-referrals */
.lav-referrals {
  display: flex;
  flex-direction: column;
  padding: 60px 12px;
}

.lav-referrals-title {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
}

.lav-referrals-wrapper {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.lav-referrals__item {
  position: relative;
  width: 100%;
  padding: 12px 0;
  background: #101010;
  box-shadow: 0px 4px 15.5px rgba(0, 0, 0, 0.13);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.lav-referrals__item.lav-open {
  background: radial-gradient(50% 58.88% at 50% 50%, #FFD438 0%, #ED8F03 100%);
}

.lav-referrals__item_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: "Inter", sans-serif;
}

.lav-referrals__item_main-user {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.lav-referrals__item_main-user-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  flex: 1;
}

.lav-row {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

.lav-referrals__item_main-data {
  display: flex;
  gap: 15px;
  font-size: 14px;
  line-height: 17px;
}

.lav-referrals__item_main-title {
  font-weight: 400;
  color: #4B4B4B;
}

.lav-referrals__item_main-user svg {
  transition: .3s;
}

.lav-open svg {
  rotate: 180deg;
}

.lav-open .lav-referrals__item_main-title {
  color: #FFFFFF;
}

.lav-referrals__item_main-sum {
  font-weight: 500;
  color: #FFFFFF;
}

.lav-referrals__item_additionally {
  display: flex;
  max-height: 0;
  overflow: hidden;
  flex-direction: column;
  gap: 13px;
  position: relative;
  transition: .3s;
  padding-left: 12px;
  padding-right: 12px;
}

.lav-open .lav-referrals__item_additionally {
  padding-top: 18px;
  max-height: 500px;
}

.lav-open .lav-referrals__item_additionally::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 1px;
  background-color: #CACACA;
  ;
}

.lav-referrals__item_additionally-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #4F75CA;
  padding-bottom: 10px;

}

.lav-referrals__item_additionally-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.lav-referrals__item_additionally-item-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #D2D2D2;
}

.lav-click-fon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

/* lav-referrals END */

/* lav-statistics */
.lav-statistics {
  padding: 60px 22px 104px;
  display: flex;
  flex-direction: column;
}

.lav-statistics-title {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  text-align: center;
}

.lav-statistics_wrapper {
  margin-top: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.lav-statistics_item {
  width: 100%;
  height: 172px;
  display: flex;
  flex-direction: column;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
}

.lav-statistics_item-title {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #E4E4E4;
  border-bottom: 1px solid #fff;
}

.lav-statistics_item-data {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  line-height: 22px;
  color: #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

/* lav-statistics END*/
</style>