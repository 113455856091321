<template>
  <div class="py-4 container-fluid">
    
    
    <div  class="d-flex bg-dark align-items-center p-3 my-3 text-white-50 bg-dark pe-6 box-shadow" style="border-radius: 20px;">
      <div class="lh-100 ms-5">
        <h2 class="mb-0 text-white lh-100 mt-3">Автоматический вывод без комиссии</h2>
        <p class="mt-2" style="font-size: 18px;">
          Выводите средства без комиссии на свой банковский счет. <br>
          Telos Market будет отправлять деньги без необходимости создавать вывод. 
          <br> 
          Поставьте пороговое значение при котором Telos Market будет автоматизировать вывод вашей прибыли. 
          <br>
          Средства будут приходить при достижении лимита и Вам не нужно будет платить с этой операции комиссию.
        </p>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="card border-0">
                <div class="row justify-content-center">
                    <h3 class="mb-4">Настроить банковский счет</h3>
                </div>
                <div class="row">
                    <div class="col-sm-7 border-line pb-3">
                        <div class="form-group">
                            <input class="form-control dark-input" type="text" name="name" placeholder="Имя получателя" size="15">
                            
                        </div>
                        <div class="form-group">
                            <div class="row px-3">
                                <input class="form-control dark-input" type="text" name="card-num" placeholder="Номер карты / 0000 0000 0000 0000" size="18" id="cr_no" minlength="19" maxlength="19">
                            </div>
                        </div>
                        <div class="form-group">
                            <p class="text-muted text-sm mb-0">Укажите сумму для вывода</p>
                            <input type="text" name="exp" placeholder="2 000" size="6" id="exp" minlength="5" maxlength="5">
                        </div>
                        
                        <div class="form-group mb-0">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <!-- <input id="chk1" type="checkbox" name="chk" class="custom-control-input" checked>  -->
                                <label for="chk1" class="custom-control-label text-muted text-sm">
                                  При приблежении доступной прибыли на Едином Счета к сумме 2 000 $, средства будут автоматически отправлены 
                                  на Указанный Вам банковский счет
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5 text-sm-center justify-content-center pt-4 pb-4">
                        <!-- <small class="text-sm text-muted">Order number</small>
                        <h5 class="mb-5">12345678</h5>
                        <small class="text-sm text-muted">Payment amount</small>
                        <div class="row px-3 justify-content-sm-center">
                            <h2 class=""><span class="text-md font-weight-bold mr-2">$</span><span class="text-danger">59.49</span></h2>
                        </div> -->
                        <button type="submit" class="btn btn-red text-center mt-4">Включить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
      <p>История выводов</p>
    </div>
    
   



    
  </div>
</template>

<style scoped>

input[type='number'] {
  -moz-appearance: textfield !important;
}
input {
  padding-left: 5px;
  margin-top: 0;
}

input:focus{
  border:none !important;
  box-shadow:none !important;
  border-color:none !important;
}

.form-group .form-control:focus{
  border:0px solid rgba(0,0,0,0) !important;
}
.dark-input:focus{
  background: #c9c9c9;
  box-shadow: none !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
.dark-input{
  padding-top: 10px !important;
  padding-left: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #3d3d3d !important;
  height: 54px;
  background: #cdcdcd;
  border:none;
  border-radius: 15px;
  color: #B4B4B4;
}
  .accordion-header button{

    color: #161616 !important;
    font-weight: 700;
  }
  .accordion-header{
    background-color:#fff !important;
    color: #000;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }

  

.card {
    padding: 30px 25px 35px 50px;
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
    border: 2px dashed rgb(85, 122, 211) !important;
}

.border-line {
    border-right: 1px solid #BDBDBD;
}

.text-sm {
    font-size: 13px;
}

.text-md {
    font-size: 18px;
}

.image {
    width: 60px;
    height: 30px;
}

::placeholder {
    color: grey;
    opacity: 1;
}

:-ms-input-placeholder {
    color: grey;
}

::-ms-input-placeholder {
    color: grey;
}

input {
    border: none;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 5px;
    margin-top: 2px;
    box-sizing: border-box;
    color: #000;
    font-size: 16px;
}

input:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
}

.btn-red {
    background-color: green;
    color: #fff;
    padding: 8px 25px;
    height: 160px;
    border-radius: 50px;
    font-size: 18px;
    letter-spacing: 2px;
    border: 2px solid #fff;
}

.btn-red:hover {
    box-shadow: 0 0 0 2px green;
}

.btn-red:focus {
    box-shadow: 0 0 0 2px green !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: green;
}

@media screen and (max-width: 575px) {
    .border-line {
        border-right: none;
        border-bottom: 1px solid #EEEEEE;
    }
}
</style>
<script>
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
// import CalcProfit from "./components/CalcProfit";

import { mapGetters,mapActions,mapMutations } from "vuex";

import {
  faHandPointer,
  faUsers,

  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      transactions: [1],
    };
  },
  methods: {
    ...mapActions(["getProfits"]),
    ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
    connect_binance(){
      this.$toast.info("Свяжитесь с менеджером через чат для подключения", { });

    },
    connect_huobi(){
      this.$toast.info("Свяжитесь с менеджером через чат для подключения", { });

    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    callSupport(){
      if(this.isMobile){
        this.$toast.info("Сейчас начнется вызов", { });
        location.href='tel:+78007074730';
      }
      else{
        this.$toast.info("Вы с компьютера или с планшета, наберите номер на мобильном", { });
      }

    },
  },
  computed:{
    ...mapGetters(["user","userProfit"]),
  },
  components: {
    // ReportsBarChart,
    // CalcProfit,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
   mounted() {
    this.getProfits()
  },
};
</script>
