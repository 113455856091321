<template>
  <div class="p-3 mt-5" style="max-width: 1000px;">

    <telegram class="pe-3" style="" v-if="!isMobile()" />

<!-- 
  <div class="alex-office__info-title mt-4 ">{{$t('profile.local_settings') }}</div>

  <div class="alex-office__info_item mt-4 position-relative settings-locaci" style="background-color: #fff;">
    <div class="alex-office__info_item-text" style="font-weight: 600;">{{$t('profile.country') }}</div>
    <div class="alex-office__info_item-text " style="font-weight: 600;">
     <div class=" end-5 position-absolute" >
      {{$t('profile.country_1') }}

      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.72456 6.80965C3.94645 6.59885 4.29367 6.57969 4.53835 6.75216L4.60845 6.80965L9.99984 11.9312L15.3912 6.80965C15.6131 6.59885 15.9603 6.57969 16.205 6.75216L16.2751 6.80965C16.497 7.02044 16.5172 7.3503 16.3356 7.58274L16.2751 7.64934L10.4418 13.191C10.2199 13.4018 9.87267 13.421 9.62799 13.2485L9.5579 13.191L3.72456 7.64934C3.48048 7.41746 3.48048 7.04152 3.72456 6.80965Z" fill="black"/>
      </svg>

     </div>  
   </div>
 </div>

 <div class="alex-office__info_item mt-3 position-relative settings-locaci" style="opacity:0.5">
  <div class="alex-office__info_item-text" style="font-weight: 600;">{{$t('profile.valuta_slovo') }}</div>
  <div class="alex-office__info_item-text" style="font-weight: 600;">
   <div class=" end-5 position-absolute"  >
    rub
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.72456 6.80965C3.94645 6.59885 4.29367 6.57969 4.53835 6.75216L4.60845 6.80965L9.99984 11.9312L15.3912 6.80965C15.6131 6.59885 15.9603 6.57969 16.205 6.75216L16.2751 6.80965C16.497 7.02044 16.5172 7.3503 16.3356 7.58274L16.2751 7.64934L10.4418 13.191C10.2199 13.4018 9.87267 13.421 9.62799 13.2485L9.5579 13.191L3.72456 7.64934C3.48048 7.41746 3.48048 7.04152 3.72456 6.80965Z" fill="black"/>
    </svg>
   </div>  
 </div>
</div>

<div class="alex-office__info_item mt-3 position-relative settings-locaci" style="opacity:0.5">
  <div class="alex-office__info_item-text" style="font-weight: 600;">{{$t('profile.language') }}</div>
  <div class="alex-office__info_item-text" style="font-weight: 600;">
    
   <div class=" end-5 position-absolute"  >
    <div class="row">
      <div class="col choose-lang" :class="$i18n.locale==='en' ? 'active-lang': null" @click="$i18n.locale = 'en'">En</div> 
      <div  class="col choose-lang" :class="$i18n.locale==='ru' ? 'active-lang': null" @click="$i18n.locale = 'ru'">Ru</div>

    </div>
    
   
   </div>  
 </div>
</div> -->



<div class="alex-office__info-title mt-5">{{$t('profile.change_profile_info') }}</div>


<profile-info-card
  title="Редактировать инфо профиля"
  description=""
  :info="info"
  
  :action="{
    route: 'javascript:;',
    tooltip: 'Edit Profile',
  }"
  />


    <div class="h-100 " style="display: none;">
      <div class="p-3 pb-0 card-header ">
      </div>
      <div class="p-3 card-body"  v-if='user.notiffications'>
        <h6 class="text-xs text-uppercase text-body font-weight-bolder">
          {{$t('profile.finance') }}
        </h6>
        <ul class="list-group">
          <li class="px-0 border-0 list-group-item ">
            <soft-switch
              id="flexSwitchCheckDefault"
              name="notiff_report_week"
              class="ps-0 ms-auto "
              label-class="mb-0  text-body ms-3 text-truncate w-70"
              :checked='user.notiffications.week_report'
              @change="change('checkbox',$event)"
              >{{$t('profile.otchet') }}</soft-switch
            >
          </li>
        </ul>
        
        <h6
          class="mt-4 text-xs text-uppercase text-body font-weight-bolder"
        >
        
        {{$t('profile.security') }}
        </h6>
        <ul class="list-group">
          <li class="px-0 border-0 list-group-item">
            <soft-switch
              id="flexSwitchCheckDefault3"
              name="notiff_new_login"
              :checked='user.notiffications.new_login'
              @change="change('checkbox',$event)"
              class="ps-0 ms-auto"
              label-class="mb-0 text-body ms-3 text-truncate w-70"
              >{{$t('profile.new_vhod') }}</soft-switch
            >
          </li>
          <li class="px-0 border-0 list-group-item">
            <soft-switch
              id="flexSwitchCheckDefault4"
              name="notiff_change_password"
              @change="change('checkbox',$event)"
              class="ps-0 ms-auto"
              label-class="mb-0 text-body ms-3 text-truncate w-70"
              :checked='user.notiffications.change_password'
              >{{$t('profile.new_password') }}</soft-switch
            >
          </li>
        </ul>
      </div>
    </div>
    
    <div class="security-block pt-4 position-relative z-index-1 p-3 h-100">
      <div class="d-flex flex-column h-100">
        <h5 class="text-white font-weight-bolder mb-4 pt-2">
          <i
          class="fa fa-shield me-2"
          aria-hidden="true"
        ></i> {{$t('profile.safe') }}
        </h5>
    
        <div class="input-group mb-2" >
          <input 
            id="oldpassword"
            type="password"
            v-model="old_password"
            :placeholder="$t('profile.st_password')"
            name="oldpassword"
            class="form-control  dark-input" 
            aria-label="старый пароль" />
          <button class="btn  text-reset" style='color:#fff;padding-top:11px; height: 54px;background:#444444;' type="button" @click="send_new_password()">{{$t('profile.sbrosit') }}</button>
        </div>
        <div class="input-group mb-2" >
          <input 
            id="new_password_1"
            type="password"
            v-model="new_password_1"
            :placeholder="$t('profile.new_password1')"
            name="new_password_1"
            class="form-control dark-input" 
            aria-label="новый пароль" />
        </div>
        <div class="input-group mb-2" >
          <input 
            id="new_password_2"
            type="password"
            v-model="new_password_2"
            :placeholder="$t('profile.new_password_add')"
            name="new_password_2"
            class="form-control dark-input" 
            aria-label="новый пароль (еще раз)" />
        </div>
          <button class="d-flex polina-btn-dark-change" 
            :disabled="loading" 
            @click="change_password_callback()"
            type="button" >
            <template v-if="loading">
              {{$t('profile.loading') }}
            </template>
            <template v-else>
              {{$t('profile.new_pass') }}
            </template>
            </button>
        <!-- <p class="text-white">
          <b>2FA</b>
          
        </p> -->

      </div>
    </div>
    <hr>
    <i class="text-dark mt-4 mb-4" v-if="user.manager">
      {{$t('profile.manager_name') }} {{ user.manager.first_name }} #{{ user.manager.promo }}  <br> tg @{{ user.manager.telegram_username }} – {{ user.manager.email }}  
    </i>
</div>
</template>


<style scoped>

/* 400 */
@font-face {
  font-family: 'RFDewi-Regular';
  src: url('../assets/fonts/RFDewi-Regular.woff') format('woff')
}

/* 600 */
@font-face {
  font-family: 'RFDewi-Semibold';
  src: url('../assets/fonts/RFDewi-Semibold.woff') format('woff')
}

/* 700 */
@font-face {
  font-family: 'RFDewi-Bold';
  src: url('../assets/fonts/RFDewi-Bold.woff') format('woff')
}
.border-header{
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 30px;
}
.active-lang{
  border: 2px solid #242121 !important;
}
.input-group .form-control:focus{
  border:0px solid rgba(0,0,0,0) !important;
}
.polina-btn-dark-change:hover{
  transform: none !important;
}
.choose-lang{
  border-radius:10px;
  margin-right:5px;
  margin-left:5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.polina-btn-dark-change{
  color: #FFFFFF;
  height: 62px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  vertical-align:baseline;
  border: 1px solid #FFFFFF !important;
  border-radius: 12px !important; 
  font-style: normal !important;
  font-weight: 600 !important;
  background-color: #202020;
  line-height: 24px !important;
  margin-top: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom:20px;
}
.dark-input:focus{
  background: #323232;
  box-shadow: none !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #B4B4B4 !important;
}
.dark-input{
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #B4B4B4 !important;
  height: 54px;
  background: #323232;
  border:none;
  border-radius: 15px;
  color: #B4B4B4;
}
.card-profile{
  min-height:300px;
  background-color: #FEFEFE !important;
}
.security-block{
  background: #202020;
  border-radius: 15px;
  margin-right:15px;
  margin-top:35px;
}
.my-bg{
  background-color: #FEFEFE !important;
}
.text-reset{
  font-weight: 600;
  font-size: 16px;
  color:#fff !important;
  line-height: 19px;
  text-transform: uppercase;
}
.alex-header {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'RFDewi-Semibold';
  font-size: 16px;
  padding: 22px 18px 22px 16px;
}
.settings-locaci{
  background-color: #F6F6F8;
  border: 1px solid rgba(0,0,0,0.05);
}
.alex-header svg {
  rotate: 180deg;
  position: absolute;
  left: 16px;
}

.alex-color__block {
  padding: 22px 29px 22px 30px;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  width: 100%;
  align-items: center;
  background: linear-gradient(270deg, #FF2749 -6.64%, #6039FB 141.62%);
}

.alex-color__block-label {
  color: #FFF;
  font-size: 20px;
  font-family: 'RFDewi-Regular';
  line-height: normal;
  letter-spacing: 0.2px;
}

.alex-color__block-value {
  color: rgba(255, 255, 255, 0.50);
  font-size: 30px;
  font-family: 'RFDewi-Semibold';
  line-height: normal;
  letter-spacing: 0.3px;
}

.alex-color__block-value span {
  font-family: 'RFDewi-Bold';
  color: #FFF;
}

.alex-office__container {
  margin-top: 35px;
  padding: 0 17px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.alex-office__block {
  display: flex;
  gap: 16px;
}

.alex-office__block_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 4px 0 0;
}

.alex-office__container-title {
  color: #151515;
  font-family: 'RFDewi-Bold';
  font-size: 24px;
  letter-spacing: -.3px;
}

.alex-office__block_content-title {
  color: #070707;
  font-family: 'RFDewi-Bold';
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
}

.alex-office__block_content-text {
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
}

.alex-office__info_blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 10px;
}

.alex-office__info_blocks-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 15px;
  background: #242121;
  padding: 10px 20px;
  flex: 1;
  white-space: nowrap;
}

.alex-office__info_blocks-item:first-child {
  width: 100%;
  flex-basis: auto;
  align-items: center;
}

.alex-office__info_blocks-item-title {
  color: #D8E3FC;
  font-size: 18px;
}

.alex-office__info_blocks-item-value {
  color: #FFF;
  font-size: 26px;
  font-family: 'RFDewi-Bold';
}

.alex-office__info {
  display: flex;
  flex-direction: column;
  padding: 30px 18px;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #BABDC5;
  background: #F6F6F8;
}

.alex-office__info-description {
  color: #373737;
  font-size: 18px;
}

.alex-office__info-title {
  color: #1B1B1B;
  font-size: 22px;
  font-family: 'RFDewi-Semibold';
  margin-top: 10px;
  margin-bottom: 15px;
}

.alex-office__info_item {
  display: flex;
  padding: 10px;
  gap: 15px;
  border-radius: 9px;
  background: #edededda;
}

.alex-office__info_item-text {
  color: #373737;
  font-size: 16px;
}

.alex__replenishment {
  display: flex;
  justify-content: space-between;
  font-family: 'RFDewi-Semibold';
}

.alex__replenishment-text {
  color: #000;
  font-size: 18px;
  max-width: 220px;
}

.alex__replenishment-amount {
  font-size: 24px;
}

.alex__replenishment-amount span {
  font-family: 'RFDewi-Bold';
}

.alex-btn-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.alex-btn-top-up {
  display: flex;
  padding: 24px 108.5px 23px 108.5px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #000;
  color: #000;
  font-family: 'RFDewi-Semibold';
  font-size: 26px;
  background: transparent;
}

.alex-btn-see-results {
  display: flex;
  border-radius: 16px;
  border: 1px solid #BABDC5;
  background: #ECEEF3;
  padding: 17px 55px;
  align-items: center;
  color: #3A3A3A;
  font-family: 'RFDewi-Semibold';
  text-align: center;
  justify-content: center;
  font-size: 18px;
}

.alex-block-separator {
  width: 287px;
  height: 2px;
  background: rgba(0, 0, 0, 0.51);
  margin: 49px auto;
  border-radius: 1px;
}

/* replenishment */
.alex-replenishment {
  padding-top: 110px;
  padding-bottom: 70px;
  display: flex;
  position: relative;
  padding-left: 20px;
  max-width: 640px;
  margin: 0 auto;
}

.alex-replenishment-col {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.18);
  min-width: 20px;
  height: 100%;
}

.alex-replenishment-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alex__replenishment-title {
  color: #000;
  font-size: 26px;
  letter-spacing: 0.26px;
  font-family: 'RFDewi-Semibold';
}

.alex__replenishment-description {
  color: rgba(0,0,0,0.6);
  font-size: 18px;
  letter-spacing: 0.18px;
}

.alex__replenishment_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.alex__replenishment_btns-btn {
  display: flex;
  gap: 10px;
  color: #fff;
  font-family: 'RFDewi-Bold';
  padding: 15px 10px;
  border-radius: 7px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  height: max-content;
}

.alex-btn-black {
  background: #141414;
}

.alex-btn-blue {
  background: linear-gradient(97deg, #1750D1 2.83%, #27E2FC 108.6%);
  line-height: 15px;
}

.alex-btn-pink {
  background: linear-gradient(99deg, #8020D3 16.22%, #F75593 118.6%);
}

.alex-replenishment_available {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.alex-input__box {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #c3c3c3;
  background: #FFF;
  padding: 10px 10px 10px 1px;
}

.alex-input__box p {
  color: #000;
  font-size: 24px;
}

.alex-input__box input {
  width: 100%;
  padding-left: 30px;
  font-size: 20px;
  letter-spacing: 0.2px;
  margin-top: 4px;
  border:none;
}


.alex-input__box input::placeholder {
  color: #8f8f8f !important;
}
input:focus{
  border:none !important;
  box-shadow:none !important;
  border-color:none !important;
}

.alex-input__box input::-webkit-outer-spin-button,
.alex-input__box input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.alex-input__box input[type='number'] {
  -moz-appearance: textfield !important;
}

.alex-replenishment-available-text {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 14px;
  font-style: italic;
  line-height: normal;
}

.alex-replenishment__choice {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 18px;
  background: #F6F6F8;
  padding: 8px 7px;
}

.alex-replenishment__choice-item {
  display: flex;
  color: #3A3A3A;
  padding: 20px 0;
  border:1px solid rgba(0,0,0,0.1);
  font-size: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-family: 'RFDewi-Semibold';
  letter-spacing: 0.14px;
  flex: 1;
  padding: 20px 10px;
  min-width: 140px;
  height: 56px;
}

.alex-choice-item-select {
  color: #FFF;
  background: #000;
}

.alex-replenishment__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 35px;
}

.alex-replenishment__cards-item {
  width: 79px;
  height: 45px;
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  border: 1px solid #BABDC5;
  background: #F6F6F8;
  padding: 4px 3px;
  gap: 3px;
}

.alex-replenishment__cards-item img {
  width: 28px;
  height: 17px;
  object-fit: cover;
}

.alex-replenishment__cards-item p {
  color: #333;
  font-size: 12px;
  letter-spacing: 0.12px;
  width: 100%;
  text-align: right;
}

.alex-replenishment__cards-add {
  width: 79px;
  height: 45px;
  display: flex;
  border-radius: 11px;
  background: #F6F6F8;
  color: #161616;
  font-size: 32px;
  justify-content: center;
  align-items: center;
}

.alex-replenishment__cards .alex-input__box {
  margin-top: 10px;
}

.alex-replenishment__cards input {
  padding-left: 0;
}

.alex-checkbox-transform input {
  display: none;
}

.alex-checkbox__label:before {
  content: ' ';
  display: block;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: transparent;
}

.alex-checkbox__label:after {
  content: '';
  display: block;
  height: 23px;
  width: 23px;
  border-radius: 4px;
  transition: 200ms;
  position: absolute;
  top: 0px;
  left: 0px;
  /* background: #fff200; */
  transition: 100ms ease-in-out;
}

.alex-checkbox__input:checked~.alex-checkbox__label:after {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 11px;
  width: 15px;
}



.alex-checkbox__label {
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.16px;
  opacity: 0.5;
  margin-left: 10px;
}

.alex-replenishment__user-data {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.alex-replenishment__user-data input {
  padding-left: 0;
}

.alex-replenishment__commission {
  display: flex;
  justify-content: space-between;
  color: #202020;
  font-size: 16px;
  letter-spacing: 0.16px;
}

/* replenishment END */
/* replenishment */
.alex-choice__wrapper {
  margin-top: 30px;
}

.alex-choice__wrapper-label {
  color: #AAA;
  font-size: 18px;
  letter-spacing: 0.18px;
}

.alex-choice__wrapper_items {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.alex-choice__wrapper_item {
  color: #000;
  font-family: 'RFDewi-Semibold';
  font-size: 18px;
  border-radius: 10px;
  background: #F6F6F8;
  padding: 17px 17px;
}

.alex-choice__item-select {
  color: #FFF;
  background: #242121;
}

.alex-promocode {
  margin: 35px 0;

}

.alex-promocode .alex-checkbox__label {
  font-style: italic;
}

.alex-choice__money_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 7px;
}

.alex-choice__money_item {
  padding: 7px 5px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #D3D4DA;
  background: #FFF;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 0.18px;
  flex: 1;
  white-space: nowrap;
}

.alex-choice__money_item:hover {
  color: #FFF;
  background: #242121;
  border: 1px solid transparent;
}

.alex-choice__money_wrapper .alex-input__box p {
  font-size: 20px;
}

.alex-choice__money_wrapper input {
  padding-left: 5px;
  margin-top: 0;
}

.attention__block {
  margin-top: 35px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.attention__block-up-row {
  background: #FAB23C;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attention__block p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background: #FFFEF5;
  color: #533D05;
  font-size: 12px;
  padding: 10px 20px 15px;
  letter-spacing: 0.12px;
  text-align: center;
}

.attention__block p span {
  font-weight: 700;
}

.alex-qr-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
}

.alex-qr-code_btn-copy {
  margin-top: 30px;
  width: 156px;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 32px;
  background: #0D0D0D;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.alex-qr-code p {
  margin-top: 15px;
  color: #4F4F4F;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20.333px;
  letter-spacing: 0.36px;
}

.alex-replenishment .alex-block-separator {
  width: 100%;
  margin: 25px 0;
}

.alex-replenishment-footnote {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.12px;
}

/* replenishment END  */
@media screen and (max-width: 360px) {
  .alex-office__info_blocks-item {
     align-items: center;
  }
}

</style>
<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import Telegram from "./components/Telegram.vue";

import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import referal from "@/assets/img/reg.jpeg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapActions } from "vuex";
import profile_photo from "@/assets/img/profile.webp";

export default {
  name: "ProfileOverview",
  components: {
    SoftSwitch,
    ProfileInfoCard,
    Telegram
    // DefaultProjectCard,
    // PlaceHolderCard,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      photo: profile_photo,
      referal: referal,
      old_password: "",
      // ref_link:'1234',
      new_password_1: "",
      new_password_2: "",
    };
  },
  computed:{
    ...mapGetters(["user"]),
    ref_link(){
      return `https://tmarket.me/sign-up?ref=${this.user.uid}`
    },
    info (){
      return {
            fullName: this.user.first_name,
            mobile: this.user.phone,
            email: this.user.email,
          }
    }
  },
  methods: {
    ...mapActions(["getUserData","updateUser","logout","restore", "change_password"]),
    send_new_password(){
      var data = {'email':this.user.email, 'no_redirect': true}
      this.restore({vm:this,data:data})
    },
    async copya(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(this.$t('statref.copy_buffer'), { });
      } catch($e) {
        this.$toast.error(this.$t('statref.no_copy'), { });
      }
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');
    },
    openInstruction(){
      alert('Инструкция находится на модерации, дата официальной публикации 15 ноября 2022')
    },  
    change_password_callback(){
      this.loading = true
      var data = {
        email:this.user.email, 
        old_password: this.old_password,
        new_password_1: this.new_password_1,
        new_password_2: this.new_password_2,
      }
      this.change_password({vm:this,data:data})
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    change(type,event){
      console.log('change',event.target.checked,event.target.name)

      if (type==='checkbox') {
        var data = {}
        data[event.target.name] = event.target.checked
        console.log(data)
         this.updateUser({vm:this,data:data})
      }
  

    }
  },
  watch:{
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
