<template>
    <div class="p-3 card "  >
      <div class="card-header pb-0">
        <h5 class="font-weight-bolder">Калькулятор доходности</h5>
      </div>
      <div class="row ">
        <div class="col-lg-5 ms-auto text-center order-md-2 order-lg-2  mt-lg-0 p-5  ">
            <div class="my-style-rounded align-items-center d-flex justify-content-center" >
              <div>
                 <small>Прибыль</small>
                <h1> ${{Math.round(pribyl).toLocaleString('en')}}</h1>
                </div>
                
            </div>
                  
           
          </div>
          <div class="col-lg-6 p-4 order-sm-1">
            <div class="d-flex flex-column h-100 ">
              <div class="row">
                <div 
                  class='col-4'
                  v-for="(item,index) in userBots"
                  :key="item.slug"
                > 
                    <input class="w-100 btn-check" type="radio" name="robot"  v-on:change="robot = item;check_index = index" :id="item.id" :value="item.slug" :disabled="false" :checked='index==0'>
                    <label class="w-100 btn" style="padding-left:0px;padding-right:0px;" :class="{'btn-calc-active':index === check_index,'btn-calc':index != check_index}" :for="item.id">{{item.name}} </label>
                </div> 
              </div>

              <div class="row pt-4">
                <p class="mb-0 ">
                  Депозит
                </p>
                <div class=""  min="0" max="365"  style="display:flex; justify-content: space-between;">
                  <div class="text-muted ">
                    <span class="badge rounded-pill bg-dark">${{deposit.toLocaleString('en')}}</span>
                    </div>
                </div>
                <div class="pl-3 pt-2"> 
                  <input type="range" :min="robot.minimum0" :max="robot.maximum"  v-model="deposit" class="form-range slide" id="customRange1">
                </div>
                 <div class="" style="display:flex; justify-content: space-between;">
                  <div class=""><small>${{robot.minimum.toLocaleString('en')}}</small></div>
                  <div class=""><small>${{robot.maximum.toLocaleString('en')}}</small></div>
                </div>
              </div>

              <div class="row pt-4">
                <p class="mb-0 ">
                  Срок
                </p>
                 <div class=""  style="display:flex; justify-content: space-between;">
                  <div class="text-muted">
                    <span class="badge rounded-pill bg-dark">{{period}} дней</span>
                  </div>
                </div>
                <div class="pl-3 pt-2"> 
                  <input type="range"  min="0" max="365"  v-model="period"  class="form-range slide" id="customRange1">
                </div>
                 <div class=""  style="display:flex; justify-content: space-between;">
                  <div class="text-muted"><small>0 дней</small></div>
                  <div class=""><small>365 дней</small></div>
                </div>
              </div>
              
              <button
                  class="btn bg-gradient-dark mt-5"
                  @click="to('/sign-up')"
                  type="button"
                  >Зарегистрироваться</button
                >
              <button
                  class="btn btn-outlined bg-gradient-dark "
                  @click="to('/sign-in')"
                  type="button"
                  >Войти</button
                >
            </div>
          </div>
          
        </div>
      
      
    </div>
</template>
<style>
body {
  overflow-x: hidden !important;
}
</style>
<style scoped>

.my-style-rounded{
  border-radius: 50% ;
  border: 10px solid #F2F2F2 !important; 
  aspect-ratio: 1 / 1 !important;
  width:100%; 
}

.btn-calc{
  background: rgba(0, 0, 0, 0.1)  !important;
  border-radius: 5px;
  font-weight: 400;
  color: #2D2D2D  !important;
  box-shadow: none  !important;
}

.btn-calc-active{
  background-color: #83B5A4 !important;
  color: #2D2D2D;
  border-radius: 5px;
  font-weight: 400;
  box-shadow: none  !important; 
}
.slide{
    width: 100%;
    height: 4px;
    background: #83B5A4 ;
}
.slide::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%; 
  background: #000000  !important;
  cursor: pointer;
}

.slide::-moz-range-thumb {
  border-radius: 50%;
  background: #000000 !important;
  cursor: pointer;
}


</style>
<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
// import Auto from "../../components/Icon/Auto.vue";
import { robots } from "../../robots.js";
import { mapGetters,mapActions,mapMutations  } from "vuex";
// import RoundSlider from 'vue-round-slider'

export default {
  name: "authors-table",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      robots: robots,
      deposit: 250,
      period: 190,
      check_index: 0,
      robot:{ "name": "Бонсай", "slug": "alpha", "revenue_from": 0.88, "revenue_to": 0.98, "minimum": 10, "maximum": 499, "checked": true },
    };
  },
  mounted() {
    // setTooltip();
    this.getBotsUser();
  },

  computed:{
     ...mapGetters(["userBots"]),
     pribyl(){
      return this.robot.revenue_to * this.period * this.deposit/100
     }
  },
  components: {
    // SoftAvatar,
    // RoundSlider
    // SoftBadge,
    // Auto
  },
  methods:{
    ...mapMutations(["toggleConfigurator",]),
    ...mapActions(["getBotsUser"]),
    to(path){
      this.$router.push(path);
    },
    getImgUrl(pic) {
        return require('../../assets/img/'+pic)
    }
  }
};
</script>
