<template>
  
  <div>
  
    <section class="lav-statistics pb-10 ">
      
       <div class="lav-statistics-wrapper lav-container">
         <header class="mt-5 lav-affiliateProgram-header">
            <h1 class="lav-affiliateProgram-title">{{$t('tasks.partner_program')}}</h1>
            <p class="lav-affiliateProgram-text">
               {{$t('tasks.desc_p')}}
            </p>
            <div class=" read-more">
               <a  target="_blank" href="https://docs.google.com/presentation/d/1KLzGLulrjROgjrKJFEKfSpAuPzj3NYDf6nWC3qMEcGM/pub?start=false&loop=false&delayms=3000#slide=id.p" style="" class="">
                  {{$t('tasks.read_det')}} 
               </a> 
            </div>
            
         </header>
         
         <h1 class="mt-5 lav-affiliateProgram-link">Your Personal Link & Promo Code</h1>
         <div class="lav-btn__wrapper mb-7">
            <a href="#" :class="[ !web_version ? 'lav-btn-telegram' : 'lav-btn-web',]" @click="web_version=true" >{{$t('tasks.link_for_web')}}</a>
            <a href="#" 
               :class="[ web_version ? 'lav-btn-telegram' : 'lav-btn-web',]"
            @click="web_version=false" >{{$t('tasks.link_for_tg')}}</a>
         </div>

          <div class="lav-statistics__data">
            <div class="lav-statistics__data_item" v-if="web_version">
                <h3 class="lav-statistics__data_item-title"> {{$t('tasks.link')}}</h3>
                <p class="lav-statistics__data_item-text">{{$t('tasks.desk_info_ref_link.0')}}
                  <span>{{$t('tasks.desk_info_ref_link.1')}}</span> {{$t('tasks.desk_info_ref_link.2')}}
                  </p>
                <div @click="copya(ref_link)" class="lav-copy-block">
                   <a href="#" class="lav-referral-link">{{ref_link}}</a>
                   <button class="lav-btn-copy">
                      <img src="../assets/img/copy.svg" alt="копирование">
                   </button>
                </div>
             </div>
            
             <div class="lav-statistics__data_item"  v-if="web_version">
                <h3 class="lav-statistics__data_item-title"> {{$t('tasks.promo_code')}} </h3>
                <div class="lav-copy-block" @click="copya(user.secret_code)">
                   <div class="lav-promotional-code">{{user.secret_code}}</div>
                   <button class="lav-btn-copy">
                      <img src="../assets/img/copy.svg" alt="copy">
                   </button>
                </div>
             </div>

             <div class="lav-statistics__data_item" v-if="web_version">
                <h3 class="lav-statistics__data_item-title">Qr-{{$t('tasks.code')}}</h3>
                <p class="lav-statistics__data_item-text">{{$t('tasks.show_individual')}}<br>
                   <span>QR-{{$t('tasks.code')}} {{$t('tasks.for_download')}}</span>
                </p>
                <div class="lav-qr"> <img :src="user.qr_address" style="width:100%; " alt=""></div>
             </div>


            

             
             <div class="lav-statistics__data_item" v-if="!web_version">
              <h3 class="lav-statistics__data_item-title">{{$t('tasks.link_for_tg')}}</h3>
              <p class="lav-statistics__data_item-text">{{$t('tasks.desk_info_ref_link.0')}}<span>{{$t('tasks.desk_info_ref_link.1')}}</span> {{$t('tasks.desk_info_ref_link.2')}}</p>
              <div  @click="copya(`https://t.me/telosmarketbot?start=secret_${user?.secret_code}`)"  class="lav-copy-block">
                 <a href="#" class="lav-referral-link">https://t.me/telosmarketbot?start=secret_{{user?.secret_code}}</a>
                 <button class="lav-btn-copy">
                    <img src="../assets/img/copy.svg" alt="copy">
                 </button>
              </div>


           </div>

          <div class="lav-statistics__content_item" v-if="!web_version">
            <div class="lav-statistics__content_item-title"> {{$t('tasks.clicks_tg')}} </div>
            <div class="lav-statistics__content_item-data">
              {{user?.ref_data_short?.tg_open}}  <span>(+{{user?.ref_data_short?.tg_open}})</span></div>
         </div>

          </div>
          <div class="lav-statistics__content">
            
             <h2 class="lav-statistics__content-title">{{$t('tasks.stat')}}</h2>
             

             <div class="lav-statistics__content_row">
                
                <div class="lav-statistics__content_item">
                   <div class="lav-statistics__content_item-title">{{$t('tasks.total_users')}}</div>
                   <div class="lav-statistics__content_item-data">
                    {{user.refs}} {{$t('tasks.chelovek') }}

                   </div>
                </div>

                <div class="lav-statistics__content_item">
                  <div class="lav-statistics__content_item-title">{{$t('tasks.volume_team')}} </div>
                  <div class="lav-statistics__content_item-data">
                   {{user.ref_data_short?.all_people_deposit.toLocaleString('en')}} $

                  </div>
               </div>
              </div>

               <div class="lav-statistics__content_row">

                <div class="lav-statistics__content_item">
                  <div class="lav-statistics__content_item-title"> {{$t('tasks.profit_from_team')}}   </div>
                  <div class="lav-statistics__content_item-data">
                    {{user.refearn?.toLocaleString('en') }} $
                   </div>
               </div>

               <div class="lav-statistics__content_item">
                <div class="lav-statistics__content_item-title">
                  {{$t('tasks.profit_from_team_passive')}}   </div>
                <div class="lav-statistics__content_item-data">
                  {{user.ref_data_short?.passive_income?.toLocaleString('en')  }} $
                </div>  
             </div>
             </div>
             <div class="lav-statistics__content_row">
               
                <div class="lav-statistics__content_item">
                   <div class="lav-statistics__content_item-title">
                    {{ $t('tasks.upgrade_self_portfolio')  }}</div>
                   <div class="lav-statistics__content_item-data">
                    +{{user.ref_data_short?.booster}} % / {{$t('tasks.month')}}
                   </div>
                </div>
               
             </div>

             <div class="lav-statistics__content_row">
              <div class="lav-statistics__content_item lav-yel">
                 <div class="lav-statistics__content_item-title text-dark">{{$t('tasks.total_m')}}</div>
                 <div class="lav-statistics__content_item-data  text-dark">
                  {{ (user.ref_data_short?.passive_income+user.refearn).toLocaleString('en') }} $
                 </div>
              </div>
            
           </div>

          </div>
 
          <div class="lav-separator lav-desktop"></div>
 
          <div v-if="user.refs>0" class="lav-statistics__btns">
             <button   @click="GoToStat()" class="lav-btn-history">{{$t('tasks.see_statis') }}</button>
          </div>
 
       </div>
    </section>
  </div>

</template>


<style scoped>


.select-source{
  width:180px;
  height: 50px;
  border: 1px solid #c3c3c3;
  border-radius: 8px;
  text-align: center;
  align-content: center;
  margin-left:10px;
  cursor: pointer;

}
.select-source.selected{
  border: 3px solid #000;
  background-color: #ffffff;
}




/* @media (prefers-reduced-motion: no-preference) {
  html {
     scroll-behavior: smooth;
  }
} */

.lav-desktop {
  display: flex !important;
}

.lav-mobile {
  display: none !important;
}


.lav-container {
  max-width: 1512px;
  margin: 0 auto;
}

/* lav-affiliateProgram */

.lav-affiliateProgram {
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 31px;
  background: linear-gradient(180deg, #282828 1.59%, #151515 60.95%,  #000000 100%);
}

.lav-affiliateProgram-header {
  color: #000;
  
}
.lav-affiliateProgram-link{
  color:#fff;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  margin-bottom: 30px;
}
.lav-affiliateProgram-title {
  color:#fff;
  font-family: "Inter", sans-serif;
  font-size: 70px;
  line-height: 74px;
}

.lav-affiliateProgram-text {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 28px;
  line-height: 34.4px;
}

.lav-affiliateProgram-figure {
  position: relative;
  margin-top: 45px;
  width: 100%;
  height: 1150px;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.lav-btn-invite {
  position: absolute;
  width: 352px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 53%;
  left: 51%;
  transform: translate3d(-50%, -50%, 0);
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 35px;
  color: #FFFFFF;
  background-color: #000;
  background: #000000;
  box-shadow: 0px -1px 15.7px #ffffff;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
}

.lav-btn__wrapper {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  justify-content: start;
  height: 24px;
}

.lav-btn__wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  height: 24px;
}

.lav-btn-web {
  padding: 10px 15px;
  color: #000;
  border:2px solid #ffffff;
  color: #fff;
}
.lav-btn-telegram {
  padding: 10px 15px;
  border:2px solid #606060;
  color: #fff;
}

/* lav-affiliateProgram END */

/* lav-statistics */
.lav-statistics {
  background-color: #000;
}

.lav-statistics-wrapper {
  padding: 100px 144px 112px 103px;
  display: flex;
  flex-direction: column;
}

.lav-statistics__data {
  display: flex;
  gap: 109px;
}

.lav-statistics__data_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28%;
}

.lav-statistics__data_item-title {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 38px;
  line-height: 35px;
  color: #FFFFFF;
}

.lav-statistics__data_item-text {
  margin-top: 25px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #E4E4E4;
}

.lav-statistics__data_item-text span {
  font-family: "Inter", sans-serif;
}

.lav-qr {
  width: 264px;
  height: 264px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 50px;
}

.lav-copy-block {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 38px 20px;
  background: #15171B;
  border-radius: 13px;
}

.lav-btn-copy {
  min-width: 56px;
  height: 56px;
  background: #000000;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.53), 0px 4px 30.6px #ede687;
  border-radius: 90px;
  margin-left: 20px;
}

.lav-promotional-code {
  font-style: italic;
  font-family: "Inter", sans-serif;
  font-size: 38px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  flex: 1;
}

.lav-referral-link {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-size: 12px;
  line-height: 19px;
  color: #FFFFFF;
  word-break: break-all;
  overflow-wrap: anywhere;
}

.lav-statistics__content {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
}

.lav-statistics__content-title {
  font-family: "Inter", sans-serif;
  font-size: 150px;
  line-height: 74px;
  color: #FFFFFF;
  margin-bottom: 96px;
}

.lav-statistics__content_row {
  width: 100%;
  display: flex;
  gap: 17px;
  margin-bottom: 40px;
}

.lav-statistics__content_item {
  width: 348px;
  height: 172px;
  display: flex;
  flex-direction: column;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
}
.read-more{
   color: #fff;
   font-size: 20px;
   text-decoration: underline;
   margin: auto;
   width: 100%;

}
.read-more a{
   background-color: #fff;
   padding: 10px 15px;
   border-radius: 8px ;
}
.lav-yel {
  background: linear-gradient(180deg, #FAB23C 11.59%, #FAB23C 50.95%);
;
}

.lav-statistics__content_item-title {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #E4E4E4;
  border-bottom: 1px solid #fff;
}

.lav-statistics__content_item-data {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  line-height: 21px;
  color: #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.lav-statistics__content_item-data span {
  color: #F4D380;
}

.lav-separator {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-top: 98px;
  margin-bottom: 108px;
}

.lav-statistics__btns {
  display: flex;
  gap: 31px;
}

.lav-btn-history {
  width: 348px;
  padding: 23px 0;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #000;
  background:#FAB23C;
  border-radius: 11px;
}

.lav-btn-details {
  width: 348px;
  padding: 23px 0;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;
  background: #222222;
  border-radius: 11px;
}

/* lav-statistics END */

@media screen and (width > 768px) and (width < 1520px) {
  .lav-affiliateProgram {
     padding-top: 7.93vw;
     padding-bottom: 2.05vw;
  }

  .lav-affiliateProgram-header {
   padding-left: 10px;
  }

  .lav-affiliateProgram-title {
     width: 36.54vw;
     font-size: 4.63vw;
     line-height: 4.89vw;
  }

  .lav-affiliateProgram-text {
     font-size: 1.85vw;
     line-height: 2.27vw;
  }

  .lav-affiliateProgram-figure {
     margin-top: 2.97vw;
     height: 75.98vw;
  }

  .lav-btn-invite {
     width: 23.26vw;
     height: 7.66vw;
     font-size: 2.11vw;
     line-height: 2.31vw;
     box-shadow: vw -.07vw 1.04vw #e3ed87;
     border-radius: .66vw;
  }

  .lav-btn__wrapper {
     gap: 1.32vw;
  }

  .lav-btn__wrapper a {
     font-size: 20px;
     line-height: 2.31vw;
     border-radius: 10px;
  }

  .lav-btn-web {
      padding: 20px 15px;
   }

  .lav-btn-telegram {
     padding: 20px 15px;
   }

  /* lav-affiliateProgram END */

  /* lav-statistics */
  .lav-statistics-wrapper {
     padding: 6.61vw 9.51vw 7.4vw 6.81vw;
  }

  .lav-statistics__data {
     gap: 7.2vw;
  }

  .lav-statistics__data_item-title {
     font-size: 2.51vw;
     line-height: 2.31vw;
  }

  .lav-statistics__data_item-text {
     margin-top: 1.65vw;
     font-size: 1.06vw;
     line-height: 1.39vw;
  }

  .lav-qr {
     width: 17.44vw;
     height: 17.44vw;
     border-radius: .66vw;
     margin-top: 3.3vw;
  }

  .lav-copy-block {
     margin-top: 3.3vw;
     padding: 2.51vw 1.32vw;
     border-radius: .86vw;
  }

  .lav-btn-copy {
     min-width: 3.7vw;
     height: 3.7vw;
     box-shadow: vw .07vw .26vw rgba(255, 255, 255, 0.53), vw .26vw 2.02vw #e6ed87;
     border-radius: 5.95vw;
     margin-left: 1.32vw;
  }

  .lav-promotional-code {
     font-size: 2.51vw;
     line-height: 2.31vw;
  }

  .lav-referral-link {
     font-size: .79vw;
     line-height: 1.26vw;
  }

  .lav-statistics__content {
     margin-top: 16.52vw;
  }

  .lav-statistics__content-title {
     font-size: 9.91vw;
     line-height: 4.89vw;
     margin-bottom: 6.34vw;
  }

  .lav-statistics__content_row {
     gap: 1.12vw;
     margin-bottom: 2.64vw;
  }

  .lav-statistics__content_item {
     width: 22.99vw;
     height: 11.36vw;
     border: .07vw solid #FFFFFF;
     border-radius: 1.32vw;
  }

  .lav-statistics__content_item-title {
     height: 4.96vw;
     font-size: .93vw;
     line-height: 1.39vw;
     border-bottom: .07vw solid #fff;
  }

  .lav-statistics__content_item-data {
     font-size: 1.26vw;
     line-height: 1.39vw;
  }

  .lav-statistics__content_item-data span {
     margin-left: .66vw;
  }

  .lav-separator {
     height: .13vw;
     margin-top: 6.48vw;
     margin-bottom: 7.14vw;
  }

  .lav-statistics__btns {
     gap: 2.05vw;
  }

  .lav-btn-history {
     width: 22.99vw;
     padding: 1.52vw 0;
     font-size: 1.19vw;
     line-height: 2.31vw;
     border-radius: .73vw;
  }

  .lav-btn-details {
     width: 22.99vw;
     padding: 1.52vw 0;
     font-size: .99vw;
     line-height: 2.31vw;
     border-radius: .73vw;
  }

  /* lav-statistics END */
}

@media screen and (width < 768px) {

  .lav-desktop {
     display: none !important;
  }

  .lav-mobile {
     display: flex !important;
  }

  .lav-affiliateProgram {
     padding-top: 106px;
  }

  .lav-affiliateProgram-header {
     flex-direction: column;
     gap: 25px;
  }

  .lav-affiliateProgram-header {
  }

  .lav-affiliateProgram-title,
  .lav-affiliateProgram-text {
     width: 100%;
  }

  .lav-affiliateProgram-title {
     font-size: 38px;
     line-height: 35px;
  }

  .lav-affiliateProgram-text {
     font-size: 16px;
     line-height: 21px;
  }

  .lav-affiliateProgram-figure {
     height: 135vw;
  }

  .lav-affiliateProgram-figure svg {
     scale: 1.8;
  }

  .lav-btn-invite {
     width: 158px;
     height: 52px;
     font-size: 13px;
  }

  .lav-btn__wrapper {
     gap: 9px;
     padding: 0 20px;
  }

  .lav-btn__wrapper a {
     font-size: 16px;
     padding: 0;
     flex: 1;
     border-radius: 11px;
     height: 54px;
  }

  .lav-statistics-wrapper {
     padding: 75px 20px 57px;
  }

  .lav-statistics__data {
     flex-direction: column;
     gap: 122px;
  }

  .lav-statistics__data_item {
     width: 100%;
     position: relative;
  }

  .lav-statistics__data_item::before {
     content: '';
     position: absolute;
     height: 2px;
     width: 57px;
     bottom: -60px;
     background: #fff;
     z-index: 1;
  }

  .lav-statistics__data_item:last-child::before {
     display: none;
  }

  .lav-statistics__data_item:nth-child(2) {
     order: -1;
  }

  .lav-statistics__content {
     margin-top: 119px;
  }

  .lav-statistics__content-title {
     font-size: 38px;
     text-align: center;
     margin-bottom: 50px;
  }

  .lav-statistics__content_row {
     flex-direction: column;
     align-items: center;
     margin-bottom: 17px;
  }

  .lav-statistics__btns {
     margin-top: 107px;
     flex-direction: column;
     align-items: center;
  }
}

@media screen and (width < 390px) {
  .lav-btn__wrapper a {
     font-size: 4.15vw;
     line-height: 9.08vw;
     padding: 2.33vw 0;
     flex: 1;
     border-radius: 2.85vw;
  }

  .lav-copy-block {
     padding: 9.86vw 5.19vw;
     border-radius: 3.37vw;
  }

  .lav-promotional-code {
     font-size: 9.86vw;
     line-height: 9.08vw;
  }

  .lav-statistics__content_item,
  .lav-btn-history,
  .lav-btn-details {
     width: 100%;
  }

  .lav-btn-details {
     padding: 5.97vw 2.15vw;
     font-size: 3.6vw;
     line-height: 9.08vw;
     text-align: center;
     border-radius: 2.85vw;
  }
}
</style>
<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import Telegram from "./components/Telegram.vue";

// import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import tableRef from "@/assets/img/table-ref.png";
import tableRefExtend from "@/assets/img/table-ref-levels.png";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import referal from "@/assets/img/reg.jpeg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
// import setNavPills from "@/assets/js/nav-pills.js";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapGetters,mapActions } from "vuex";
import profile_photo from "@/assets/img/profile.webp";

export default {
  name: "ProfileOverview",
  components: {
    // SoftSwitch,
    // ProfileInfoCard,
    // Telegram
    // DefaultProjectCard,
    // PlaceHolderCard,
  },
  data() {
    return {
      showMenu: false,
      loading: false,
      sophie,
      tableRef,
      tableRefExtend,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      photo: profile_photo,
      referal: referal,
      old_password: "",
      // ref_link:'1234',
      new_password_1: "",
      new_password_2: "",
      web_version: true,
    };
  },
  computed:{
    ...mapGetters(["user"]),
    
    ref_link(){
      return `https://tmarket.me/l/ref?uid=${this.user.uid}`
    },
    info (){
      return {
            fullName: this.user.first_name,
            mobile: this.user.phone,
            email: this.user.email,
          }
    }
  },
  methods: {
    ...mapActions(["getUserData","updateUser","logout","restore", "change_password"]),
    send_new_password(){
      var data = {'email':this.user.email, 'no_redirect': true}
      this.restore({vm:this,data:data})
    },
    GoToStat(){
      this.$router.push({path: '/inapp/statref'})
    },
    gotochat(){
      this.$router.push({path: '/inapp/gotochatfromtask'})
      setTimeout(() => {
        this.$router.push({query: {}})
      }, 1000);
    },
    copya(mytext) {
      try {
        navigator.clipboard.writeText(mytext);
        this.$toast.success(this.$t('statref.copy_buffer'), { });
      } catch($e) {
        this.$toast.error(this.$t('statref.no_copy'), { });
      }
    },

    copyreflink() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.reflink.innerHTML;
      this.$refs.reference.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
      this.$toast.success(this.$t('statref.copy_buffer'), { });
    },

    copy_secret_code() {
      const storage = document.createElement('textarea');
      storage.value = this.$refs.secretcode.innerHTML;
      this.$refs.reference.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
      this.$toast.success(this.$t('statref.copy_buffer'), { });
    },


    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      this.$toast.success('Скопировано!');
    },
    openInstruction(){
      alert('Инструкция находится на модерации, дата официальной публикации 15 ноября 2022')
    },  
    change_password_callback(){
      this.loading = true
      var data = {
        email:this.user.email, 
        old_password: this.old_password,
        new_password_1: this.new_password_1,
        new_password_2: this.new_password_2,
      }
      this.change_password({vm:this,data:data})
    },
    change(type,event){
      console.log('change',event.target.checked,event.target.name)

      if (type==='checkbox') {
        var data = {}
        data[event.target.name] = event.target.checked
        console.log(data)
         this.updateUser({vm:this,data:data})
      }
     
      // else if (type==='profile') this.updateUser({
      //   first_name: this.user.first_name,
      //   phone: this.user.phone,
      // })
      // else if (type==='photo') this.updateUser({
      //   photo: this.photo
      // })

    }
  },
  watch:{
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
