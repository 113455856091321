<template>
    <div 
      v-for="({  title: itemTitle, date, amount }, index) of item"
      :key="index"
      class="mt-2 p-4 row ps-2" 
      style="border-radius:15px; 
      background: rgb(239, 239, 239)"
    >
    
      <div class="">
            <h6 class="mb-0 
             text-dark" style="font-size: 25px; font-weight:800">{{ amount }} </h6>
             <div class="progress mb-3">
              <div class="progress-bar progress-bar-striped progress-bar-animated " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
            </div>
            <span class="text">{{ date }} / "{{ itemTitle }}" </span> <br>
            <span class="text-muted "  style="opacity: 0.4; max-width:500px;">
              {{ $t('withdrawn.reglament') }}
            </span>
      </div>
      
    </div>
</template>
<script>
export default {
  name: "RankingList",
  props: {
    title: {
      type: String,
      default: "",
    },
    item: {
      type: Array,
      default: () => {},
      title: String,
      date: String,
      amount: String,
    },
    horizontalBreak: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
