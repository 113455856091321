<template>
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div> -->
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-dark">
                    {{$t('restore.restore_pass') }}
                  </h3>
                  <p class="mb-0"> 1. {{$t('restore.vvod_email.1') }} <br>
                  2. {{$t('restore.vvod_email.2') }}<br>
                  3. {{$t('restore.vvod_email.3') }}</p>
                </div>

                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="getFormValues"> 
                    <!-- <label>{{$t('restore.vvod_email') }}</label> -->
                    <soft-input
                      id="email"
                      type="text"
                      :placeholder="$t('restore.email')"
                      name="email"
                    />
                    
                    <div class="text-center">
                      <button
                        class="my-3 mb-2 align-items-center justify-content-center d-flex btn-dark w-100"
                        style="height:50px; border-radius: 15px; cursor:pointer;"
                        color="dark"
                        full-width
                        :class='{disabled:loading}'
                        >{{$t('restore.new_pass') }}
                      </button>
                    </div>
                  </form>
                  
                <div class=" mt-3">
                  <p class="mx-auto mb-1 text-sm ">
                    {{$t('signup.load_acc') }}
                    <router-link
                      :to="{ name: 'Sign In' }"
                        style="color:orange !important; font-weight: 600;"
                      class="text-dark font-weight-bolder"
                    >
                    {{$t('signup.vvoiti') }}
                    </router-link>
                  </p>

                  <p class="mx-auto mb-2 text-sm">
                    {{$t('sign_in.no_acc') }}
                    <router-link
                    style="color:orange; font-weight: 600;"
                      :to="{ name: 'Sign Up' }"
                      class=""
                      >{{$t('sign_in.registration') }}</router-link
                    >
                  </p>
               </div>

               <div class="p-3 text-start small mt-4" style="background-color: #e7e7e7; border-radius: 10px; color:#424242; opacity: 0.7;">
                <text style="">
                  {{$t('sign_in.telegram_auth.0') }}
                  <a style="color:orange; font-weight: bold;" target="_blank" href="https://t.me/telosmarketbot?start=mypassword">
                    {{$t('sign_in.telegram_auth.1') }}
                  </a> 
                  {{$t('sign_in.telegram_auth.2') }}
                </text>
              </div>
                
              </div>
            </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover  position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/signin.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- <app-footer /> -->
</template>
<style>
  @media screen and (min-width: 900px) {
    .card-plain{
      margin-top: 8rem!important;
    }
  }


</style>
<script>


// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapActions } from "vuex";

export default {
  name: "SignIn",
  components: {
    // Navbar,
    // AppFooter,
    SoftInput,
    // SoftSwitch,
  },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig","login"]),
    ...mapActions(["restore"]),



    getFormValues (submitEvent) {
      this.loading = true
      var data = {'email':submitEvent.target.elements.email.value}
      this.restore({vm:this,data:data})
      
    }

  },
};
</script>
