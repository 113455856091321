<template>
  <div class="" style="max-width: 1100px;">
    <!-- <h2 class="m-4">Условия инвестирования</h2> -->
    <!-- <section class=" screen__5 mb-6">
    <div class="screen__5-fon">
          <img class="screen__5-fon-img" src="./../assets/img/Ellipse.png" alt="круг пунктирной линией">
          <div class="circle-4">
            <img class="" src="./../assets/img/logobox.png" alt="круг пунктирной линией">
          </div>
        </div>
        <div class="blocks__wrapper">
          <div class="blocks__wrapper_row"  >
              <div class="blocks__wrapper_row-item" style="margin-left: 10%;">  <span>1.</span>Вы инвестируете </div>
              <div class="blocks__wrapper_row-item"  style="margin-left: 20%;"><span>2.</span>Мы покупаем на ваши инвестиции товары</div>
              <div class="blocks__wrapper_row-item "  style="margin-left: 30%;"><span>3.</span>Мы продаем их на марктеплейсах и Авито
              </div>
              <div class="blocks__wrapper_row-item"  style="margin-left: 20%;"><span style="height: auto">4.</span>Делимся с вами прибылью</div>
       
              <div class="blocks__wrapper_row-item "  style="margin-left: 10%;"><span>5.</span>Что и дает вам 1-4% прибыли в день</div>
          </div>
        </div>
   </section> -->
  
    <div class="row    m-2 ">
        <div class="card mb-4 p-4" style="background-color: rgb(239, 239, 239);">
            <span class="header-bot text-dark " style="font-family: 'Inter', sans-serif; font-weight:500;">{{$t('invest.investment_programs')}}</span>
            <div class="mt-4  text-dark " style="font-size: 20px;">
              {{$t('invest.long_desc')}}
            </div>
      </div>

    </div>


  <div class="p-2  calc-window "  >
      <div class="mt-4 ms-3 pb-0">
        <span class="header-bot text-light " style="font-weight:700;">{{$t('invest.profit_calculator')}}</span>
      </div>
      <div class="row ">
        <div class="left-calc-result col-lg-6 text-center order-md-2 order-lg-2  mt-lg-0 p-3   ">
            <div class="text-light my-style-rounded align-items-center d-flex justify-content-center" >
              
              <div>
                <div style="opacity: 0.6" class="mb-2">
                <svg  width="100" height="100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 18.2C14.2091 18.2 16 16.4091 16 14.2C16 11.9908 14.2091 10.2 12 10.2C9.79086 10.2 8 11.9908 8 14.2C8 16.4091 9.79086 18.2 12 18.2Z" :stroke="true ? '#fff' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.25 12.95V13.88C12.25 14.23 12.07 14.56 11.76 14.74L11 15.2"  :stroke="true ? '#fff' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.99983 22H15.9998C20.0198 22 20.7398 20.39 20.9498 18.43L21.6998 10.43C21.9698 7.99 21.2698 6 16.9998 6H6.99983C2.72983 6 2.02983 7.99 2.29983 10.43L3.04983 18.43C3.25983 20.39 3.97983 22 7.99983 22Z"  :stroke="true ? '#fff' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"  :stroke="true ? '#fff' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M21.6498 11C19.9198 12.26 17.9998 13.14 16.0098 13.64"  :stroke="true ? '#fff' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2.62012 11.27C4.29012 12.41 6.11012 13.22 8.00012 13.68"  :stroke="true ? '#fff' : '#fff'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <text class="text-light" style="font-size: 20px; font-weight: 400; opacity: 0.4;">
                  If you invest {{sign}}{{deposit.toLocaleString('en')}}, in {{period}}  {{$t('invest.days')}}, your capital growth will be {{sign}}{{ Math.round(total_capital) }}, resulting in a profit of </text>
                <h1 class="text-green mt-4"  style="font-size: 40px; font-weight: 800;">  + {{sign}}{{Math.round(pribyl).toLocaleString('en')}}</h1>
                </div>
                
            </div>
                  
          </div>
          <div class="col-lg-6 p-4 order-sm-1">
            <div class="d-flex flex-column h-100 ">
              <div class="row">
                  <template  v-for="(item,index) in userBots"
                  :key="item.slug">
                    <div 
                        class='col-4 mb-2'
                        v-if="item.show_in_deposit_section" 
                        
                      >   
                        <div 
                          style="font-weight:500; background-color: #e5e5e5; cursor:pointer; border-radius:15px;text-align:center; height: 80px;"
                          @click="robot = item;check_index = index"
                          :id="`${item.slug}`" 
                          class="p-3 justify-content-center d-flex align-items-center"
                           :class="{'btn-calc-active':index === check_index,'btn-calc':index != check_index}"
                        >
                          {{item.name}}
                        </div>
                      </div> 
                  </template>
                </div>

              <div class="row pt-4">
                <p class="mb-0 text-light font-weight-bolder">
                  {{$t('invest.dep_amount')}}
                </p>
                <div class=""  min="0" max="365"  style="display:flex; justify-content: space-between;">
                  <div class="text-muted ">
                    <span class="text-light">{{sign}}{{deposit.toLocaleString('en')}}</span>
                    </div>
                </div>
                <div class="pl-3 pt-2"> 
                  <input type="range" :min="robot.minimum*koef" :max="robot.maximum*koef"  v-model="deposit" class="form-range slide" id="customRange1">
                </div>
                 <div class="" style="display:flex; justify-content: space-between;">
                  <div class="text-light"><small>{{sign}}{{(robot.minimum*koef).toLocaleString('en')}}</small></div>
                  <div class="text-light"><small>{{sign}}{{(robot.maximum*koef).toLocaleString('en')}}</small></div>
                </div>
              </div>

              <div class="row pt-4">
                <p class="mb-0 text-light font-weight-bolder">
                  {{$t('invest.investment_dur')}}
                </p>
                 <div class=""  style="display:flex; justify-content: space-between;">
                  <div class="text-muted">
                    <span class="text-light">{{period}}  {{$t('invest.days')}}</span>
                  </div>
                </div>
                <div class="pl-3 pt-2"> 
                  <input type="range"  min="0" max="365"  v-model="period"  class="form-range slide" id="customRange1">
                </div>
                 <div class=""  style="display:flex; justify-content: space-between;">
                  <div class="text-light text-muted"><small>0 {{$t('invest.days')}}</small></div>
                  <div class="text-light"><small>365 {{$t('invest.days')}}</small></div>
                </div>
              </div>
             
            </div>
           
          </div>
          
        </div>
        <div class="text-end d-flex" style="width: fit-content;">
              <div @click="toggleTopUpCrypto" class=" green-box  cursor-pointer align-items-center justify-content-between d-flex">
                  <div class="digit"> <span class="bl">{{$t('dashboard.Top_up_dep') }}</span>   </div>
                  <div class="image-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="28" width="28" height="28" rx="14" transform="rotate(-90 0 28)" fill="rgba(255,255,255,0.5)"/>
                      <path d="M9 14H19" stroke="black" stroke-linecap="round"/>
                      <path d="M14 9L14 19" stroke="black" stroke-linecap="round"/>
                    </svg>
                  </div>  
              </div>
          </div>
      </div>
      
        <div class="p-2">
          <bot-card />
      </div>

  </div>
</template>

<style scoped>
.calc-window{
  background-color: #000000f4;
  margin: 10px;
  border-radius: 20px;
}
.my-style-rounded{
  background-color: #ececec14;
  border-radius: 15px ;
  padding: 30px;
  height: 100%;
  /* border: 10px solid #F2F2F2 !important;  */
  /* aspect-ratio: 1 / 1 !important; */
  width:100%; 
}

.btn-calc{
  background: #fab13c49 ;
  border-radius: 5px;
  font-weight: 400;
  color: #000  !important;
  box-shadow: none  !important;
}
.text-yellow{
  color: #FAB23C !important;
  font-weight: 800;

}
.btn-calc-active{
  background-color: #FAB23C !important;
  color: #000;
  border-radius: 5px;
  font-weight: 600 !important;
}
.left-calc-result{
  /* background: #26d959; */
}
.slide{
    width: 100%;
    height: 4px;
    background: #FAB23C ;
}
.slide::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%; 
  background: #FAB23C  !important;
  cursor: pointer;
}

.slide::-moz-range-thumb {
  border-radius: 50%;
  background: #FAB23C !important;
  cursor: pointer;
}

.bg-me{
  background: radial-gradient(50% 50% at 50% 50%, #FAB23C 0%, #E1A050 100%);;
}
.green{
  height: 264px;
  background: #404040 !important;
  border-radius: 40px;
}
.header-invest{
  
  font-style: normal;
  font-weight: 700  !important;
  font-size: 20px  !important;
  line-height: 24px  !important;
  text-align: center  !important;
  letter-spacing: 0.01em  !important;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  
  color: #FFFFFF;
}
.text-invest{

   font-family: "Inter", sans-serif;
   font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em !important;
  color: rgba(253, 253, 253, 0.79);
}


.screen__5 {
   margin: 0 auto;
   padding: 0 21px;
   position: relative;
   margin-top: 70px;
}

.screen__5-fon {
   position: absolute;
   display: flex;
   justify-content: center;
   top: 50%;
   left: 10%;
   transform: translate3d(-50%, -45%, 0);
}

.circle-4 {
   position: absolute;
   width: 255px;
   height: 255px;
   border-radius: 50%;
   /* background: radial-gradient(50% 50% at 50% 50%, #C9010E 0%, #480101 100%); */
   /* filter: blur(9.45px); */
   top: 50%;
   left: 50%;
   transform: translate3d(-50%, -50%, 0);
}
.circle-4 img {
   height: 100%;
}

.screen__5-title {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate3d(-50%, -50%, 0);
   font-size: 38px;
   line-height: 49px;
   color:#fff;
}

.screen__5-fon-img {
   position: relative;
}
.text-green{
  color: #68ff68;
}
.blocks__wrapper {
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: column;
}

.blocks__wrapper_row {
   justify-content: center;
   
}
.green-box{
  padding: 15px;
  gap: 10px;
  width: 100%;
  background-color: #FAB23C;
  border-radius: 15px;
  color: #000;
  font-weight: 700;
}
.gray-box{
  /* Frame 1171275659 */

/* Auto layout */
  padding: 15px;
  gap: 10px;

  width: 100%;
  background-color: #FAB23C;
  border-radius: 15px;

}
.blocks__wrapper_row-item {
   display: flex;
   width: 300px;
   max-width: 70%;
   align-items: start;
   gap: 10px;
   padding: 25px 30px;
   background: #1c1c1def;
   border-radius: 24px;
   line-height: 1.4rem;
   color:#fff;
   font-size: 20px !important;
   margin-top: 50px;
  }

.blocks__wrapper_row-item span {
   display: block;
   height: 100%;
}

.blocks__wrapper_row-item span br {
   display: block;
   height: 100%;
}

</style>

<script>
import BotCard from "./components/BotCard";
// import CalcProfit from "./components/CalcProfit";
// import SoftAvatar from "@/components/SoftAvatar.vue";
import { mapGetters,mapActions,mapMutations  } from "vuex";
import img1 from "./../assets/img/team-2.jpg";
import img2 from "./../assets/img/team-3.jpg";
import img3 from "./../assets/img/team-4.jpg";
import img4 from "./../assets/img/team-3.jpg";
import img5 from "./../assets/img/team-2.jpg";
import img6 from "./../assets/img/team-4.jpg";

import { robots } from "./../robots.js";

export default {
  name: "invest",
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      robots: robots,
      rub_curr:false,
      deposit: 500,
      period: 190,
      check_index: 3,
      robot:{ "name": "Бонсай", "slug": "gamma", "revenue_from": 0.88, "revenue_to": 0.98, "minimum": 10, "maximum": 100000, "checked": true },
    };
  },
  components: {
    // CalcProfit,
    BotCard,
  },
  unmounted() {
      // this.root.style.setProperty("--bs-body-bg", "#fff");
    this.getBotsUser();
  },
  watch:{
    rub_curr(){
      this.deposit = (this.robot.minimum + this.robot.maximum) / 2 * this.koef;
    }
  },
  computed:{
     ...mapGetters(["userBots"]),
     pribyl(){
      return this.robot.revenue_to * this.period * this.deposit/100
     },
     total_capital(){
      console.log(this.deposit, this.pribyl)
      return this.deposit *(1 + this.robot.revenue_to * this.period /100)
     },
     sign(){
      if ( this.rub_curr) return '$' 
      else return '$' 
     },
     koef(){
      if ( this.rub_curr) return 60 
      else return 1
     }
  },
    mounted() {
    
    // this.root = document.documentElement;
    // this.root.style.setProperty("--bs-body-bg", "#0C0C0C");
  },
  methods:{
    ...mapMutations(["toggleConfigurator","toggleTopUpCrypto"]),
    ...mapActions(["getBotsUser"]),
    getImgUrl(pic) {
        return require('./../assets/img/'+pic)
    }
  }

};
</script>
