export const robots = [
  {
    id: 1,
    name: "Day Account",
    slug: "alpha",
    minimum: 10.0,
    maximum: 500.0,
    revenue_from: 0.13,
    revenue_to: 0.14,
    hours_to_withdraw: 0,
    hours_to_reinvest: 0,
    reinvest: true,
    days: 1,
    description:
      "This plan ensures liquidity for the flow of funds between institutional companies and the platform, which is why the interest rate is slightly lower. However, you can start investing with just $10, earning up to 5.5% monthly with daily profit payouts.",
    summa_text: "From $10 to $500",
    vyvod_text: "Daily",
    vyvod_totla_text: "not blocked",
    risk: 0,
    revenue_text_total: "4%-5.5% / month",
    revenue_text: null,
    deposits: 10.0,
    total_revenue: 0,
    withdrow_period: 1,
    image: "https://backend.l1st.online/media/portfolio/d_sWRykiv.png",
    date: "13/08/2024",
    available: 0,
    available_processing: 0,
    transactions: [ ],
  },
  {
    id: 2,
    name: "Week Account",
    slug: "beta",
    minimum: 500.0,
    maximum: 3000.0,
    revenue_from: 0.23,
    revenue_to: 0.25,
    hours_to_withdraw: 0,
    hours_to_reinvest: 0,
    reinvest: true,
    days: 7,
    description:
      "This is the golden middle ground\u2014there are some costs due to the absence of a deposit lock, but it offers a solid and stable earning percentage. Enjoy up to 8% monthly profit with daily payouts.",
    summa_text: "From $500 to $3,000",
    vyvod_text: "Daily",
    vyvod_totla_text: "7 days",
    risk: 0,
    revenue_text_total: "5.5%-8% / month",
    revenue_text: null,
    deposits: 600.0,
    total_revenue: 0,
    withdrow_period: 7,
    image: "https://backend.l1st.online/media/portfolio/w_sIGofUG.png",
    date: "13/08/2024",
    available: 0,
    available_processing: 0,
    transactions: [ ],
  },
  {
    id: 3,
    name: "Quarter Account",
    slug: "gamma",
    minimum: 3000.0,
    maximum: 100000.0,
    revenue_from: 0.36,
    revenue_to: 0.4,
    hours_to_withdraw: 0,
    hours_to_reinvest: 0,
    reinvest: true,
    days: 93,
    description:
      "The main investment tool, offering a minimum of 11% and more monthly returns depending on the product group and season. The system ensures the minimum return, with higher returns being frequent.",
    summa_text: "From $3,000 to $100,000",
    vyvod_text: "Daily",
    vyvod_totla_text: "120 days",
    risk: 0,
    revenue_text_total: "8%-14% / month",
    revenue_text: "sdsd",
    deposits: 1000.0,
    total_revenue: 2,
    withdrow_period: 31,
    image: "https://backend.l1st.online/media/portfolio/q_cuHpEJE.png",
    date: "13/08/2024",
    available: 86.0,
    available_processing: 0,
    transactions: [],
  }
]
