<template>
  <div class="py-4 container-fluid">
    
    
  <div  > 
    <div  class="d-flex bg-dark align-items-center p-3 my-3 text-white-50 bg-dark  box-shadow" style="border-radius:15px;">
      <div class="lh-100 ms-5">
        <h1 class="mb-0 text-white lh-100">{{$t('history.title')}}</h1>
        <small>{{$t('history.desc')}}</small>
      </div>
    </div>

    <div v-if="userProfit.length>0" class="my-3 p-3 bg-white rounded box-shadow ">
      <h3 class="border-bottom border-gray pb-2 mb-0 ">{{$t('history.tt')}}</h3>
      <div class="media text-muted pt-3 row border-bottom pb-3"  v-for="yo in userProfit"  v-bind:key="yo.uid">
    
        
        <div class="col">
            <span><span class="" style="font-weight:700; ">  {{yo.value.toLocaleString('en')}} $ </span> / {{yo.created_at}}</span><br>
            <span class="media-body pb-3 mb-0 ">{{$t('history.p_purpose') }}: {{yo.kind}} </span> <br>
            <span>{{$t('history.curr_account') }}: <span style="">{{yo.portfolio}}</span></span> <br>
            <span>{{$t('history.status') }}: <span style="color:green">{{$t('history.success')}}</span></span> <br>
            <span>uid: <span style="color:gray">{{yo.id}}</span></span> <br>

        </div>

        
      </div>
     
    </div>
    <div class=" ms-4 " style="" v-else>
    <loader></loader>
    <p style="opacity: 0.5; font-size:13px" class="pt-2">список начислений пуст...</p>
  </div>

   </div>
  


    
  </div>
</template>

<style>
  .accordion-header button{

    color: #161616 !important;
    font-weight: 700;
  }
  .accordion-header{
    background-color:#fff !important;
    color: #000;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
</style>
<script>
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
// import CalcProfit from "./components/CalcProfit";

import { mapGetters,mapActions,mapMutations } from "vuex";

import {
  faHandPointer,
  faUsers,

  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      transactions: [1],
    };
  },
  methods: {
    ...mapActions(["getProfits"]),
    ...mapMutations(["toggleConfigurator","toggleWithdraw","toggleTopUpCrypto","toggleReinvest","toggleTopUpOwn"]),
    connect_binance(){
      this.$toast.info("Свяжитесь с менеджером через чат для подключения", { });

    },
    connect_huobi(){
      this.$toast.info("Свяжитесь с менеджером через чат для подключения", { });

    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    callSupport(){
      if(this.isMobile){
        this.$toast.info("Сейчас начнется вызов", { });
        location.href='tel:+78007074730';
      }
      else{
        this.$toast.info("Вы с компьютера или с планшета, наберите номер на мобильном", { });
      }

    },
  },
  computed:{
    ...mapGetters(["user","userProfit"]),
  },
  components: {
    // ReportsBarChart,
    // CalcProfit,
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
   mounted() {
    this.getProfits()
  },
};
</script>
